import {createPromiseThunk, handleAsyncActions, reducerUtils,} from '../lib/asyncUtils';
import * as videoApi from "../apis/api/video";
import * as bannerApi from "../apis/api/banner";
import * as eventApi from "../apis/api/event";

//액션 타입 정의
const API_ACTIONS = {
    GET_HOME_VIDEO_LIST: 'GET_HOME_VIDEO_LIST',
    GET_HOME_VIDEO_LIST_SUCCESS: 'GET_HOME_VIDEO_LIST_SUCCESS',
    GET_HOME_VIDEO_LIST_ERROR: 'GET_HOME_VIDEO_LIST_ERROR',

    GET_BANNER_LIST: 'GET_BANNER_LIST',
    GET_BANNER_LIST_SUCCESS: 'GET_BANNER_LIST_SUCCESS',
    GET_BANNER_LIST_ERROR: 'GET_BANNER_LIST_ERROR',

    GET_EVENT_LIST: 'GET_EVENT_LIST',
    GET_EVENT_LIST_SUCCESS: 'GET_EVENT_LIST_SUCCESS',
    GET_EVENT_LIST_ERROR: 'GET_EVENT_LIST_ERROR',
};

const ACTIONS = {
    // CLEAR_PATCH: 'CLEAR_PATCH',
    // CLEAR_DELETE: 'CLEAR_DELETE',
}

Object.freeze(API_ACTIONS);
Object.freeze(ACTIONS);

//외부에서 dispatch할 때 사용
export const getKfaNat10 = createPromiseThunk(API_ACTIONS.GET_HOME_VIDEO_LIST, videoApi.searchVideo)
export const getBannerList = createPromiseThunk(API_ACTIONS.GET_BANNER_LIST, bannerApi.getBannerList);
export const getHomeEventList = createPromiseThunk(API_ACTIONS.GET_EVENT_LIST, eventApi.getRecentEventList);
// export const clearPatch = () => ({type: ACTIONS.CLEAR_PATCH});
// export const clearDelete = () => ({type: ACTIONS.CLEAR_DELETE});

const initialState = {
    kfaNatList: reducerUtils.initial(),
    bannerList: reducerUtils.initial(),
    eventList: reducerUtils.initial(),
};

/**
 * 영상업로드 관리에서 영상 목록을 불러오고 관리하거나, 영상관리에서 영상 파일 목록을 조회할때 사용
 * @param state
 * @param action
 * @returns {*|{videoFile: {data: null, loading: boolean, error: null}}}
 */
export default function homeReducer(state = initialState, action) {
    switch (action.type) {
        case API_ACTIONS.GET_HOME_VIDEO_LIST:
        case API_ACTIONS.GET_HOME_VIDEO_LIST_ERROR:
            return handleAsyncActions(API_ACTIONS.GET_HOME_VIDEO_LIST, 'kfaNatList')(state, action);

        case API_ACTIONS.GET_HOME_VIDEO_LIST_SUCCESS:
            const list = [
                ...(state.kfaNatList.data ?? [])
            ];
            list[action.payload.index] = action.payload.data

            return {
                ...state,
                kfaNatList: reducerUtils.success(list)
            }

        case API_ACTIONS.GET_BANNER_LIST:
        case API_ACTIONS.GET_BANNER_LIST_ERROR:
        case API_ACTIONS.GET_BANNER_LIST_SUCCESS:
            return handleAsyncActions(API_ACTIONS.GET_BANNER_LIST, 'bannerList')(state, action);

        case API_ACTIONS.GET_EVENT_LIST:
        case API_ACTIONS.GET_EVENT_LIST_ERROR:
        case API_ACTIONS.GET_EVENT_LIST_SUCCESS:
            return handleAsyncActions(API_ACTIONS.GET_EVENT_LIST, 'eventList')(state, action);

        default:
            return state;
    }
}