import DataTable from "../../../../common/component/grid/DataTable"
import {useEffect, useState} from "react";
import {clearVODDownload, downloadVOD} from "../../../../modules/userReducer";
import {useDispatch, useSelector} from "react-redux";
import {ShowAlertModal} from "../../../../common/component/modal/AlertModal";
import {makePath, scheme} from "../../../../util/url";

/**
 * @param {SimplePageableModel} notiData
 * @param onPageMove
 * @param {function(Set<string>) : void} onDelete
 * @return {JSX.Element}
 * @constructor
 */
export default function VideoDownloadLogTable({downloadLogList, onPageMove, onDelete}) {
    const loginData = useSelector(state => state.userReducer.loginData);
    const downloadVODResult = useSelector(state => state.userReducer.downloadVOD);
    const dispatch = useDispatch();
    const rowKeyGetter = (row) => row.downloadId;
    const [selectedRows, setSelectedRows] = useState(new Set([]));
    const columns = [
        // {key: 'itemDt', name: "날짜"},
        // {key: 'itemData', name: "내용"},
        {key: 'downloadId', name: '요청ID'},
        {key: 'fileTitle', name: '파일명', width: '40%'},
        {key: 'status', name: '상태', width: '10%'},
    ];

    const [rows, setRows] = useState([]);

    useEffect(() => {
        setRows(createRows())
    }, [downloadLogList])


    useEffect(() => {
        console.log(selectedRows);
    }, [selectedRows])

    const createRows = () => {
        return downloadLogList.content.map(data => {
            let status = data.status;
            if(status == "success" || status == "완료" || status == "승인") {
                if(data.jobSt == "success" || data.jobSt == "pass"){
                    const download = () => {
                        dispatch(downloadVOD({
                            downloadId: data.downloadId,
                            uid: loginData.uid,
                        }))
                    }
                    if(data.downloadCnt >= 2 ){
                        status = <a className="btn btn-default btn-tiny inactive" >다운로드{data.downloadCnt}/2</a>
                    }
                    else {
                        status = <a className="btn btn-tiny" onClick={download}>다운로드{data.downloadCnt}/2</a>
                    }
                }
                else {
                    <a className="btn btn-default btn-tiny inactive">처리중</a>
                }
            }
            else {
                status = <a className="btn btn-default btn-tiny inactive">{status}</a>
            }
            return {
                ...data,
                status: status,
            }
        })
    }

    useEffect(() => {
        if(downloadVODResult.error != null){
            ShowAlertModal({
                message: '오류가 발생했습니다',
            })
            dispatch(clearVODDownload());
        }
        else if(downloadVODResult.data != null){
            if(downloadVODResult.data?.data?.code != "00"){
                ShowAlertModal({
                    message: `오류가 발생했습니다 - ${downloadVODResult.data?.data?.message ?? ''}`,
                })
            }
            else {
                const data = downloadVODResult.data?.data;
                let url = '';
                if(data.data?.[0].fileStorageId === 7) {
                    url = data.tokenUrl;
                }
                else {
                    const tokenUrl = data.tokenUrl;
                    let tokenParam = '';
                    if(tokenUrl != null){
                        tokenParam = `?token=${tokenUrl}`
                    }
                    url = makePath(
                        scheme,
                        data?.data?.[0]?.file_cdnUrl,
                        data?.data?.[0]?.filePath,
                        data?.data?.[0]?.fileName ?? data?.data?.[0]?.filename,
                    );
                    url = `${url}${tokenParam}`
                }

                // const a = <a id={'dummy_download_a'} href={`${url}${tokenParam}`} download={data.fileTitle}>Download</a>
                const a = window.document.createElement('a');
                a.id = "dummy_download_a"
                a.href = url;
                a.target = '_blank';
                a.download = data.fileTitle ?? data.fileName;
                a.style.display = "none"
                window.document.body.appendChild(a)
                const dummy = window.document.querySelector('#dummy_download_a');
                dummy?.click();
                dummy?.remove();

                // window.open(`${url}${tokenParam}`,'download')
            }
            dispatch(clearVODDownload());
        }
    }, [downloadVODResult])

    return <>
        <div className="content_body">
            <DataTable
                pageableModel={downloadLogList}
                rows={rows}
                rowKeyGenerate={rowKeyGetter}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                onMovePage={onPageMove}
                columns={columns}
                checkBoxInFirstColumn={false}
            >
                <button
                    className={`btn btn-default ${selectedRows.size === 0 ? "inactive" : ""}`}
                    onClick={() => {
                        onDelete([...selectedRows])
                    }}
                >
                    삭제
                </button>
            </DataTable>
        </div>
    </>

}