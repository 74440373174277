import AWS from 'aws-sdk'

class Queue {
    constructor() {
        this._arr = [];
    }
    enqueue(item) {
        this._arr.push(item);
    }
    dequeue() {
        return this._arr.shift();
    }

    get length() {
        return this._arr.length;
    }
}

export class UploadParam {
    /**
     *
     * @param {string} endPoint
     * @param {string} region
     * @param {string} accessKey
     * @param {string}  secretKey
     * @param {string}  bucketName
     * @param {File} file
     * @param {string} fileName
     * @param {string} path
     * @param {number} chunkSize
     * @param {number} parallelSize
     * @param setPercentage
     */
    constructor({
                    endPoint = "https://kr.object.ncloudstorage.com",
                    region = "kr-standard",
                    accessKey,
                    secretKey,
                    bucketName,
                    file,
                    fileName,
                    path,
                    chunkSize = 1024 * 1024 * 100, // 100MB
                    parallelSize = 1,
                    setPercentage,
                }) {
        this.endPoint = endPoint;
        this.region = region;
        this.accessKey = accessKey;
        this.secretKey = secretKey;
        this.bucketName = bucketName;
        this.file = file;
        this.fileName = fileName;
        this.path = path;
        this.chunkSize = chunkSize;
        this.parallelSize = parallelSize;
        this.setPercentage = setPercentage;
    }
}

let uploadStack = [];

/**
 *
 * @param {UploadParam} param
 * @returns {Promise<void>}
 */
export const uploadFile = async (param) => {
    console.log('업로드 시작');
    const startAt = new Date();
    console.log(startAt);
    pushUploadState(startAt);
    try{
        const file = param.file;
        const fileSize = file.size;
        const key = (param.path + '/' + param.fileName).normalize('NFC').replace(/ +/g, " ").replaceAll("//", "/").replace(/^\//,"");
        const endPoint = param.endPoint;
        const region = param.region;
        const accessKey = param.accessKey;
        const secretKey = param.secretKey;
        const bucketName = param.bucketName;
        const chunkSize = param.chunkSize;
        const parallelSize = param.parallelSize;

        const S3 = new AWS.S3({
            endpoint: endPoint,
            region: region,
            credentials: {
                accessKeyId : accessKey,
                secretAccessKey: secretKey,
            }
        });

        let chunkCount = 1;
        const uploadPartResults = []

        const multipartCreateResult = await S3.createMultipartUpload({
            ACL: "public-read",
            Bucket: bucketName,
            Key: key,
        }).promise()

        // console.log(multipartCreateResult);

        // const blobs = [];
        const blobQueue = new Queue();

        for(let i = 0; i < fileSize; i += chunkSize) {
            const blob = file.slice(i, i + chunkSize);
            // blobs.push(blob);
            blobQueue.enqueue(blob);
        }

        const partSize = blobQueue.length;

        async function worker(workerId, parallelSize) {
            let blob = blobQueue.dequeue();
            while(blob != null){
                const index = chunkCount++;
                // console.log(`worker ${workerId} ${index}`, blob);
                uploadPartResults[index-1] = await upload(S3, bucketName, key, multipartCreateResult.UploadId, blob, index);
                blob = blobQueue.dequeue();
                param.setPercentage?.(
                    Math.floor((partSize - blobQueue.length )/ partSize * 100)
                );
            }
        }

        await Promise.all([...Array(parallelSize).keys()].map(it => worker(it, parallelSize)))

        // console.log(uploadPartResults);

        const completeUploadResponse= await S3.completeMultipartUpload({
            Bucket: bucketName,
            Key: key,
            MultipartUpload: {
                Parts: uploadPartResults.map((it, index)=>({
                    PartNumber: index + 1,
                    ETag: it,
                })),
            },
            UploadId: multipartCreateResult.UploadId
        }).promise()

        // console.log(completeUploadResponse);

        const endAt = new Date();
        console.log(endAt);
        console.log(`업로드 완료 ${(endAt.getTime() - startAt.getTime())/1000}s`);
    }
    finally {
        popUploadState(startAt);
    }
}

function pushUploadState(key) {
    uploadStack.push(key);
    window.onbeforeunload = function() {
        return "파일 업로드 중입니다. 업로드를 중단하고 나가시겠습니까?";
    }
}

function popUploadState(key) {
    uploadStack = uploadStack.filter(it => it !== key);
    if(uploadStack.length === 0){
        window.onbeforeunload = null;
    }
}

async function upload(S3, bucket, key, uploadId, blob, index) {
    const arrayBuffer = await readBlob(blob);
    // console.log(arrayBuffer);

    let uploadPromiseResult = await S3.uploadPart({
        Body: arrayBuffer,
        Bucket: bucket,
        Key: key,
        PartNumber: index,
        UploadId: uploadId,
    }).promise()
    // console.log(index, uploadPromiseResult.ETag);
    return uploadPromiseResult.ETag;

}

function readBlob(blob) {
    return new Promise((res, rej)=> {
        const reader = new FileReader()
        reader.onerror = function(error){
            rej(error);
        }
        reader.onload = function() {
            const arrayBuffer = this.result
            res(arrayBuffer);
        }
        reader.readAsArrayBuffer(blob)
    })
}