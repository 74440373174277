import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import ImageLog from "./downloadLog/ImageLog";
import VideoUploadPage from "./VideoUploadPage";
import ImageUploadPage from "./ImageUploadPage";
import {useSelector} from "react-redux";
import {acList, acValue} from "../../../resource/menus/mypage";

export default function UploadManagePage() {

    const loginData = useSelector(state => state.userReducer.loginData);

    return <div className="order__item">
        <div className="content_head mb_40">
            <div className="head_title">
                <div className="txt_head2">업로드 관리</div>
            </div>
        </div>
        <Tabs
            forceRenderTabPanel={true}
        >
            <article className="content__tab__area mb_24">
                <TabList
                    className="content_tab">
                    {
                        (loginData.upload_ac === acValue.all || loginData.upload_ac === acValue.video) &&
                        <Tab>
                            <div className="txt_head4 tab_title">영상 업로드</div>
                        </Tab>
                    }
                    {
                        (loginData.upload_ac === acValue.all || loginData.upload_ac === acValue.image) &&
                        <Tab>
                            <div className="txt_head4 tab_title">사진 업로드</div>
                        </Tab>}

                </TabList>
            </article>
            {
                (loginData.upload_ac === acValue.all || loginData.upload_ac === acValue.video) &&
                <TabPanel>
                    <VideoUploadPage/>
                </TabPanel>
            }
            {
                (loginData.upload_ac === acValue.all || loginData.upload_ac === acValue.image) &&
                <TabPanel>
                    <ImageUploadPage/>
                </TabPanel>
            }
        </Tabs>

    </div>;
}