import PreviewList, {PreviewListModel} from "./component/previewList";
import PreviewItemModel from "../../common/component/previewItem/model";
import {useDispatch, useSelector} from "react-redux";
import {getHomeEventList, getKfaNat10} from "../../modules/homeReducer";
import {useEffect, useState} from "react";
import SearchEngineResponse from "../../apis/dto/SearchEngineResponse";
import {getAllChildren, getAsFlat} from "../../lib/menu/menuUtil";
import VideoContentItem from "../../apis/dto/VideoContentItem";
import BannerList from "../../common/component/home/Banner";
import LiveList from "../../common/component/home/Live";
import {makeVideoThumbnailUrl} from "../../util/url";
import {useLoadingModal} from "../../common/component/LoadingState";
import {makePreviewEventTitle} from "../../util/eventUtil";
import EventModel from "../play/model/EventModel";
import queryString from "query-string";
import {VideoSearchParam} from "../../apis/dto/SearchParam";
import SponsorFooter from "../../common/component/home/SponsorFooter";
import SSONoticePopup from "../../common/component/user/sso/SSONoticePopup";

export default function Home() {

    const dispatch = useDispatch();
    const eventList = useSelector(state => state.homeReducer.eventList);
    const homeVideo = useSelector(state => state.homeReducer.kfaNatList);
    const loginData = useSelector(state => state.userReducer.loginData);
    const menus = useSelector(state => state.menuReducer.menus);
    const [kfaNatPreview, setKfaNatPreview] = useState(null); // menuId 2
    const [kfaNormalPreview, setKfaNormalPreview] = useState(null); // menuId 3
    const [eventPreview, setEventPreview] = useState(null); // menuId 4
    const [insideCamPreview, setInsideCamPreview] = useState(null); // menuId 5

    const copyrightAc = () => {
        return loginData?.copyright_list_ac === 1 ? null : 'Y';
    }

    useLoadingModal([eventList, homeVideo, menus]);

    useEffect(()=> {
        dispatch(getHomeEventList(
            copyrightAc(),
        ));
    }, [loginData]);

    useEffect(()=> {
        if(menus.data != null){
            const menuList = getAsFlat(menus.data);
            if(eventList.data != null){
                // console.log(eventList.data);
                const _eventPreview = new PreviewListModel({
                    title: '경기기록',
                    link: '/event',
                    list: eventList.data?.data?.hits?.hits?.map(it => EventModel.of2(it._source)).map((it, index) => new PreviewItemModel({
                        title: makePreviewEventTitle(it),
                        category: menuList.find(m => m.menuId === it.menuId)?.menuNm ?? it.matchName,
                        date: it.videocontent.match_date,
                        link: `/event/video/${it.eventId}?boardParam=${encodeURIComponent(queryString.stringify({page: 0, size: 10, index: index}))}`,
                        state: {eventId: it.eventId},
                        thumbnail: it.thumbnail,
                    }))
                })
                // console.log(_eventPreview);
                setEventPreview(_eventPreview);
            }
        }
    }, [menus.data, eventList.data, eventList.loading, eventList.error]);

    useEffect(()=> {
        // TODO 라이브, 경기기록은 별도 검색 처리
        if(menus.data != null){
            menus.data.forEach((it, index) => {
                if(it.menuId === 2 || it.menuId === 3){
                    const ids = getAllChildren(it).map(it => it.menuId);
                    dispatch(getKfaNat10({
                        ...new VideoSearchParam({
                            menuIds: ids,
                            size: 10,
                            copyright: copyrightAc(),
                        }),
                        index: index,
                    }));
                }
                else if(it.menuId === 5){
                    const ids = getAllChildren(it).map(it => it.menuId);
                    dispatch(getKfaNat10({
                        ...new VideoSearchParam({
                            menuIds: ids,
                            size: 10,
                            copyright: copyrightAc(),
                        }).toRegDtDesc(),
                        index: index,
                    }));
                }
            })
        }
    }, [menus, loginData])

    useEffect(()=>{
        // console.log(homeVideo.data);
        if(menus.data != null){
            if(homeVideo.data != null){
                // const data = new SearchEngineResponse(homeVideo.data.data);
                const list = homeVideo.data.map(it => new SearchEngineResponse(it))
                const menuList = getAsFlat(menus.data);
                // console.log(list);
                const grid = list.map((it, index) => {
                    const menu = menus.data[index];
                    const category = menu.menuNm;
                    const menuId = menu.menuId;
                    const url = menu.menuUrl;
                    const isGamePlayMenu = menu.matchMappingYN === 'Y';
                    const data = it.content?.map(it=>new VideoContentItem(it))
                    // console.log(data);

                    return new PreviewListModel({
                        title: category,
                        menuId: menuId,
                        link: `${url}`, // TODO videoBoard 대신 menu 지정 경로 사용가능한지 검토
                        list: data?.map(data => {
                            const subMenu = menuList.find(it => it.menuId == data.videocontent.menuId)
                            let titlePrefix = ""
                            if(subMenu != null && index != 1){ // kfa 경기는 prefix 안씀
                                titlePrefix = `[${subMenu.menuNm}] `
                            }

                            // data.videocontent.title= `${titlePrefix}${data.videocontent.title}`;
                            // return PreviewItemModel.fromVideoContentItem(menuList, data);
                            return new PreviewItemModel(
                                {
                                    title: `${titlePrefix}${data.videocontent.title}`,
                                    thumbnail: makeVideoThumbnailUrl(data),
                                    category: isGamePlayMenu ?
                                        data.videocontent.ma_title :
                                        menuList.find(m => m.menuId === data.videocontent.menuId)?.menuNm ?? data.videocontent.match_type_name,
                                    date: data.videocontent.match_date,
                                    link: `video/${data.videocontent.cid}`,
                                }
                            )
                        })
                    })
                })

                const nat = grid.filter(it => it.menuId === 2)
                const normal = grid.filter(it => it.menuId === 3)
                const insideCam = grid.filter(it => it.menuId === 5)

                // console.log(nat, normal, insideCam)

                setKfaNatPreview(nat[0])
                setKfaNormalPreview(normal[0])
                setInsideCamPreview(insideCam[0])
            }
        }
    }, [homeVideo, menus.data])

    return (
        <div className="inner max-sizeX">
            <BannerList/>
            <LiveList/>
            <article className="content__wrap mt_64">
                {
                    kfaNatPreview != null &&
                    <div
                        key={`kfaNat_grid`}>
                        <PreviewList
                            item={kfaNatPreview}
                        />
                    </div>
                }
                {
                    kfaNormalPreview != null &&
                    <div
                        key={`kfaNormal_grid`}>
                        <PreviewList
                            item={kfaNormalPreview}
                        />
                    </div>
                }
                {
                    eventPreview != null &&
                    <div
                        key={`event_grid`}>
                        <PreviewList
                            item={eventPreview}
                        />
                    </div>
                }

                {
                    insideCamPreview != null &&
                    <div
                        key={`insideCam_grid`}>
                        <PreviewList
                            item={insideCamPreview}
                        />
                    </div>
                }
            </article>
            <SponsorFooter/>
            <SSONoticePopup/>
        </div>
    )
}