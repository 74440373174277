import Menu from "../component/menu";
import {Outlet} from "react-router-dom";
import ErrorBoundary from "../component/ErrorBoundary";

export default function MenuWrapper() {
    return (
        <>
            <Menu/>
            <div className="container__">
                <ErrorBoundary
                    fill={true}
                >
                    <Outlet/>
                </ErrorBoundary>
            </div>
        </>
    )
}