import SearchParam from "../../../../apis/dto/SearchParam";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import ImageLog from "./ImageLog";
import VideoLog from "./VideoLog";
import {acValue} from "../../../../resource/menus/mypage";
import {useSelector} from "react-redux";

export class DownloadLogSearchParam extends SearchParam {
    constructor({
                    uid,
                    userType,
                    sourceType,
                    _pageNo,
                    _pageSize
                }) {
        super();
        this.uid = uid;
        this.userType = userType;
        this.sourceType = sourceType;
        this._pageNo = _pageNo;
        this._pageSize = _pageSize;
    }

    static toDownloadLogSearchParam({
                                        keyword, startDate, endDate, sourceType, uid, userType, _pageNo, _pageSize,
                                    }) {
        return {
            keyword: keyword,
            _fromDt: startDate,
            _toDt: endDate,
            sourceType,
            uid,
            userType,
            _pageNo,
            _pageSize
        }
    }
}

class DownloadLog {
    constructor({
                    regDt, thumbnailUrl,
                }) {
    }
}

const jobStatuses = [
    {
        name: "대기중",
        value: "ready"
    },
    {
        name: "업로드중",
        value: "working"
    },
    {
        name: "업로드 중지",
        value: "stop"
    },
    {
        name: "업로드 실패",
        value: "fail"
    },
    {
        name: "업로드 성공",
        value: "success"
    }
];

export default function DownloadLogPage() {

    const loginData = useSelector(state => state.userReducer.loginData);
    return <div className="order__item">
        <div className="content_head mb_40">
            <div className="head_title">
                <div className="txt_head2">다운로드 내역</div>
            </div>
        </div>
        <Tabs
            forceRenderTabPanel={true}
        >
            <article className="content__tab__area mb_24">
                <TabList
                    className="content_tab">
                    {
                        (loginData.download_ac === acValue.all || loginData.download_ac === acValue.all_auto || loginData.download_ac === acValue.video) &&
                        <Tab>
                            <div className="txt_head4 tab_title">영상</div>
                        </Tab>
                    }
                    {
                        (loginData.download_ac === acValue.all || loginData.download_ac === acValue.all_auto || loginData.download_ac === acValue.image) &&
                        <Tab>
                            <div className="txt_head4 tab_title">사진</div>
                        </Tab>
                    }
                </TabList>
            </article>

            {
                (loginData.download_ac === acValue.all || loginData.download_ac === acValue.all_auto || loginData.download_ac === acValue.video) &&
                <TabPanel>
                    <VideoLog/>
                </TabPanel>
            }

            {
                (loginData.download_ac === acValue.all || loginData.download_ac === acValue.all_auto || loginData.download_ac === acValue.image) &&
                <TabPanel>
                    <ImageLog/>
                </TabPanel>
            }
        </Tabs>

    </div>
}