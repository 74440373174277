/**
 * @param value
 * @return {JSX.Element}
 * @constructor
 */
export default function ProgressBar({value, remainTime}) {
    return <div className="progressBar__wrap">
        <div className="progress__text">
            <div className="percent">{value}%</div>
            <div className="time">{remainTime}</div>
        </div>
        <progress className="progressBar" value={value} max="100"></progress>
    </div>
}