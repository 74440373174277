import {createPromiseThunk, handleAsyncActions, reducerUtils,} from '../lib/asyncUtils';
import * as videoApi from "../apis/api/video";
import SearchParam, {VideoSearchParam} from "../apis/dto/SearchParam";
import * as uploadApi from "../apis/api/upload";

const API_ACTIONS = {

    START_UPLOAD: 'UPLOAD/VIDEO/START_UPLOAD',
    START_UPLOAD_ERROR: 'UPLOAD/VIDEO/START_UPLOAD_ERROR',
    START_UPLOAD_SUCCESS: 'UPLOAD/VIDEO/START_UPLOAD_SUCCESS',

    UPDATE_UPLOAD: 'UPLOAD/VIDEO/UPDATE_UPLOAD',
    UPDATE_UPLOAD_ERROR: 'UPLOAD/VIDEO/UPDATE_UPLOAD_ERROR',
    UPDATE_UPLOAD_SUCCESS: 'UPLOAD/VIDEO/UPDATE_UPLOAD_SUCCESS',
}

const ACTIONS = {
    CLEAR_UPLOAD_UPDATE: 'UPLOAD/VIDEO/CLEAR_UPLOAD_UPDATE',
    CLEAR_UPLOAD: 'UPLOAD/VIDEO/CLEAR_UPLOAD',
}

Object.freeze(API_ACTIONS);
Object.freeze(ACTIONS);

export const videoUploadStart = (param) => createPromiseThunk(API_ACTIONS.START_UPLOAD, uploadApi.videoUploadStart)(param);
export const videoUploadUpdate = (param) => createPromiseThunk(API_ACTIONS.START_UPLOAD, uploadApi.videoUploadUpdate)(param);

export const clearUploadUpdate = () => ({type: ACTIONS.CLEAR_UPLOAD_UPDATE})
export const clearUpload = () => ({type: ACTIONS.CLEAR_UPLOAD})

const initialState = {
    startUploadResult: reducerUtils.initial(),
    updateUploadResult: reducerUtils.initial(),
};

export default function videoUploadReducer(state = initialState, action) {
    switch (action.type) {

        case API_ACTIONS.START_UPLOAD:
        case API_ACTIONS.START_UPLOAD_ERROR:
        case API_ACTIONS.START_UPLOAD_SUCCESS:
            return handleAsyncActions(API_ACTIONS.START_UPLOAD, 'startUploadResult')(state, action);

        case API_ACTIONS.UPDATE_UPLOAD:
        case API_ACTIONS.UPDATE_UPLOAD_ERROR:
        case API_ACTIONS.UPDATE_UPLOAD_SUCCESS:
            return handleAsyncActions(API_ACTIONS.UPDATE_UPLOAD, 'updateUploadResult')(state, action);

        case ACTIONS.CLEAR_UPLOAD_UPDATE:
            return {
                ...state,
                updateUploadResult: reducerUtils.initial(),
            }

        case ACTIONS.CLEAR_UPLOAD:
            return {
                ...state,
                startUploadResult: reducerUtils.initial(),
                updateUploadResult: reducerUtils.initial(),
            }

        default:
            return state;
    }
}