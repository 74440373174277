import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useLoadingModal} from "../../common/component/LoadingState";
import {ShowAlertModal} from "../../common/component/modal/AlertModal";
import {clearSignup, requestResetPassword} from "../../modules/userReducer";
import {validateEmail} from "../../util";
import {ResetPasswordEmailPreset} from "../../config/email/ResetPasswordEmailPreset";

export default function FindPassPage() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [id, setId] = useState("");
    const [userNm, setUserNm] = useState("");
    const [canSend, setCanSend] = useState(false);
    const resetPasswordResult = useSelector(state => state.userReducer.resetPasswordResult);

    useLoadingModal([resetPasswordResult]);

    useEffect(() => {
        if(resetPasswordResult.error){
            ShowAlertModal({
                message: '오류가 발생하였습니다.',
            });
            dispatch(clearSignup());
        }
        else if(resetPasswordResult.data){
            if(resetPasswordResult.data?.data?.code != "00"){
                ShowAlertModal({
                    message: '사용자정보 확인해주세요',
                });
            }
            else {
                ShowAlertModal({
                    message: '임시 비밀번호 메일이 발송되었습니다',
                    onConfirm: ()=> {
                        navigate('/user/login');
                    }
                });
            }
            dispatch(clearSignup());
        }
    }, [resetPasswordResult]);

    useEffect(()=> {
        setCanSend(userNm.trim().length > 1 && id.length > 3);
    }, [id, userNm]);

    const handleEmailSend = () => {
        dispatch(requestResetPassword({
            uid: id,
            userNm: userNm,
            // userEmail: email,
            // ...ResetPasswordEmailPreset,
        }));
    }

    return <div className="inner">
        <div className="sign-form-area">
            <div className="txt_head2 mb_48">비밀번호 찾기</div>
            <div className="find_alert mb_24">가입시 등록한 아이디를 입력해주세요.</div>
            <div className="field__inp mb_12">
                <input type="text" placeholder="아이디" className="inp-valid" value={id} onChange={e => setId(e.target.value)}/>
            </div>
            <div className="field__inp">
                <input type="name" placeholder="이름" className="inp-valid" value={userNm} onChange={e => setUserNm(e.target.value)}/>
            </div>
            <div className="field__btn mt_24">
                <button className="btn submit" id="submitBtn" disabled={!canSend} onClick={handleEmailSend}>임시 비밀번호를 이메일 발송</button>
            </div>
        </div>
    </div>
}