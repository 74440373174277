import DataTable from "../../../../common/component/grid/DataTable"
import {useEffect, useState} from "react";
import {clearImgDownload, downloadImg} from "../../../../modules/userReducer";
import {useDispatch, useSelector} from "react-redux";
import {ShowAlertModal} from "../../../../common/component/modal/AlertModal";
import {makePath, scheme} from "../../../../util/url";
import GalleryModal2 from "../../../../common/component/modal/GalleryModal2";

/**
 * @param {SimplePageableModel} notiData
 * @param onPageMove
 * @param {function(Set<string>) : void} onDelete
 * @return {JSX.Element}
 * @constructor
 */
export default function ImageDownloadLogTable({downloadLogList, onPageMove, onDelete}) {
    const loginData = useSelector(state => state.userReducer.loginData);
    const downloadImgResult = useSelector(state => state.userReducer.downloadImg);
    const dispatch = useDispatch();
    const rowKeyGetter = (row) => row.downloadId;
    const [selectedRows, setSelectedRows] = useState(new Set([]));
    const columns = [
        // {key: 'itemDt', name: "날짜"},
        // {key: 'itemData', name: "내용"},
        {key: 'downloadId', name: '요청ID'},
        {key: 'fileName', name: '파일명', width: '40%'},
        {key: 'status', name: '상태', width: '10%'},
    ];
    const [rows, setRows] = useState([]);
    const [showImage, setShowImage] = useState(null);

    useEffect(() => {
        setRows(createRows())
    }, [downloadLogList])

    const showGalleryImage = (id) => {
        setShowImage(id);
    }

    const createRows = () => {
        return downloadLogList.content.map(data => {
            let status = data.status;
            if(status  == 'success' || status == "완료" || status == "승인" || data.jobSt == "success") {
                const download = () => {
                    dispatch(downloadImg({
                        downloadId: data.downloadId,
                        uid: loginData.uid,
                    }))
                }
                if(data.downloadCnt >= 2 ){
                    status = <a className="btn btn-default btn-tiny inactive" >다운로드{data.downloadCnt}/2</a>
                }
                else {
                    status = <a className="btn btn-tiny" onClick={download}>다운로드{data.downloadCnt}/2</a>
                }
            }
            else {
                status = <a className="btn btn-default btn-tiny inactive">{status}</a>
            }
            return {
                ...data,
                fileName: <a onClick={e => showGalleryImage(data.image_cid)}>{data.fileName}</a>,
                status: status,
            }
        })
    }

    useEffect(() => {
        if(downloadImgResult.error != null){
            ShowAlertModal({
                message: '오류가 발생했습니다',
            })
            dispatch(clearImgDownload());
        }
        else if(downloadImgResult.data != null){
            if(downloadImgResult.data?.data?.code != "00"){
                ShowAlertModal({
                    message: `오류가 발생했습니다 - ${downloadImgResult.data?.data?.message ?? ''}`,
                })
            }
            else {
                const data = downloadImgResult.data?.data;
                const tokenUrl = data.tokenUrl;
                let tokenParam = '';
                if(tokenUrl != null){
                    tokenParam = `?token=${tokenUrl}`
                }
                const url = makePath(
                    scheme,
                    data?.data?.[0]?.file_cdnUrl,
                    data?.data?.[0]?.filePath,
                    data?.data?.[0]?.fileName ?? data?.data?.[0]?.filename,
                );

                if (data?.data?.[0]?.sourceSubType === 'Web') {
                    fetch(url, { method: 'GET' })
                        .then((res) => {
                            return res.blob();
                        })
                        .then((blob) => {
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.href = url;
                            a.download = data?.data?.[0]?.fileName ?? '이미지';
                            document.body.appendChild(a);
                            a.click();
                            setTimeout((_) => {
                                window.URL.revokeObjectURL(url);
                            }, 60000);
                            a.remove();
                            // setOpen(false);
                        })
                        .catch((err) => {
                            console.error('err: ', err);
                        });
                } else {
                    const a = window.document.createElement('a');
                    a.id = "dummy_download_a"
                    a.href = `${url}${tokenParam}`
                    a.download = data.fileTitle ?? data.fileName;
                    a.style.display = "none"
                    window.document.body.appendChild(a)
                    const dummy = window.document.querySelector('#dummy_download_a');
                    dummy?.click();
                    dummy?.remove();
                }

                // const a = <a id={'dummy_download_a'} href={`${url}${tokenParam}`} download={data.fileTitle}>Download</a>
                // window.open(`${url}${tokenParam}`,'download')
            }
            dispatch(clearImgDownload());
        }
    }, [downloadImgResult])

    return <>
        <div className="content_body">
            <DataTable
                pageableModel={downloadLogList}
                rows={rows}
                rowKeyGenerate={rowKeyGetter}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                onMovePage={onPageMove}
                columns={columns}
                checkBoxInFirstColumn={false}
            >
                <button
                    className={`btn btn-default ${selectedRows.size === 0 ? "inactive" : ""}`}
                    onClick={() => {
                        onDelete([...selectedRows])
                    }}
                >
                    삭제
                </button>
            </DataTable>
        </div>
        {
            showImage != null &&
            <GalleryModal2
                index={0}
                list={[{cid: showImage}]}
                onClose={e => setShowImage(null)}
                user={loginData}
            />
        }
    </>

}