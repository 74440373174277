export default function NotFound({message}) {
    return (
        <div className="search__notfound">
            <div><i className="icon gas-info-big"></i></div>
            <div><span className="txt">
                {message != null && `${message}`}
                {message == null && '항목이 없습니다.'}
            </span></div>
        </div>
    )
}