import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import ErrorBoundary from "../../../../common/component/ErrorBoundary";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import VideoLog from "../downloadLog/VideoLog";
import ImageLog from "../downloadLog/ImageLog";
import FavoriteVideoBoard from "./FavoriteVideoBoard";
import FavoriteImageBoard from "./FavoriteImageBoard";

export class FavorSearchParam {
    constructor({
                    userId,
                    _pageNo,
                    _pageSize,
                    _fromDt,
                    _toDt,
                    _keyword,
                    _sortField,
                    _sortDirection,
                    contentType,
                } = {}) {
        this.userId = userId;
        this._pageNo = _pageNo;
        this._pageSize = _pageSize;
        this.contentType = contentType;
        this._fromDt = _fromDt;
        this._toDt = _toDt;
        this._keyword = _keyword;
        this._sortField = _sortField;
        this._sortDirection = _sortDirection;
    }

    toSearchBoxParam() {
        return {
            startDate: this._fromDt,
            endDate: this._toDt,
            keyword: this._keyword,
        }
    }
}

export default function FavoriteMediaTabWrapper() {
    return <ErrorBoundary fill={true}>
        <div className="content_head mb_40">
            <div className="head_title">
                <div className="txt_head2">관심 영상/사진</div>
            </div>
        </div>
        <Tabs
            forceRenderTabPanel={true}
        >
            <article className="content__tab__area mb_24">
                <TabList
                    className="content_tab">
                    <Tab>
                        <div className="txt_head4 tab_title">관심영상</div>
                    </Tab>
                    <Tab>
                        <div className="txt_head4 tab_title">관심사진</div>
                    </Tab>
                </TabList>
            </article>
            <TabPanel>
                <FavoriteVideoBoard/>
            </TabPanel>
            <TabPanel>
                <FavoriteImageBoard/>
            </TabPanel>
        </Tabs>
    </ErrorBoundary>
}