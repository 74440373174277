import {useState} from "react";
import {useNavigate} from "react-router-dom";
import classNames from "classnames";
import {filterExceptNumbers, validateEmail} from "../../../util";
import {getUserType, getMediaDownAcType} from "../../../resource/menus/mypage";
import useSSO from "../../../lib/useSSO";
import {useSelector} from "react-redux";

/**
 * @param {UserInfoDto} myInfo
 * @param onSubmit
 * @param onEmailAuth
 * @return {JSX.Element}
 * @constructor
 */
export default function MyInfoTable({myInfo, onSubmit, onResign,onChangePassword, onEmailAuth}) {
    const navigate = useNavigate();
    const {isSSOUser, goSSOInfoChange} = useSSO();

    const [myInfoState, setMyInfoState] = useState(myInfo);
    const [authIssued, setAuthIssued] = useState(false);

    const checkValidity = () => {
        return true;
    }

    const isEmailCorrect = () => validateEmail(myInfoState.userEmail) && myInfoState.userEmail !== myInfo.userEmail;

    return <>
        <div className="order__item">
            <div className="content_head mb_40">
                <div className="head_title">
                    <div className="txt_head2">나의 정보</div>
                </div>
            </div>
            <div className="content_body">
                <form>
                    <div className="div__table__area table_myinfo">
                        <div className="row">
                            <dl>
                                <dt>가입일</dt>
                                <dd>
                                    <span>{new Date(myInfoState.authReqDt).format("yyyy-MM-dd")}</span>
                                    {
                                        <span className="u_label success">가입승인 완료</span>
                                    }
                                </dd>
                            </dl>
                        </div>
                        <div className="row">
                            <dl>
                                <dt>이름</dt>
                                <dd><span>{myInfoState.userNm}</span></dd>
                            </dl>
                        </div>
                        <div className="row">
                            <dl>
                                <dt>아이디</dt>
                                <dd><span>{myInfoState.uid}</span></dd>
                            </dl>
                        </div>
                        <div className="row">
                            <dl>
                                <dt>가입구분</dt>
                                <dd><span>{getUserType(myInfoState.userType)}</span></dd>
                            </dl>
                            { myInfoState.userType === 'M' && (
                                <dl>
                                    <dt>미디어 공유 등급</dt>
                                    <dd><span>{getMediaDownAcType(myInfoState.media_download_ac)}</span></dd>
                                </dl>
                            )}
                        </div>
                        <div className="row">
                            <dl>
                                <dt>휴대폰번호</dt>
                                <dd>
                                    <div className="field__inp">
                                        <input
                                            type="text"
                                            disabled={isSSOUser}
                                            value={myInfoState.userMobile}
                                            onChange={({target}) => setMyInfoState(state => ({
                                                ...state,
                                                userMobile: filterExceptNumbers(target.value)
                                            }))}
                                        />
                                        {
                                            isSSOUser &&
                                            <div
                                                className="btn btn-default"
                                                onClick={() => {
                                                    goSSOInfoChange();
                                                }}>
                                                변경하기
                                            </div>
                                        }
                                    </div>
                                </dd>
                            </dl>
                        </div>
                        <div className="row">
                            <dl>
                                <dt>이메일주소</dt>
                                <dd>
                                    <div className="field__inp">
                                        <input
                                            type="email"
                                            value={myInfoState.userEmail}
                                            onChange={({target}) => setMyInfoState(state => ({
                                                ...state,
                                                userEmail: target.value
                                            }))}
                                            disabled={isSSOUser || authIssued}
                                        />
                                        {
                                            !isSSOUser &&
                                            <div className={classNames({
                                                "btn": true,
                                                "btn-default": true,
                                                [isEmailCorrect() ? "active" : "inactive"]: true
                                            })}
                                                 onClick={() => {
                                                     onEmailAuth(myInfoState.uid, myInfoState.userEmail)
                                                     setAuthIssued(true)
                                                 }}
                                            >
                                                인증
                                            </div>
                                        }
                                        {
                                            isSSOUser &&
                                            <div
                                                className="btn btn-default"
                                                onClick={() => {
                                                    goSSOInfoChange();
                                                }}>
                                                변경하기
                                            </div>
                                        }
                                    </div>
                                </dd>
                                {
                                    authIssued ? <>
                                        <dt>인증번호</dt>
                                        <dd>
                                            <div className="field_inp">
                                                <input
                                                    maxLength="6"
                                                    value={myInfoState.authNo}
                                                    onChange={({target}) => setMyInfoState(state => ({
                                                        ...state,
                                                        authNo: filterExceptNumbers(target.value)
                                                    }))}
                                                />
                                            </div>
                                        </dd>
                                    </> : null
                                }
                            </dl>
                        </div>
                        <div className="row">
                            <dl>
                                <dt>회사명</dt>
                                <dd>
                                    <div className="field__inp">
                                        <input
                                            type="text"
                                            disabled={isSSOUser}
                                            value={myInfoState.userComp}
                                            onChange={({target}) => setMyInfoState(state => ({
                                                ...state, userComp: target.value
                                            }))}
                                        />
                                        {
                                            isSSOUser &&
                                            <div
                                                className="btn btn-default"
                                                onClick={() => {
                                                    goSSOInfoChange();
                                                }}>
                                                변경하기
                                            </div>
                                        }
                                    </div>
                                </dd>
                            </dl>
                        </div>
                        <div className="row">
                            <dl>
                                <dt>부서/직책</dt>
                                <dd>
                                    <div className="field__inp">
                                        <input
                                            type="text"
                                            disabled={isSSOUser}
                                            value={myInfoState.userPart}
                                            onChange={({target}) => setMyInfoState(state => ({
                                                ...state,
                                                userPart: target.value
                                            }))}
                                        />
                                        <span className="slash">/</span>
                                        <input
                                            type="text"
                                            disabled={isSSOUser}
                                            value={myInfoState.userPos}
                                            onChange={({target}) => setMyInfoState(state => ({
                                                ...state,
                                                userPos: target.value
                                            }))}
                                        />

                                        {
                                            isSSOUser &&
                                            <div
                                                className="btn btn-default"
                                                onClick={() => {
                                                    goSSOInfoChange();
                                                }}>
                                                변경하기
                                            </div>
                                        }
                                    </div>
                                </dd>
                            </dl>
                        </div>
                        <div className="row">
                            <dl>
                                <dt>비밀번호</dt>
                                <dd>
                                    <div className="btn btn-default" onClick={onChangePassword}>변경하기
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div className="content_controll">
                        <div className="single">
                            <div className="btn btn-default" onClick={onResign}>탈퇴하기</div>
                        </div>
                        {
                            !isSSOUser &&
                            <div className="group">
                                <div className="btn btn-cancle" onClick={() => navigate(-1)}>취소</div>
                                <div
                                    className="btn submit"
                                    onClick={() => {
                                        if (checkValidity())
                                            onSubmit(myInfoState);
                                    }}
                                >
                                    수정
                                </div>
                            </div>
                        }
                    </div>
                </form>
            </div>
        </div>
    </>
}
