import {createPromiseThunk, handleAsyncActions, reducerUtils,} from '../lib/asyncUtils';
import * as videoApi from "../apis/api/video";

//액션 타입 정의
const API_ACTIONS = {
    SEARCH_VIDEO_BOARD: 'SEARCH_VIDEO_BOARD',
    SEARCH_VIDEO_BOARD_SUCCESS: 'SEARCH_VIDEO_BOARD_SUCCESS',
    SEARCH_VIDEO_BOARD_ERROR: 'SEARCH_VIDEO_BOARD_ERROR',

};

Object.freeze(API_ACTIONS);



export const searchVideoBoard = (searchParam) => createPromiseThunk(API_ACTIONS.SEARCH_VIDEO_BOARD, videoApi.searchVideo)(searchParam);

const initialState = {
    videoBoardSearchResult: reducerUtils.initial(),
};

export default function videoBoardReducer(state = initialState, action) {
    switch (action.type) {
        case API_ACTIONS.SEARCH_VIDEO_BOARD:
        case API_ACTIONS.SEARCH_VIDEO_BOARD_ERROR:
        case API_ACTIONS.SEARCH_VIDEO_BOARD_SUCCESS:
            return handleAsyncActions(API_ACTIONS.SEARCH_VIDEO_BOARD, 'videoBoardSearchResult')(state, action);

        default:
            return state;
    }
}