import {apiInstance, searchEngineApiInstance} from "../utils";

export const getHotHashtagList = () => {
    return apiInstance.get(
        `/hothashtag/_search`,
        {
            params: {
                from: 0,
                size: 10,
            }
        }
    );
}

export const textApi = () => {
    return searchEngineApiInstance.post(
        '',
        {
            "from": 0,
            "size": 10,
        },
        {
            headers: {
                'subUrl': `/hothashtag/_search`,
                "Content-Type": "application/json",
            }
        }
    )
}