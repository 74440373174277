import MyInfoTable from "../../../common/component/user/MyInfoTable";
import {useDispatch, useSelector} from "react-redux";
import {
    changeEmailAuth,
    changePassword,
    changeUserInfo, clearLogin, clearPasswordChange,
    clearResendEmail, clearResignUser,
    clearSignup,
    loginRefresh,
    resendEmail, resignUser
} from "../../../modules/userReducer";
import {useEffect, useState} from "react";
import {ShowAlertModal} from "../../../common/component/modal/AlertModal";
import {useNavigate} from "react-router-dom";
import {filterExceptNumbers} from "../../../util";
import ResignModal from "../../../common/component/modal/ResignModal";
import PasswordChangeModal from "../../../common/component/modal/PasswordChangeModal";
import useSSO from "../../../lib/useSSO";

export default function MyInfoPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const myInfo = useSelector(state => state.userReducer.loginData);
    const changeUserInfoResult = useSelector(state => state.userReducer.changeUserInfoResult);
    const resendEmailResult = useSelector(state => state.userReducer.changeEmailResult);
    const [resignModalToggle, setResignModalToggle] = useState(false);
    const [passwordChangeModalToggle, setPasswordChangeModalToggle] = useState(false);
    const resignUserResult = useSelector(state => state.userReducer.resignUserResult);
    const changePasswordResult = useSelector(state => state.userReducer.changePasswordResult);
    const {isSSOUser, goSSOPasswordChange, goSSOInfoChange, goSSOResign} = useSSO();

    const handlePasswordChangeSubmit = (currentPassword, newPassword) => {
        dispatch(changePassword({
            uid: myInfo.uid,
            userPwd: currentPassword,
            _new_password: newPassword,
        }))
    }

    useEffect(() => {
        if (changePasswordResult.error) {
            ShowAlertModal({
                message: '오류가 발생하엿습니다.',
            })
            dispatch(clearPasswordChange());
        } else if (changePasswordResult.data) {
            ShowAlertModal({
                message: '비밀번호가 변경되었습니다.',
                onConfirm: () => {
                    dispatch(clearLogin());
                }
            })
            dispatch(clearPasswordChange());
        }
    }, [changePasswordResult])


    const handleSubmit = (userInfo) => {
        console.log(userInfo);
        dispatch(changeUserInfo({
            uid: myInfo.uid,
            // userPwd: myInfo.userPwd,
            // userNm: myInfo.userNm,
            // userType: myInfo.userType,
            userMobile: userInfo.userMobile,
            userEmail: userInfo.userEmail,
            userComp: userInfo.userComp,
            userPart: userInfo.userPart,
            userPos: userInfo.userPos,
            authNo: userInfo.authNo !== "" ? userInfo.authNo : myInfo.authNo,
            // upload_ac: myInfo.upload_ac,
            // download_ac: myInfo.download_ac,
        }));
    }

    useEffect(() => {
        if (changeUserInfoResult.error) {
            ShowAlertModal({
                message: '오류가 발생하였습니다'
            });
            dispatch(clearSignup());
        } else if (changeUserInfoResult.data) {
            if (changeUserInfoResult.data.data?.code !== "00") {
                ShowAlertModal({
                    message: '오류가 발생하였습니다'
                });
            } else {

                ShowAlertModal({
                    message: '수정되었습니다',
                    onConfirm: () => {
                        dispatch(loginRefresh());
                    }
                });
            }
            dispatch(clearSignup());
        }
    }, [changeUserInfoResult]);

    const handleEmailSubmit = (uid, email) => {
        dispatch(changeEmailAuth(
            {
                uid,
                userEmail: email,
            }
        ))
    }

    useEffect(() => {
        console.log(resendEmailResult)
        const {data, loading, error} = resendEmailResult
        if (error) {
            ShowAlertModal({
                message: '오류가 발생하였습니다'
            });
            dispatch(clearResendEmail());
        } else if (data) {
            if (data.data?.code !== "00") {
                ShowAlertModal({
                    message: '오류가 발생하였습니다'
                });
            } else {

                ShowAlertModal({
                    message: '수정되었습니다',
                    onConfirm: () => {
                        dispatch(loginRefresh());
                    }
                });
            }
            dispatch(clearResendEmail());
        }
        if (!loading && !error && data != null) {
            ShowAlertModal({
                message: "인증을 위한 이메일이 발송되었습니다. 보이지 않을 경우 스팸메일함을 확인해주세요.",
            });
        } else if (!loading && error != null) {
            ShowAlertModal({
                message: "메일 발송이 제대로 이루어지지 않은 것 같습니다.",
            });
        }

    }, [resendEmailResult])

    // 회원탈퇴 시도할 경우
    const handleResignSubmit = ({password, reason}) => {
        dispatch(resignUser({uid: myInfo.uid, userPwd: password, resignRemarks: reason}));
    }


    useEffect(() => {
        if (resignUserResult.error) {
            ShowAlertModal({
                message: "회원탈퇴가 제대로 이루어지지 않은 것 같습니다.",
            });
            dispatch(clearResignUser());
        } else if (resignUserResult.data) {
            if (resignUserResult.data.data?.code !== "00") {
                ShowAlertModal({
                    message: "회원탈퇴가 제대로 이루어지지 않은 것 같습니다."
                });
            } else {
                ShowAlertModal({
                    message: "회원탈퇴가 완료되었습니다. 이용해주셔서 감사합니다.",
                    onConfirm: () => {
                        dispatch(clearLogin());
                    }
                });
            }
            dispatch(clearResignUser());
        }
    }, [resignUserResult]);

    return <>
        <MyInfoTable
            myInfo={{
                ...myInfo,
                authNo: "",
                userMobile: filterExceptNumbers(myInfo.userMobile)
            }}
            onSubmit={handleSubmit}
            onEmailAuth={handleEmailSubmit}
            onResign={() => {
                if(isSSOUser) {
                    goSSOResign();
                }
                else {
                    setResignModalToggle(true)
                }
            }}
            onChangePassword={() => {
                if(isSSOUser) {
                    goSSOPasswordChange();
                }
                else {
                    setPasswordChangeModalToggle(true);
                }
            }}
        />
        {
            resignModalToggle ?
                <ResignModal
                    onSubmit={handleResignSubmit}
                    onClose={() => setResignModalToggle(false)}
                /> : null
        }
        {
            passwordChangeModalToggle ?
                <PasswordChangeModal
                    onSubmit={handlePasswordChangeSubmit}
                    onClose={() => setPasswordChangeModalToggle(false)}
                /> : null
        }
    </>
}