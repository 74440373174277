import {useEffect, useImperativeHandle, useState} from "react";
import {createPortal} from "react-dom";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import {validatePassword} from "../../../util";

export default function AlertModal2({ message, onConfirm,}) {
    const container = document.querySelector('.alert-modal-portal');
    return createPortal(<AlertModalContent message={message} onConfirm={onConfirm}/>, container)
}

export function AlertModalContent({message, onConfirm}) {
    const handleConfirm = () => {
        onConfirm?.();
    }
    return (
        <div className="modal-alert-wrap">
            <div
                className={classNames({
                    'modal-dialog': true,
                    'fade': true,
                })}
            >
                <div className="dim"></div>
                <div className="modal-alert">
                    <div>
                        <div className="message">{message}</div>
                    </div>
                    <div className="btn_area">
                        <div className="btn" onClick={handleConfirm}>확인</div>
                    </div>
                </div>
            </div>
        </div>
    )
}