import {useEffect, useState} from "react";
import classNames from "classnames";
import {excludeCodeList, majorEventCodeList} from "../../../util/dummy";


export default function EventFilter({ codeList, onFilterChange }) {

    const [usingCodeList, setUsingCodeList] = useState([]);
    const [selectInstance, setSelectInstance] = useState(null);

    const handleCheckAll = () => {
        const isAllChecked = selectedFilterList.length === usingCodeList.length;
        if(isAllChecked){
            selectInstance?.selectClear();
        }
        else {
            selectInstance?.selectAll();
        }
    }

    const initSelect = () => {
        const select = new window.CustomSelectBox('.selectBox', {
            option : '.select_option',
            type : 'multiple',
            onChange: (result) => {
                // console.log(result.result);
                const selectedCodeList = result.result;
                const newSelectedCodeList = usingCodeList.filter(it => selectedCodeList.includes(it.eventCd));
                setSelectedFilterList(newSelectedCodeList);
            }
        });
        setSelectInstance(select);
    }

    useEffect(()=> {
        let filtered = codeList.filter(it => !excludeCodeList.includes(it.eventCd));
        const majorEventCodes = majorEventCodeList.map(it => it.eventCd);
        filtered = filtered.map(it => ({
            ...it,
            isMajor: majorEventCodes.includes(it.eventCd),
        }))
        filtered.sort((a, b) => a.sortSeq - b.sortSeq).sort((a, b) => a.isMajor);
        setUsingCodeList(filtered);
    }, [codeList])

    const loadSavedFilterList = (isMajor) => {
        const saved = localStorage.getItem('eventFilter');
        let savedSelectedCodeList = saved != null ? JSON.parse(saved) : majorEventCodeList;
        if(savedSelectedCodeList.length === 0){
            savedSelectedCodeList = majorEventCodeList;
        }
        // console.log('loadSavedFilterList', savedSelectedCodeList);
        return savedSelectedCodeList;
    }

    const [selectedFilterList, setSelectedFilterList] = useState(()=>loadSavedFilterList(false));

    useEffect(() => {
        saveFilterList(selectedFilterList);
        onFilterChange?.(selectedFilterList);
    }, [selectedFilterList]);

    const saveFilterList = (list) => {
        localStorage.setItem('eventFilter', JSON.stringify(list));
    }

    useEffect(() => {
        if(usingCodeList.length > 0){
            // console.log('usingCodeList', usingCodeList);
            initSelect();
        }
    }, [usingCodeList])

    return (
        <article className="content_item">
            <div className="select__wrap">
                <div className="selectBox" data-multiple="true">
                    <div className="select">
                        <div className="select_text">
                            <div className="option"><span className="multiple__text">경고</span></div>
                        </div>
                        <ul className="select_option">
                            <div className={classNames({
                                'checked': selectedFilterList.length === usingCodeList.length,
                            })}

                                 onClick={handleCheckAll}
                            >
                                <div className="checkbox dummy_option"  onClick={handleCheckAll}>
                                    <div className="option_text">
                                        <span>전체선택</span>
                                    </div>
                                </div>
                            </div>
                            {
                                usingCodeList.filter(it => it.isMajor)?.map((it, index) => {
                                    const isSelected = selectedFilterList.find(selected => selected.eventCd === it.eventCd) != null;
                                    return (
                                        <li key={`event_filter_item_major_${index}`}
                                            className={classNames({
                                                'selected': isSelected,
                                            })}
                                        >
                                            <div className="option" data-option-value={it.eventCd}>
                                                <div className="option_text">
                                                    <span>{it.eventNm}</span>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                            {
                                usingCodeList.filter(it => it.isMajor).length > 0 && usingCodeList.filter(it => !it.isMajor).length > 0 &&
                                <div className="option__divider"/>
                            }
                            {
                                usingCodeList.filter(it => !it.isMajor)?.map((it, index) => {
                                    const isSelected = selectedFilterList.find(selected => selected.eventCd === it.eventCd) != null;
                                    return (
                                        <li key={`event_filter_item_not_major_${index}`}
                                            className={classNames({
                                                'selected': isSelected,
                                            })}
                                        >
                                            <div className="option" data-option-value={it.eventCd}>
                                                <div className="option_text">
                                                    <span>{it.eventNm}</span>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </article>
    )
}