import {useLocation, useParams} from "react-router-dom";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {useEffect, useState} from "react";

export default function TermsPage() {
    const location = useLocation();
    const param = useParams();
    const [tabIndex, setTabIndex] = useState(0)

    useEffect(()=> {
        setTabIndex(Number(param.tab ?? 0))
    }, [param])

    const handleTabChange = (index) => {
        setTabIndex(index);
    }

    return (
        <div className="inner">
            <article className="content__wrap">
                <Tabs
                    selectedIndex={tabIndex}
                    onSelect={(index) => handleTabChange(index)}
                >
                    <ul className="terms__list">
                        <TabList className="flex">
                            <Tab>
                                서비스 이용 약관
                            </Tab>
                            <Tab>
                                개인정보 처리방침
                            </Tab>
                            <Tab>
                                이메일 수집거부
                            </Tab>
                        </TabList>
                    </ul>
                    <div className="terms__text__area">
                        <TabPanel>
                            <div className="txt_head2 mt_48 mb_48">서비스 이용 약관(임시)</div>
                            <div className="txt_head5 mb_48">제1장 총칙</div>
                            <div className="txt_bold mb_16">제1장 총칙</div>
                            <div className="txt_pre mb_48">{`이 약관은 주식회사 KFA플러스(이하 “회사”)이 KFA플러스 웹사이트와 모바일, 태블릿, 스마트TV 앱 서비스 및
                        프로그램을 이용하여 온라인으로 제공하는 디지털콘텐츠(이하 "콘텐츠") 및 제반 서비스를 이용함에 있어 이용자의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.`}
                            </div>

                            <div className="txt_bold mb_16">제2조 [정의]</div>
                            <div className="txt_pre mb_48">{`1. "회사"라 함은 "콘텐츠" 산업과 관련된 경제활동을 영위하는 자로서 “콘텐츠” 제반 서비스를 제공하는 자이며, 이
                        약관에서는 주식회사 KFA플러스을 말합니다.
                        2. "이용자"라 함은 "회사"의 KFA플러스 웹사이트와 모바일, 태블릿, 스마트TV 앱 서비스에 접속하여 이 약관에 따라 "회사"가 제공하는 "콘텐츠" 제반 서비스를
                        이용하는 회원 및 비회원을 말합니다.
                        3. "회원"이라 함은 "회사"와 이용계약을 체결하고 "이용자" 아이디(ID)를 부여 받은 "이용자"로서 "회사"의 정보를 지속적으로 제공받으며 "회사"가 제공하는 서비스를
                        지속적으로 이용할 수 있는 자를 말합니다.
                        4. "비회원"이라 함은 "회원"이 아니면서 "회사"가 제공하는 서비스를 이용하는 자를 말합니다.
                        5. “KFA플러스” 회원 : “KFA플러스” 서비스 운영에 동의하고 회원 ID를 부여받은 자 중 “회사”의 서비스 이용에 동의한 회원을 의미하며, “KFA플러스”
                        회원약관에 의해 운영 됩니다.
                        6. "콘텐츠"라 함은 정보통신망이용촉진 및 정보보호 등에 관한 법률 제2조 제1항 제1호의 규정에 의한 정보통신망에서 사용되는 부호, 문자, 음성, 음향, 이미지 또는
                        영상 등으로 표현된 자료 또는 정보로서, 그 보존 및 이용에 있어서 효용을 높일 수 있도록 전자적 형태로 제작 또는 처리된 것을 말합니다.
                        7. "아이디(ID)"라 함은 "회원"의 식별과 서비스이용을 위하여 "회원"이 정하고 "회사"가 승인하는 문자 또는 숫자의 조합을 말합니다.
                        8. "비밀번호(PASSWORD)"라 함은 "회원"이 부여 받은 "아이디"와 일치되는 "회원"임을 확인하고 비밀보호를 위해 "회원" 자신이 정한 문자 또는 숫자의 조합을
                        말합니다.
                        9. "유료 서비스"라 함은 서비스 이용을 위해 대금을 지불한 후에 이용할 수 있는 서비스를 말합니다.
                        10. "무료 서비스"라 함은 서비스 이용을 위해 대금을 지불하지 않고 이용할 수 있는 서비스를 말합니다.`}
                            </div>

                            <div className="txt_bold mb_16">제3조 [신원정보 등의 제공]</div>
                            <div className="txt_pre mb_48">{`"회사"는 이 약관의 내용, 상호, 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함),
                        전화번호, 모사전송번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호 및 개인정보관리책임자 등을 “이용자”가 쉽게 알 수 있도록 온라인 서비스초기화면에 게시합니다.
                        다만, 약관은 “이용자”가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.`}
                            </div>

                            <div className="txt_bold mb_16">제4조 [약관의 게시 등]</div>
                            <div className="txt_pre mb_48">{`1. "회사"는 이 약관을 "회원"이 그 전부를 인쇄할 수 있고 거래과정에서 해당 약관의 내용을 확인할 수 있도록 기술적
                        조치를 취합니다.
                        2. “이용자”가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회ㆍ배송책임ㆍ환불조건 등과 같은 중요한 내용을 “이용자”가 이해할 수 있도록 별도의 연결화면
                        또는 팝업화면 등을 제공하여 “이용자”의 확인을 구하여야 합니다.
                        3. "회사"는 "이용자"가 "회사"와 이 약관의 내용에 관하여 질의 및 응답할 수 있도록 기술적 장치를 설치합니다.`}
                            </div>

                            <div className="txt_bold mb_16">제5조 [약관의 개정 등]</div>
                            <div className="txt_pre mb_48">{`1. "회사"는 콘텐츠산업진흥법, 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 전자문서 및
                        전자거래기본법, 전자금융거래법, 전자서명법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 방문판매 등에 관한 법률, 소비자기본법 등 관련법을 위배하지 않는 범위에서 이
                        약관을 개정할 수 있습니다.
                        2. "회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 서비스초기화면에 그 적용일자 7일 전에 게시하거나 기타의 방법을 통해 제공하고,
                        기존회원에게는 적용일자 및 변경될 내용 중 중요사항에 대해 전자우편주소로 발송합니다. 단, “회원”에게 불리한 약관개정의 경우에는 적용일자 및 변경사유를 명시하여 현행
                        약관과 함께 그 개정약관의 적용일자 30일 전부터 적용일자 전일까지 공지하며, 공지 외에 전자우편주소 등의 전자적 수단을 통해 따로 명확히 통지하도록 합니다.
                        3. "회사"가 약관을 개정할 경우에는 개정약관 공지 후 개정약관의 적용에 대한 "이용자"의 동의 여부를 확인합니다. "이용자"가 개정약관의 적용에 동의하지 않는 경우
                        "회사" 또는 "이용자"는 “콘텐츠” 이용계약을 해지할 수 있으며, "이용자"가 개정약관의 적용일까지 거부의 의사를 밝히지 않을 경우에 개정약관에 동의하는 것으로
                        간주합니다.`}
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="txt_head2 mt_48 mb_48">개인정보 처리방침(임시)</div>
                            <div className="txt_head5 mb_48">제1장 총칙</div>
                            <div className="txt_bold mb_16">제1장 총칙</div>
                            <div className="txt_pre mb_48">{`이 약관은 주식회사 KFA플러스(이하 “회사”)이 KFA플러스 웹사이트와 모바일, 태블릿, 스마트TV 앱 서비스 및
                        프로그램을 이용하여 온라인으로 제공하는 디지털콘텐츠(이하 "콘텐츠") 및 제반 서비스를 이용함에 있어 이용자의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.`}
                            </div>

                            <div className="txt_bold mb_16">제2조 [정의]</div>
                            <div className="txt_pre mb_48">{`1. "회사"라 함은 "콘텐츠" 산업과 관련된 경제활동을 영위하는 자로서 “콘텐츠” 제반 서비스를 제공하는 자이며, 이
                        약관에서는 주식회사 KFA플러스을 말합니다.
                        2. "이용자"라 함은 "회사"의 KFA플러스 웹사이트와 모바일, 태블릿, 스마트TV 앱 서비스에 접속하여 이 약관에 따라 "회사"가 제공하는 "콘텐츠" 제반 서비스를
                        이용하는 회원 및 비회원을 말합니다.
                        3. "회원"이라 함은 "회사"와 이용계약을 체결하고 "이용자" 아이디(ID)를 부여 받은 "이용자"로서 "회사"의 정보를 지속적으로 제공받으며 "회사"가 제공하는 서비스를
                        지속적으로 이용할 수 있는 자를 말합니다.
                        4. "비회원"이라 함은 "회원"이 아니면서 "회사"가 제공하는 서비스를 이용하는 자를 말합니다.
                        5. “KFA플러스” 회원 : “KFA플러스” 서비스 운영에 동의하고 회원 ID를 부여받은 자 중 “회사”의 서비스 이용에 동의한 회원을 의미하며, “KFA플러스”
                        회원약관에 의해 운영 됩니다.
                        6. "콘텐츠"라 함은 정보통신망이용촉진 및 정보보호 등에 관한 법률 제2조 제1항 제1호의 규정에 의한 정보통신망에서 사용되는 부호, 문자, 음성, 음향, 이미지 또는
                        영상 등으로 표현된 자료 또는 정보로서, 그 보존 및 이용에 있어서 효용을 높일 수 있도록 전자적 형태로 제작 또는 처리된 것을 말합니다.
                        7. "아이디(ID)"라 함은 "회원"의 식별과 서비스이용을 위하여 "회원"이 정하고 "회사"가 승인하는 문자 또는 숫자의 조합을 말합니다.
                        8. "비밀번호(PASSWORD)"라 함은 "회원"이 부여 받은 "아이디"와 일치되는 "회원"임을 확인하고 비밀보호를 위해 "회원" 자신이 정한 문자 또는 숫자의 조합을
                        말합니다.
                        9. "유료 서비스"라 함은 서비스 이용을 위해 대금을 지불한 후에 이용할 수 있는 서비스를 말합니다.
                        10. "무료 서비스"라 함은 서비스 이용을 위해 대금을 지불하지 않고 이용할 수 있는 서비스를 말합니다.`}
                            </div>

                            <div className="txt_bold mb_16">제3조 [신원정보 등의 제공]</div>
                            <div className="txt_pre mb_48">{`"회사"는 이 약관의 내용, 상호, 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함),
                        전화번호, 모사전송번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호 및 개인정보관리책임자 등을 “이용자”가 쉽게 알 수 있도록 온라인 서비스초기화면에 게시합니다.
                        다만, 약관은 “이용자”가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.`}
                            </div>

                            <div className="txt_bold mb_16">제4조 [약관의 게시 등]</div>
                            <div className="txt_pre mb_48">{`1. "회사"는 이 약관을 "회원"이 그 전부를 인쇄할 수 있고 거래과정에서 해당 약관의 내용을 확인할 수 있도록 기술적
                        조치를 취합니다.
                        2. “이용자”가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회ㆍ배송책임ㆍ환불조건 등과 같은 중요한 내용을 “이용자”가 이해할 수 있도록 별도의 연결화면
                        또는 팝업화면 등을 제공하여 “이용자”의 확인을 구하여야 합니다.
                        3. "회사"는 "이용자"가 "회사"와 이 약관의 내용에 관하여 질의 및 응답할 수 있도록 기술적 장치를 설치합니다.`}
                            </div>

                            <div className="txt_bold mb_16">제5조 [약관의 개정 등]</div>
                            <div className="txt_pre mb_48">{`1. "회사"는 콘텐츠산업진흥법, 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 전자문서 및
                        전자거래기본법, 전자금융거래법, 전자서명법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 방문판매 등에 관한 법률, 소비자기본법 등 관련법을 위배하지 않는 범위에서 이
                        약관을 개정할 수 있습니다.
                        2. "회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 서비스초기화면에 그 적용일자 7일 전에 게시하거나 기타의 방법을 통해 제공하고,
                        기존회원에게는 적용일자 및 변경될 내용 중 중요사항에 대해 전자우편주소로 발송합니다. 단, “회원”에게 불리한 약관개정의 경우에는 적용일자 및 변경사유를 명시하여 현행
                        약관과 함께 그 개정약관의 적용일자 30일 전부터 적용일자 전일까지 공지하며, 공지 외에 전자우편주소 등의 전자적 수단을 통해 따로 명확히 통지하도록 합니다.
                        3. "회사"가 약관을 개정할 경우에는 개정약관 공지 후 개정약관의 적용에 대한 "이용자"의 동의 여부를 확인합니다. "이용자"가 개정약관의 적용에 동의하지 않는 경우
                        "회사" 또는 "이용자"는 “콘텐츠” 이용계약을 해지할 수 있으며, "이용자"가 개정약관의 적용일까지 거부의 의사를 밝히지 않을 경우에 개정약관에 동의하는 것으로
                        간주합니다.`}
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="txt_head2 mt_48 mb_48">이메일 수집거부(임시)</div>
                            <div className="txt_head5 mb_48">제1장 총칙</div>
                            <div className="txt_bold mb_16">제1장 총칙</div>
                            <div className="txt_pre mb_48">{`이 약관은 주식회사 KFA플러스(이하 “회사”)이 KFA플러스 웹사이트와 모바일, 태블릿, 스마트TV 앱 서비스 및
                        프로그램을 이용하여 온라인으로 제공하는 디지털콘텐츠(이하 "콘텐츠") 및 제반 서비스를 이용함에 있어 이용자의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.`}
                            </div>

                            <div className="txt_bold mb_16">제2조 [정의]</div>
                            <div className="txt_pre mb_48">{`1. "회사"라 함은 "콘텐츠" 산업과 관련된 경제활동을 영위하는 자로서 “콘텐츠” 제반 서비스를 제공하는 자이며, 이
                        약관에서는 주식회사 KFA플러스을 말합니다.
                        2. "이용자"라 함은 "회사"의 KFA플러스 웹사이트와 모바일, 태블릿, 스마트TV 앱 서비스에 접속하여 이 약관에 따라 "회사"가 제공하는 "콘텐츠" 제반 서비스를
                        이용하는 회원 및 비회원을 말합니다.
                        3. "회원"이라 함은 "회사"와 이용계약을 체결하고 "이용자" 아이디(ID)를 부여 받은 "이용자"로서 "회사"의 정보를 지속적으로 제공받으며 "회사"가 제공하는 서비스를
                        지속적으로 이용할 수 있는 자를 말합니다.
                        4. "비회원"이라 함은 "회원"이 아니면서 "회사"가 제공하는 서비스를 이용하는 자를 말합니다.
                        5. “KFA플러스” 회원 : “KFA플러스” 서비스 운영에 동의하고 회원 ID를 부여받은 자 중 “회사”의 서비스 이용에 동의한 회원을 의미하며, “KFA플러스”
                        회원약관에 의해 운영 됩니다.
                        6. "콘텐츠"라 함은 정보통신망이용촉진 및 정보보호 등에 관한 법률 제2조 제1항 제1호의 규정에 의한 정보통신망에서 사용되는 부호, 문자, 음성, 음향, 이미지 또는
                        영상 등으로 표현된 자료 또는 정보로서, 그 보존 및 이용에 있어서 효용을 높일 수 있도록 전자적 형태로 제작 또는 처리된 것을 말합니다.
                        7. "아이디(ID)"라 함은 "회원"의 식별과 서비스이용을 위하여 "회원"이 정하고 "회사"가 승인하는 문자 또는 숫자의 조합을 말합니다.
                        8. "비밀번호(PASSWORD)"라 함은 "회원"이 부여 받은 "아이디"와 일치되는 "회원"임을 확인하고 비밀보호를 위해 "회원" 자신이 정한 문자 또는 숫자의 조합을
                        말합니다.
                        9. "유료 서비스"라 함은 서비스 이용을 위해 대금을 지불한 후에 이용할 수 있는 서비스를 말합니다.
                        10. "무료 서비스"라 함은 서비스 이용을 위해 대금을 지불하지 않고 이용할 수 있는 서비스를 말합니다.`}
                            </div>

                            <div className="txt_bold mb_16">제3조 [신원정보 등의 제공]</div>
                            <div className="txt_pre mb_48">{`"회사"는 이 약관의 내용, 상호, 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함),
                        전화번호, 모사전송번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호 및 개인정보관리책임자 등을 “이용자”가 쉽게 알 수 있도록 온라인 서비스초기화면에 게시합니다.
                        다만, 약관은 “이용자”가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.`}
                            </div>

                            <div className="txt_bold mb_16">제4조 [약관의 게시 등]</div>
                            <div className="txt_pre mb_48">{`1. "회사"는 이 약관을 "회원"이 그 전부를 인쇄할 수 있고 거래과정에서 해당 약관의 내용을 확인할 수 있도록 기술적
                        조치를 취합니다.
                        2. “이용자”가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회ㆍ배송책임ㆍ환불조건 등과 같은 중요한 내용을 “이용자”가 이해할 수 있도록 별도의 연결화면
                        또는 팝업화면 등을 제공하여 “이용자”의 확인을 구하여야 합니다.
                        3. "회사"는 "이용자"가 "회사"와 이 약관의 내용에 관하여 질의 및 응답할 수 있도록 기술적 장치를 설치합니다.`}
                            </div>

                            <div className="txt_bold mb_16">제5조 [약관의 개정 등]</div>
                            <div className="txt_pre mb_48">{`1. "회사"는 콘텐츠산업진흥법, 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 전자문서 및
                        전자거래기본법, 전자금융거래법, 전자서명법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 방문판매 등에 관한 법률, 소비자기본법 등 관련법을 위배하지 않는 범위에서 이
                        약관을 개정할 수 있습니다.
                        2. "회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 서비스초기화면에 그 적용일자 7일 전에 게시하거나 기타의 방법을 통해 제공하고,
                        기존회원에게는 적용일자 및 변경될 내용 중 중요사항에 대해 전자우편주소로 발송합니다. 단, “회원”에게 불리한 약관개정의 경우에는 적용일자 및 변경사유를 명시하여 현행
                        약관과 함께 그 개정약관의 적용일자 30일 전부터 적용일자 전일까지 공지하며, 공지 외에 전자우편주소 등의 전자적 수단을 통해 따로 명확히 통지하도록 합니다.
                        3. "회사"가 약관을 개정할 경우에는 개정약관 공지 후 개정약관의 적용에 대한 "이용자"의 동의 여부를 확인합니다. "이용자"가 개정약관의 적용에 동의하지 않는 경우
                        "회사" 또는 "이용자"는 “콘텐츠” 이용계약을 해지할 수 있으며, "이용자"가 개정약관의 적용일까지 거부의 의사를 밝히지 않을 경우에 개정약관에 동의하는 것으로
                        간주합니다.`}
                            </div>
                        </TabPanel>
                    </div>
                </Tabs>
                {/*<div className="scroll__wrap scroll__hide">*/}
                {/*    <ul className="terms__list">*/}
                {/*        <li className="active"><a href="">서비스 이용 약관</a></li>*/}
                {/*        <li><a href="">개인정보 처리방침</a></li>*/}
                {/*        <li><a href="">이메일 수집거부</a></li>*/}
                {/*    </ul>*/}
                {/*</div>*/}

            </article>
        </div>
    )
}