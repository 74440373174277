import PreviewGridItem from "../../../../common/component/previewItem/PreviewGridItem";
import {Swiper, SwiperSlide} from "swiper/react";
import {useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Navigation} from 'swiper'
import useSwiperRef from "../../../../common/useSwiperRef";
import FlexFillCenter from "../../../../common/component/FlexFillCenter";
import NotFound from "../../../../common/component/NotFound";

export class PreviewListModel {
    constructor({
                    title,
                    link,
                    list,
                    orgList,
                    menuId,
                    count,
                }) {
        this.title = title;
        this.link = link;
        this.list = list;
        this.orgList = orgList;
        this.count = count;
        this.menuId = menuId;
    }

}

export const ContentTitle = ({title, count, link}) => <div
    className="content_head"
    style={{
        // marginRight: '40px',
    }}
>
    <div
        className="head_title"
        style={{
            alignItems: "center",
            justifyContent: "space-between",
            width: '100%',
        }}>
        <div className={'flex items-baseline'}>
            <div className="txt_head2">{title}</div>
            {
                count != null && <div className="txt_head6 txt_sub">{count}</div>
            }
        </div>
        <Link to={`${link}`}>전체보기 ></Link>
    </div>
</div>
/**
 *
 * @param {PreviewListModel} item
 * @returns {JSX.Element}
 * @constructor
 */
export default function PreviewList({item}) {
    const [nextEl, nextElRef] = useSwiperRef();
    const [prevEl, prevElRef] = useSwiperRef();

    return (
        <>
            <ContentTitle title={item.title} link={item.link} count={item.count}/>
            <article className="slide__content__wrap mt_48"
                     style={{
                         marginBottom: '80px',
                     }}>
                <div className="card__slide">
                    <Swiper
                        modules={[Navigation]}
                        navigation={{
                            prevEl: prevEl,
                            nextEl: nextEl,
                        }}
                        slidesPerView={'auto'}
                        slidesPerGroup={1}
                        centeredSlides={false}
                        scrollbar={{draggable: true}}
                        pagination={{clickable: true}}
                    >
                        {
                            item?.list?.map((data, index) =>
                                <div>
                                    <SwiperSlide
                                        key={`${item.title}_${index}`}
                                    >
                                        <PreviewGridItem
                                            {...data}
                                        />
                                    </SwiperSlide>
                                </div>
                            )
                        }

                        {
                            (item?.list?.length ?? 0) === 0 &&
                            <FlexFillCenter children={<NotFound message={'검색 결과가 없습니다.'}/>}/>
                        }
                    </Swiper>
                </div>
                <div className="swiper-control">
                    <button className="swiper-button-prev slide__04__prev" ref={prevElRef}></button>
                    <button className="swiper-button-next slide__04__next" ref={nextElRef}></button>
                </div>
                {/*<div className="inner">*/}
                {/*    <div className="mt_48">*/}
                {/*        <Link to={`${item.link}`} className="btn-more">전체보기</Link>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </article>
        </>
    )
}