import {useEffect, useState} from "react";

export default function useChromeCast({onConnected, onDisconnected, onError} = {}) {

    const [available, setAvailable] = useState(false);
    let loadCastInterval = null;
    let chrome = window.chrome;
    let cast = window.cast;

    const initCastApi = () => {
        setAvailable(true);
        let context = cast.framework.CastContext.getInstance();
        context.setOptions({
            receiverApplicationId: chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
            autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED
        });
    }


    const connectToSession = () => {
        return Promise.resolve()
            .then(() => {
                let castSession = cast.framework.CastContext.getInstance().getCurrentSession();
                if (!castSession) {
                    return cast.framework.CastContext.getInstance().requestSession()
                        .then(() => {
                            return Promise.resolve(cast.framework.CastContext.getInstance().getCurrentSession());
                        });
                }
                return Promise.resolve(castSession);
            });
    }

    const startCast = (url) => {
        // You could add an is connecting message here. E.g. Connecting to Chromecast...

        return connectToSession()
            .then((session)=> {
                let mediaInfo = new chrome.cast.media.MediaInfo(url);
                mediaInfo.contentType = 'video/mp4';
                let request = new chrome.cast.media.LoadRequest(mediaInfo);
                request.autoplay = true;
                return session.loadMedia(request);
            })
            .then(()=> {
                // Show controls
                // document.querySelector('.js-casting-controls').setAttribute('aria-hidden', 'false');
                // Playing on Chromecast
                listenToRemote();
            })
            .catch((error)=> {
                console.log(error);
            });
    }

    const listenToRemote = () => {
        let player = new cast.framework.RemotePlayer();
        let playerController = new cast.framework.RemotePlayerController(player);

        playerController.addEventListener(
            cast.framework.RemotePlayerEventType.ANY_CHANGE, function() {
                // you could update the play/pause button here or update the displayed time
                // console.log(player.isPaused);
            });

        playerController.addEventListener(
            cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED, function() {
                console.log('IS_CONNECTED_CHANGED')
                if(player.isConnected){
                    onConnected?.();
                }
                else {
                    onDisconnected?.();
                }
            });
    }

    const initChromecast = () =>  {
        if(typeof window.chrome === undefined) {
            setAvailable(false);
            return;
        }
        loadCastInterval = setInterval(function() {
            if (window.chrome.cast.isAvailable && window.cast != null) {
                setAvailable(true);
                clearInterval(loadCastInterval);
                loadCastInterval = null;
                initCastApi();
            } else {
                setAvailable(false);
            }
        }, 1000);
    }

    useEffect(() => {
        initChromecast()
        return () => {
            if(loadCastInterval != null){
                clearInterval(loadCastInterval);
            }
        }
    }, [])

    return {
        available,
        startCast,
    }
}