import {createPromiseThunk, handleAsyncActions, reducerUtils,} from '../lib/asyncUtils';
import * as imageApi from "../apis/api/image";


//액션 타입 정의
const API_ACTIONS = {
    GET_IMAGE_CONTENT: 'GET_IMAGE_CONTENT',
    GET_IMAGE_CONTENT_SUCCESS: 'GET_IMAGE_CONTENT_SUCCESS',
    GET_IMAGE_CONTENT_ERROR: 'GET_IMAGE_CONTENT_ERROR',

    GET_IMAGE_LIST: 'GET_IMAGE_LIST',
    GET_IMAGE_LIST_ERROR: 'GET_IMAGE_LIST_ERROR',
    GET_IMAGE_LIST_SUCCESS: 'GET_IMAGE_LIST_SUCCESS',

};

const ACTIONS = {
    CLEAR_IMAGE: 'CLEAR_IMAGE',
}

Object.freeze(API_ACTIONS);
Object.freeze(ACTIONS);

//외부에서 dispatch할 때 사용
export const getImage = createPromiseThunk(API_ACTIONS.GET_IMAGE_CONTENT, imageApi.getImage);
export const getImageList = createPromiseThunk(API_ACTIONS.GET_IMAGE_LIST, imageApi.searchImage);
export const clearImage = () => ({type: ACTIONS.CLEAR_IMAGE});

const initialState = {
    imageContent: reducerUtils.initial(),
    imageList: reducerUtils.initial(),
};

/**
 * 영상업로드 관리에서 영상 목록을 불러오고 관리하거나, 영상관리에서 영상 파일 목록을 조회할때 사용
 * @param state
 * @param action
 * @returns {*|{videoFile: {data: null, loading: boolean, error: null}}}
 */
export default function imageReducer(state = initialState, action) {
    switch (action.type) {
        case API_ACTIONS.GET_IMAGE_CONTENT:
        case API_ACTIONS.GET_IMAGE_CONTENT_ERROR:
        case API_ACTIONS.GET_IMAGE_CONTENT_SUCCESS:
            return handleAsyncActions(API_ACTIONS.GET_IMAGE_CONTENT, 'imageContent')(state, action);

        case API_ACTIONS.GET_IMAGE_LIST:
        case API_ACTIONS.GET_IMAGE_LIST_ERROR:
        case API_ACTIONS.GET_IMAGE_LIST_SUCCESS:
            return handleAsyncActions(API_ACTIONS.GET_IMAGE_LIST, 'imageList')(state, action);

        case ACTIONS.CLEAR_IMAGE:
            return {
                ...state,
                imageContent: reducerUtils.initial(),
                imageList: reducerUtils.initial(),
            }

        default:
            return state;
    }
}