import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {getBannerList} from "../../../modules/homeReducer";
import {Autoplay, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import useSwiperRef from "../../useSwiperRef";
import classNames from "classnames";
import {makeResourceUrl} from "../../../util/url";

export default function Banner() {

    const dispatch = useDispatch();
    const bannerList = useSelector(state => state.homeReducer.bannerList);
    const [data, setData] = useState([]);

    const [nextEl, nextElRef] = useSwiperRef();
    const [prevEl, prevElRef] = useSwiperRef();
    const [paginationEl, paginationElRef] = useSwiperRef();
    const [pause, setPause] = useState(false);
    const swiperRef = useRef();

    useEffect(()=>{
        dispatch(getBannerList());
    }, [])

    useEffect(()=> {
        if(pause){
            swiperRef?.current?.swiper?.autoplay?.stop();
        }
        else {
            swiperRef?.current?.swiper?.autoplay?.start();
        }
    }, [pause])

    useEffect(()=>{
        if(bannerList.data != null){
            const value = bannerList.data?.data?.hits?.hits?.map(it => it._source);
            // console.log(value)
            setData(value);
        }
    }, [bannerList.data, bannerList.error, bannerList.loading])

    return (
        <article className="visula__slide__wrap">
            <div className="swiper visual__slide">
                <Swiper
                    ref={swiperRef}
                    modules={[Navigation, Pagination, Autoplay]}
                    // navigation={true}
                    navigation={{
                        enabled: true,
                        prevEl: prevEl,
                        nextEl: nextEl,
                    }}
                    pagination={{
                        clickable: true,
                        el: paginationEl,
                    }}
                    slidesPerView={'auto'}
                    slidesPerGroup={1}
                    autoplay={{
                        delay: 5000,
                    }}
                    loop={true}
                    loopedSlides={1}
                    centeredSlides={false}
                    scrollbar={{ draggable: true }}
                >
                    {
                        data.map(it=>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <a href={it.remarks} target="_blank">
                                        <div className="slide_img">
                                            <img src={makeResourceUrl(it)}/>
                                        </div>
                                    </a>
                                </div>
                            </SwiperSlide>
                        )
                    }
                </Swiper>
                <div className="pagenation-control">
                    <div className="pagenation-wrap">
                        <div className="swiper-pagination" ref={paginationElRef}></div>
                        <div className={classNames({
                            'swiper-btn-pause': true,
                            'active': pause
                        })}
                             onClick={(e)=> setPause(!pause)}></div>
                    </div>
                </div>
                <div className="swiper-control">
                    <button className="swiper-button-prev" ref={prevElRef}></button>
                    <button className="swiper-button-next" ref={nextElRef}></button>
                </div>
            </div>
        </article>
    )
}