import {Link} from "react-router-dom";

export default function Footer() {
    let quickGuidManualUrl = "https://gpazjnfhanuq13159679.cdn.ntruss.com/2023/01/13/1207/%EC%B6%95%EA%B5%AC%ED%98%91%ED%9A%8C%20%EC%95%84%EC%B9%B4%EC%9D%B4%EB%B8%8C%20%EC%8B%9C%EC%8A%A4%ED%85%9C%20Quick%20Guide.pdf";
    let fullManualUrl = "https://gpazjnfhanuq13159679.cdn.ntruss.com/2023/01/13/1207/%EC%B6%95%EA%B5%AC%ED%98%91%ED%9A%8C%20%EC%95%84%EC%B9%B4%EC%9D%B4%EB%B8%8C%20%EC%8B%9C%EC%8A%A4%ED%85%9C%20%EB%A7%A4%EB%89%B4%EC%96%BC.pdf";

    return (
        <footer className="footer__">
            <div className="inner">
                <div className="ft__gng__area">
                    <div className="gnb__menu">
                        <ul>
                            <li>
                                <Link to="/terms">이용약관</Link>
                            </li>
                            <li>
                                <Link to="/terms/1">개인정보처리방침</Link>
                            </li>
                            <li>
                                <Link to="/terms/2">이메일무단수집거부</Link>
                            </li>
                            <li>
                                <a
                                    href={quickGuidManualUrl}
                                    target={quickGuidManualUrl}
                                >
                                    간단 메뉴얼</a>
                            </li>
                            <li>
                                <a
                                    href={fullManualUrl}
                                    target={fullManualUrl}
                                >
                                    사용자 메뉴얼</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="text_copyright">
                    <span>Copyright ⓒ KFA. All Rights Reserved.</span>
                </div>
            </div>
        </footer>
    )
}