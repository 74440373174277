
export default function GalleryMainGridItem({index, item, showDialog}) {
    return <div className="css-grid__link w-[50%] h-[50%]"
         onClick={e => showDialog(item, index)}
    >
        <img className="absolute-bg" style={{objectFit: 'contain'}} src={item.thumbSrc}/>
        <div className="css-grid-desc p-4 h-full flex-col justify-end">
            <div className="txt_sub txt_bold mb_4">{item.menuNm}</div>
            <div style={{
                overflow: "hidden",
                textOverflow: 'ellipsis',
                lineClamp: 2,
            }}>{item.title}</div>
        </div>
    </div>
}