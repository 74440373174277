import {MessageError} from "../common/component/ErrorBoundary";
import {clearLogin} from "../modules/userReducer";

export function handleContentCode(data, loginData, dispatch) {
    if(data?.code === "99"){
        if(loginData == null){
            throw new MessageError('로그인이 필요합니다.');
        }
        else {
            dispatch(clearLogin());
            throw new MessageError('로그인이 만료되었습니다. 다시 로그인 해주세요');
        }
    }
    else if(data?.code === '82'){
        if(loginData != null){
            throw new MessageError("시청 권한이 없습니다. 관리자에게 문의해주세요")
        }
        else {
            dispatch(clearLogin());
            throw new MessageError('로그인이 만료되었습니다. 다시 로그인 해주세요');
        }
    }
    else if(data?.code === '83'){
        if(loginData != null){
            throw new MessageError(data?.message ?? '가입 승인 대기중 상태입니다.')
        }
        else {
            dispatch(clearLogin());
            throw new MessageError('로그인이 만료되었습니다. 다시 로그인 해주세요');
        }
    }
    else if(data?.code === '71'){
        throw new MessageError('해당 영상을 조회 할 수 없습니다');
    }
    else if (data?._source == null && data?.data?._source == null) {
        throw new MessageError('해당 영상을 조회 할 수 없습니다');
    }
}