export default class VideoContentItem{
    constructor({
                    category,
                    cid,
                    file,
                    hash,
                    menuId,
                    player,
                    attach,
                    videocontent,
                    imagerelation,
                    videorelation,
                    tokenUrl,
                } = {}) {
        this.category = category;
        this.cid = cid;
        this.file = file;
        this.hash = hash?.map(it => it.hashNm);
        this.menuId = menuId;
        this.attach = attach;
        this.player = player;
        this.videocontent = videocontent?.[0];
        this.imagerelation = imagerelation;
        this.videorelation = videorelation;
        this.tokenUrl = tokenUrl;
    }

}