import {Link} from "react-router-dom";
import ThumbnailImage from "../ThumbnailImage";

/**
 * @param title
 * @param category
 * @param categoryOnTop
 * @param date
 * @param thumbnail
 * @param link
 * @param state
 * @param action
 * @return {JSX.Element}
 * @constructor
 */
export default function PreviewGridItem({
                                            title,
                                            category,
                                            categoryOnTop,
                                            date,
                                            thumbnail,
                                            link,
                                            state,
                                            action,
                                            children,
                                        }) {

    const Wrapper = ({children}) => action != null ?
        <button onClick={e => action(e)}>
            {children}
        </button>
        :
        <Link to={link} state={state}>
            {children}
        </Link>

    return (
        <div className="swiper-slide">
            <Wrapper>
                <div className="video__card">
                    <div className="video__prev">
                        <div className="video_thum">
                            <ThumbnailImage src={thumbnail}/>
                            <div className="thum_mask">
                                {
                                    // Todo: state가 'ON-AIR'를 통해 분기했는데, 데이터를 모르는 상태로 작성해 상태를 나타내는 코드가 별도로 있을 경우 수정 필요합니다.
                                    state === 'ON-AIR' ? <>
                                        <div className="live_state onAir">ON-AIR</div>
                                        <div className="circle">
                                            <i className="icon gas-play"></i>
                                        </div>
                                    </> : state === 'NOT_STARTED' ? <div className="circle txt_head7">
                                        <span>{new Date(date).format("MM/dd")}</span><span>{new Date(date).format("a/p hh:mm")}</span>
                                    </div> : null
                                }
                                {children}
                            </div>
                        </div>
                    </div>
                    <div className="video__info">
                        {categoryOnTop ? <div className="txt_bold txt_primary">{category}</div> : null}
                        <div className="txt_subject">{title}</div>
                        <div className="txt_sub">
                            {
                                date != null &&
                                <span className="txt_sub">{date}</span>
                            }
                            {
                                category != null && !categoryOnTop &&
                                <span className="txt_sub">{category}</span>
                            }
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>
    )
}