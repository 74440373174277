import {Link} from "react-router-dom";
import ThumbnailImage from "../ThumbnailImage";

/**
 * @param title
 * @param category
 * @param categoryOnTop
 * @param date
 * @param thumbnail
 * @param link
 * @param state
 * @return {JSX.Element}
 * @constructor
 */
export default function PreviewEventListItem({
                                                 id,
                                                 year,
                                                 title,
                                                 titleForList,
                                                 date,
                                                 time,
                                                 event,
                                                 player,
                                                 home,
                                                 away,
                                                 thumbnail,
                                                 link,
                                                 children,
                                             }) {

    return (
        <tr>
            <td>{id}</td>
            <td>
                <div className="prev_thum">
                    <ThumbnailImage src={thumbnail}/>
                </div>
            </td>
            {/*<td>{year}</td>*/}
            <td>{event}</td>
            <td>{player}</td>
            <td><Link to={link}>{titleForList ?? title}</Link></td>
            {/*<td>{date?.substring(5)}</td>*/}
            <td>{time}</td>
            <td>{home}</td>
            <td>{away}</td>
        </tr>
    )
}