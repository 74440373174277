import React, {useCallback, useEffect, useRef, useState} from "react";
import {Dialog, DialogActions, DialogContent} from "@mui/material";
import './SSONoticeStyle.css';
import useSSO from "../../../../lib/useSSO";
import useLocation2 from "../../../../util/useLocation2";

export default function SSONoticePopup() {
    const donotborder = useRef();
    const {isSSOUser, goSSOIntegrate2} = useSSO();
    const {pathname} = useLocation2();

    const shouldShow = useCallback(() => {
        if(isSSOUser === true){
            return false;
        }

        if(pathname.startsWith('/sso')) {
            return false;
        }

        let untilStr = localStorage.getItem('SSONoticePopupNotShowUntil');
        let should = true;
        if(untilStr != null && untilStr !== ''){
            let nowStr = new Date().format('yyyyMMdd');
            should = nowStr > untilStr;
        }
        return should;
    }, []);

    const onClose = useCallback(() => {
        setShow(false);
        let checked = donotborder.current.checked;
        if(checked) {
            let until = new Date().format('yyyyMMdd');
            localStorage.setItem('SSONoticePopupNotShowUntil', until);
        }
    }, []);

    useEffect(() => {
        if(pathname.startsWith('/sso')) {
            setShow(false);
        }
    }, [pathname])

    const [show, setShow] = useState(shouldShow);

    return <Dialog open={show} maxWidth={'xl'} PaperProps={{sx: {borderRadius: '1rem'}}}>
        <DialogContent>
            <div className="sso-popup ">
                <div className="flex-1 overflow-y-auto">
                    <div className="top">
                        <div className="bg-crop">
                            <img className="bg-tiger" src="/image/kfa_105_11_R.png"/>
                        </div>
                        <h1 className="kfa-font">KFA 통합회원 전환에 대한 안내문<span className="cursor">&nbsp;</span></h1>
                    </div>
                    <div className="mid">
                        <ul>
                            <li className="kfa-font">KFA 통합회원 서비스가 오픈함에 따라</li>
                            <li className="kfa-font">통합회원으로 전환하시면 대한축구협회가 운영하는 서비스를</li>
                            <li className="kfa-font">별도의 추가 회원 가입없이 편리하게 이용하실 수 있습니다.</li>
                            <li className="kfa-font">기존 회원이신 경우 KFA 통합회원으로 전환해 주시기 바랍니다.</li>
                        </ul>
                    </div>

                    <button className="btn-skew-normal-red w-200 m-l-40" type="button" title="통합회원 전환하기"
                            onClick={() => goSSOIntegrate2()}>통합회원 전환하기
                    </button>
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            <div className="w-full flex items-center justify-between ml-[40px] mr-[40px]">
                <div className="flex items-center gap-1">
                    <input
                        id="dontborder"
                        ref={donotborder}
                        type="checkbox"
                        className="input_check"
                    />
                    <label for="dontborder">1일간 열지 않음.</label>
                </div>
                <button
                    onClick={onClose}
                >
                    [닫기]
                </button>
            </div>
        </DialogActions>
    </Dialog>
}