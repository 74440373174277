import {searchEngineApiInstance} from '../utils/index';

export const searchVideo = ({
                                query = { "match_all": {} } ,
                                size,
                                from = 0,
                                index,
                                sortQuery,
                            })=>{
    return searchEngineApiInstance.post(
        ``,
        {
            "track_total_hits": true,
            "query": query,
            "from": from,
            "size": size,
            "aggs": {
                "total_count": {
                    "value_count": {
                        "field": "cid"
                    }
                }
            },
            "sort": sortQuery ?? [
                {
                    "videocontent.regDt": "desc"
                }
            ]
        },
        {
            headers: {
                'subUrl': `/video/_search`,
                "Content-Type": "application/json",
            }
        }
    )
        .then(res => {
            res.index = index;
            return res;
        });
}

export const getVideo = (cid)=>{
    return searchEngineApiInstance.post(
        ``,
        {},
        {
            headers: {
                'subUrl': `/video/_doc/${cid}`,
                "Content-Type": "application/json",
            }
        }
    );
}


/**
 * 복수의 항목을 검색. 관련영상 조회용
 * @param {[number]} cids
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getVideos = (cids, copyright)=>{

    const query = {
        "bool": {
            "filter": {
                "terms": {
                    "_id": cids,
                }
            }
        }
    };

    if(copyright != null) {
        query.bool.must = [{
            "term": {
                "videocontent.copyright_yn": copyright,
            }
        }];
    }

    return searchEngineApiInstance.post(
        ``,
        {
            "query": query,
            "from": 0,
            "size": cids.length,
        },
        {
            headers: {
                'subUrl': `/video/_search`,
                "Content-Type": "application/json",
            }
        }
    );
}


/**
 * 관련영상 조회용
 * @param {number} singleIdx
 * @param {number} matchIdx
 * @param {String} matchClass
 * @param {number} ignoreCid
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getGameRelatedVideos = (singleIdx, matchIdx, matchClass, ignoreCid, copyright)=>{

    const query = {
        "bool": {
            "must": [
                {
                    "bool": {
                        "must": [
                            {
                                "term": {
                                    "match_class": matchClass
                                }
                            },
                            {
                                "term": {
                                    "match_idx": matchIdx
                                }
                            },
                            {
                                "term": {
                                    "single_idx": singleIdx
                                }
                            },
                        ]
                    }
                }
            ],
        }
    };

    if(copyright != null) {
        query.bool.must[0].bool.must.push({
            "term": {
                "videocontent.copyright_yn": copyright,
            }
        })
    }

    if(ignoreCid != null){
        query.bool.must_not = [
            {
                "bool": {
                    "must": [
                        {
                            "term": {
                                "cid" : ignoreCid
                            }
                        }
                    ]
                }
            }
        ]
    }

    return searchEngineApiInstance.post(
        ``,
        {
            "query": query,
            "aggs": {
                "total_count": {
                    "value_count": {
                        "field": "cid"
                    }
                }
            },
            "from": 0,
            "size": 999,
            "sort": [
                {
                    "videocontent.regDt": "desc"
                }
            ]
        },
        {
            headers: {
                'subUrl': `/video/_search`,
                "Content-Type": "application/json",
            }
        }
    );
}