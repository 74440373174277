import useChromeCast from "./useChromeCast";
import CastIcon from '@mui/icons-material/Cast';
import classNames from "classnames";
import {useMemo} from "react";

export default function ChromecastButton({url, onConnected, onDisconnected}) {

    const {startCast, available} = useChromeCast({onConnected, onDisconnected});

    const _available = useMemo(() => {
        return available && url != null && url !== '';
    }, [url, available])

    return <div className={classNames({
        'flex justify-end mt-2': true,
        'visible': _available,
        'invisible': !_available,
    })}>
        <button
            onClick={e => startCast(url)}>
            <div className="flex">
                <span className="mr-2">Chromecast 보기</span>
                <CastIcon/>
            </div>
        </button>
    </div>
}