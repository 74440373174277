import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {WatermarkInfo} from "./videoPlayer";

/**
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<PlayerOption> & React.RefAttributes<unknown>>}
 */

const FlexPlayer = forwardRef(({option = {}}, ref) => {
    const [player, setPlayer] = useState(null);
    const [_option, _setOption] = useState({...option});
    const watermarkData = useSelector(state => state.watermarkReducer.videoWatermark)

    useEffect(()=> {
        return ()=> {
            // player?.remove();
        }
    }, [player]);

    useEffect(()=> {
        if(watermarkData){
            initPlayer();
        }
    }, [watermarkData])

    useImperativeHandle(ref, () => ({
        initialize(option) {
            _setOption(option);
        },
        getState() {
            return player.getState()
        },
        play() {
            player.play();
        },
        pause() {
            player.pause();
        }
    }));

    useEffect(()=> {
        initPlayer();
    }, [_option])

    const initPlayer = () => {
        let o = _option; // ?? option;
        // setOption(o);
        // console.log('initializePlayer', o);
        let watermarkInfo;
        if(watermarkData?._source != null){
            watermarkInfo = WatermarkInfo.of({...watermarkData?._source});
        }

        const _o = {
            waterMark: watermarkInfo == null ? null : {
                image: watermarkInfo.url,
                position: 'bottom-right',
                opacity: watermarkInfo.opacity,
            },
            // image: o.image,
            autoStart: false, // allowed when mute=true
            autoFallback: true, // if playback fails, next will be played automatically
            controls: true, // show control bar
            disableSeekUI: true, // disable users to seek using a progress bar or keyboard interaction
            showSeekControl: false, // show or hide the quick seek buttons
            expandFullScreenUI: true, // full screen with double click
            mute: false,
            volume: 100,
            sources: o.sources,
            debug: false,
            lang: 'ko',
            hlsConfig: {
                debug: false,
            }
        };
        // console.log('option', _o);
        const p = window.FlexPlayer.create('flexPlayer', _o);
        setPlayer(p);
    }


    return <div >
        <div style={{
            backgroundColor: '#000',
            color: '#fff',
            aspectRatio: 16 / 9,
        }}>
            {
                player == null &&

                <div className="flex flex-col items-center justify-center h-full" >
                    <span>영상 정보가 없습니다</span>
                </div>
            }
            {
                <div style={{
                    display: 'block',
                }}>
                    <div className="notranslate" translate="no" id="flexPlayer" style={{
                        // display: player !== null ? 'none' : 'unset'
                    }}/>
                </div>
            }
        </div>
    </div>
})

export default FlexPlayer;