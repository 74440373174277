import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import FlexFillCenter from "../../FlexFillCenter";
import useSSO, {ssoSilentLogin} from "../../../../lib/useSSO";


export default function KFASSOSilentLoginPage() {

    const navigate = useNavigate();
    const {isSSOUser, goSSOInfoChange} = useSSO();
    const loginData = useSelector(state => state.userReducer.loginData);

    useEffect(() => {
        if(loginData == null){
            ssoSilentLogin();
        }
        else {
            navigate('/');
        }
    }, []);

    return (
        <FlexFillCenter
            absolute={true}
        >
            <span>로그인 중입니다.</span>
        </FlexFillCenter>
    )
}