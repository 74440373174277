import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo, useRef, useState} from "react";
import {clearVideo, getEventListForEventPlayer, getVideoByEvent, selectEvent} from "../../../modules/videoReducer";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import VideoContentItem from "../../../apis/dto/VideoContentItem";
import VideoPlayer, {MatchInfo, PlayerOption} from "../../../common/component/videoPlayer";
import classNames from "classnames";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import EventModel from "../model/EventModel";
import {scheme} from "../../../util/url";
import {useLoadingModal} from "../../../common/component/LoadingState";
import {applyMatchOffset, secToHHmmss} from "../../../lib/videoOffsetCalcUtil";
import {getWatermark} from "../../../modules/watermarkReducer";
import {getAsFlat} from "../../../lib/menu/menuUtil";
import EventContentItem from "../../../apis/dto/EventContentItem";
import RelativeImageList from "../gamePlay/component/RelativeImageList";
import RelativeVideoList from "../gamePlay/component/RelativeVideoList";
import queryString from "query-string";
import {EventSearchParam} from "../../../apis/dto/SearchParam";
import Config from "../../../config/config";
import EventListWithPage from "./component/EventListWithPage";
import {ShowAlertModal} from "../../../common/component/modal/AlertModal";
import {
    addFavoriteContent,
    clearRequestVODDownload,
    clearUpdateFavoriteContentResult,
    deleteFavoriteContent,
    requestDownload
} from "../../../modules/userReducer";
import {PartialDownloadState} from "../gamePlay/component/PartialDownloadTable";
import {handleContentCode} from "../../../util/contentUtil";
import {getTotalVideoRelation} from "../../../modules/relationReducer";
import VideoOffsetUtil from "../../../lib/videoOffsetCalcUtil2";

export default function EventPlayPage({}) {
    const navigate = useNavigate();
    const location = useLocation();
    const param = useParams();
    const eventId = param.id;
    const dispatch = useDispatch();

    const videoPlayerRef = useRef();

    const menus = useSelector(state => state.menuReducer.menus);
    const watermark = useSelector(state => state.watermarkReducer.watermarkResult);
    const eventOne = useSelector(state => state.videoReducer.eventOne)
    const video = useSelector(state => state.videoReducer.videoContent)
    const event = useSelector(state => state.videoReducer.eventListForEventPlay);

    const [searchParam, setSearchParam] = useSearchParams();
    const [data, setData] = useState(new VideoContentItem());
    const [videoTypeList, setVideoTypeList] = useState([]);
    const [selectedVideoTypeIndex, setSelectedVideoTypeIndex] = useState(null);
    const [videoPlayOption, setVideoPlayOption] = useState();
    const selectedEvent = useSelector(state => state.videoReducer.selectEvent);
    const [showEventList, setShowEventList] = useState(true);
    const [eventPage, setEventPage] = useState();
    const [totalEventSize, setTotalEventSize] = useState(0);

    const loginData = useSelector(state => state.userReducer.loginData);

    const [isFavorite, setIsFavorite] = useState(false);
    const addFavoriteContentResult = useSelector(state => state.userReducer.addFavoriteContentResult);
    const deleteFavoriteContentResult = useSelector(state => state.userReducer.deleteFavoriteContentResult);
    const {isLoading, hasError} = useLoadingModal([video, event, watermark, menus]);
    const [showingEventList, setShowingEventList] = useState([]);

    // 경기기록 구간 다운로드
    const requestDownloadResult = useSelector(state => state.userReducer.requestDownload);
    const [canDownload, setCanDownload] = useState(false);

    const videoOffsetUtil = useMemo(() => {
        return VideoOffsetUtil.fromPortalVideo(video);
    }, []);

    const _setEventPage = (page) => {
        setEventPage(page);

        const query = queryString.parse(location.search);
        const param = queryString.parse(query.boardParam);
        console.log(param);
        // dispatch()
        // setEventPage(p2.page);
        const p2 = new EventSearchParam({
            ...param,
            page: Number(page),
        })
        dispatch(getEventListForEventPlayer(p2));
        setSearchParam({'boardParam': queryString.stringify({...p2.toHistory()})}, {replace: true})
    }

    useEffect(()=> {
        if(!isLoading){
            setIsFavorite(video?.data?.data?.faverYN === "Y");
            if(location.state?.eventId != null){
                videoPlayerRef.current?.addPlayerLoadedCallback(()=> {
                    dispatch(selectEvent(location.state.eventId))
                })
            }
        }
        return () => {
            if(location.state?.eventId != null){
                dispatch(selectEvent(null))
            }
        }
    }, [isLoading])

    useEffect(()=> {
        if(event.data != null){
            // console.log(event.data);
            const data = event.data?.data?.hits?.hits?.map(it => it._source).map(it => EventModel.of2(it));
            // console.log(data);
            setTotalEventSize(event.data?.data?.aggregations?.total_count?.value ?? 0)
            setShowingEventList(data)
        }
    }, [event.data, event.loading, event.error]);

    useEffect(()=> {
        if(loginData == null){
            ShowAlertModal({
                message: '로그인이 필요한 기능입니다.',
                onConfirm: () => {
                    navigate("/user/login", { state: {redirectUrl: location.pathname}})
                }
            })
            return;
        }
        // dispatch(getVideo(eventId))
        // dispatch(getEventList(eventId)) // TODO 진입 검색 조건에 맞는 이벤트 목록 조회
        dispatch(clearVideo());
        dispatch(getWatermark());
        dispatch(getVideoByEvent(eventId));
        setCanDownload(loginData?.download_ac >= 2)
        let p2;
        const queryParameter = queryString.parseUrl(location.search)
        console.log(queryParameter);
        if(queryParameter?.query?.boardParam != null) {
            const eventSearchParam = decodeURIComponent(queryParameter.query.boardParam);
            // console.log(eventSearchParam);
            const p = queryString.parse(eventSearchParam)

            // console.log(p)
            p2 = new EventSearchParam(p)
        }
        else {
            p2 = new EventSearchParam({
                page: 0,
                size: Config.videoSideContentPageSize,
            });
        }
        if(p2.size !== Config.videoSideContentPageSize){
            const originIndex = p2.page * p2.size + Number(p2.index);
            const newPage = Math.floor(originIndex / Config.videoSideContentPageSize)
            p2 = new EventSearchParam({
                ...p2,
                page: newPage,
                size: Config.videoSideContentPageSize,
            })
        }
        else {
            p2 = new EventSearchParam({
                ...p2,
                page: p2.page,
                size: Config.videoSideContentPageSize,
            })
        }
        setEventPage(p2.page);
        dispatch(getEventListForEventPlayer(p2));
        setSearchParam({'boardParam': queryString.stringify({...p2.toHistory()})}, {replace: true})

        return () => {
            dispatch(clearVideo())
        }
    }, [param.id])

    useEffect(()=> {
        if(selectedEvent != null){
            if(eventId != selectedEvent){
                console.log('selectedEvent', location.search);
                navigate(`/event/video/${selectedEvent}${location.search}`);
                // dispatch(getVideoByEvent(selectedEvent));
            }
        }
    }, [selectedEvent])

    useEffect(()=> {
        if(isLoading === false){
            handleContentCode(video?.data, loginData, dispatch); // event-video
            handleContentCode(eventOne.data, loginData, dispatch); // event-event
            const data = video?.data?.data?._source;
            const event = eventOne.data?._source?.event?.[0];
            const tokenUrl = {};
            Object.keys(video?.data?.data).filter(it => it.startsWith('tokenUrl')).forEach(key => tokenUrl[key] = video?.data?.data?.[key]);

            // console.log(data);
            const newVideoData = new EventContentItem({
                    ...data,

                    event,
                    tokenUrl,
                }
            )
            setData(new EventContentItem({
                ...data,
                event,
                tokenUrl
            }))

            dispatch(getTotalVideoRelation({
                // cid: newVideoData.videocontent.cid, // 이벤트는 cid 필터 제외
                singleIdx: newVideoData.videocontent.single_idx,
                matchIdx: newVideoData.videocontent.match_idx,
                matchClass: newVideoData.videocontent.match_class,
                relationCids: newVideoData.videorelation.map(it => it.refcid)
            }));


            setVideoTypeList(
                data.file
            )
            // console.log('menus',menus);
            const menuList = getAsFlat(menus.data);
            const menu = menuList.find(it => it.menuId === data.menuId);
            if(menu != null){
                if(menu.matchMappingYN === "N"){
                    setShowEventList(false);
                }
            }
        }
    }, [isLoading])

    useEffect(()=> {
        if(videoTypeList.length > 0){
            // console.log('videoTypeList', videoTypeList)
            setSelectedVideoTypeIndex(0);
        }
    }, [videoTypeList])

    useEffect(()=> {
        // console.log('selectedVideoTypeIndex', selectedVideoTypeIndex)
        if(video.data != null && event.data != null){ // TODO 이벤트 목록 설정
            if(selectedVideoTypeIndex != null){
                if(videoTypeList[selectedVideoTypeIndex] != null){
                    // console.log('2023-05-22 initializePlayer', eventOne, video.data, videoTypeList[selectedVideoTypeIndex])
                    initializePlayer(videoTypeList[selectedVideoTypeIndex])
                }
            }
        }
    }, [video.data, selectedVideoTypeIndex, showingEventList, videoTypeList])

    useEffect(()=> {
        // console.log('videoPlayOption', videoPlayOption)
        if(videoPlayOption != null){
            // console.log('2023-05-22 videoPlayOption', videoPlayOption);
            videoPlayerRef.current?.initialize(videoPlayOption)
        }
    }, [videoPlayOption])

    const toggleFavorite = () => {
        if (!isFavorite) {
            dispatch(addFavoriteContent({
                userId: loginData.uid,
                userLevelCd: 1,
                cid: data.cid,
                contenttype: "V"
            }));
        } else {
            dispatch(deleteFavoriteContent({
                userId: loginData.uid,
                cid: data.cid,
                contenttype: "V",
            }));
        }
    }
    useEffect(() => {
        if (addFavoriteContentResult.error) {
            ShowAlertModal({
                message: "찜 목록 추가에 실패했습니다.",
            });
            dispatch(clearUpdateFavoriteContentResult())

        } else if (addFavoriteContentResult.data) {
            if (addFavoriteContentResult.data.data?.code !== '00') {
                ShowAlertModal({
                    message: "찜 목록 추가에 실패했습니다.",
                });
            } else {
                ShowAlertModal({
                    message: "찜 목록에 추가되었습니다.",
                });
                setIsFavorite(true)
            }
            dispatch(clearUpdateFavoriteContentResult())
        }
    }, [addFavoriteContentResult])


    useEffect(() => {
        if (deleteFavoriteContentResult.error) {
            ShowAlertModal({
                message: "찜 목록 제거에 실패했습니다.",
            });
            dispatch(clearUpdateFavoriteContentResult())

        } else if (deleteFavoriteContentResult.data) {
            if (deleteFavoriteContentResult.data.data?.code !== '00') {
                ShowAlertModal({
                    message: "찜 목록 제거에 실패했습니다.",
                });
            } else {
                ShowAlertModal({
                    message: "찜 목록에서 제거되었습니다.",
                });
                setIsFavorite(false)
            }
            dispatch(clearUpdateFavoriteContentResult())
        }
    }, [deleteFavoriteContentResult])

    const initializePlayer = (videoSource)=> {
        // console.log('initializePlayer', videoSource)
        if (videoSource?.cid != null) {

            let matchInfoList = Object.keys(videoSource)
                .filter(it => videoSource[it] != null )
                .filter(it => it.toLowerCase().endsWith("pos"))
                .map(it => MatchInfo.fromKey(it, videoSource[it]));
            matchInfoList = videoOffsetUtil.applyMatchOffset(matchInfoList);

            const videoSources = [];
            const tokenUrl = data.tokenUrl[`tokenUrl_${videoSource.fileId}`];
            const offset = {
                use: false,
            };
            if(eventOne != null){
                const event = EventModel.of2(eventOne.data?._source);
                const {startTime, endTime} = videoOffsetUtil.getSection(event, matchInfoList);
                // console.log(event, startTime, endTime);
                offset.use = true;
                offset.start = startTime;
                offset.end = endTime;
            }
            if(videoSource.proxyFile1){
                videoSources.push({
                    src: `${scheme}${videoSource.proxy_cdnUrl}${videoSource.orgPath}${videoSource.proxyFile1}?token=${tokenUrl}`,
                    type: "video/mp4",
                    label: "FHD",
                    ...videoSource,
                    // offset,
                })
            }
            if(videoSource.proxyFile2){
                videoSources.push({
                    src: `${scheme}${videoSource.proxy_cdnUrl}${videoSource.orgPath}${videoSource.proxyFile2}?token=${tokenUrl}`,
                    type: "video/mp4",
                    label: "HD",
                    ...videoSource,
                    // offset,
                })
            }
            if(videoSource.proxyFile3){
                videoSources.push({
                    src: `${scheme}${videoSource.proxy_cdnUrl}${videoSource.orgPath}${videoSource.proxyFile3}?token=${tokenUrl}`,
                    type: "video/mp4",
                    label: "SD",
                    ...videoSource,
                    // offset,
                })
            }
            setVideoPlayOption(new PlayerOption({
                videoSources: videoSources,
                fileId: videoSource.fileId,
                spriteCount: videoSource.spriteFiles,
                path: videoSource.proxyPath,
                offset: offset,
            }));
        }
    };

    const handlePartialDownload = () => {
        const videoSource = videoTypeList[selectedVideoTypeIndex];

        let matchInfoList = Object.keys(videoSource)
            .filter(it => videoSource[it] != null )
            .filter(it => it.toLowerCase().endsWith("pos"))
            .map(it => MatchInfo.fromKey(it, videoSource[it]));
        matchInfoList = videoOffsetUtil.applyMatchOffset(matchInfoList);

        const event = EventModel.of2(eventOne.data?._source);
        const {startTime, endTime} = videoOffsetUtil.getSection(event, matchInfoList);
        // console.log('handlePartialDownload', startTime, endTime, secToHHmmss(startTime), secToHHmmss(endTime))
        dispatch(requestDownload(new PartialDownloadState({
            sourceType: 'V',
            fileTitle: `${data.event?.eventTitle ?? `${data.videocontent?.ma_title ?? ''}_${data.videocontent?.title ?? ''}`}_${new Date().format('yyyyMMddHHmmss')}`.replaceAll(" ", "_"),
            video_fileId: videoSource.fileId,
            video_cid: data.cid,
            uid: loginData.uid,
            startPos: secToHHmmss(startTime),
            endPos: secToHHmmss(endTime),
        })));
    }

    useEffect(() => {
        if(requestDownloadResult?.error != null){
            ShowAlertModal({
                message: '구간 다운로드 요청중 오류가 발생했습니다',
            })
            dispatch(clearRequestVODDownload())
        }
        else if(requestDownloadResult?.data != null){
            if(requestDownloadResult.data?.data?.code != "00"){
                ShowAlertModal({
                    message: `오류가 발생했습니다 - ${requestDownloadResult.data?.data?.message ?? ''}`,
                })
            }
            else {
                ShowAlertModal({
                    message: '다운로드 요청 성공',
                    onConfirm: () => {

                    }
                })
            }
            dispatch(clearRequestVODDownload())
        }
    }, [requestDownloadResult])


    if(isLoading){
        return ""; // 로딩중
    }

    return (
        <>
            <div className="inner">
                <article className="content__wrap order__wrap">
                    <article className="content__wrap order__item">
                        <div className="content_head mb_32">
                            <div className="head_title">
                                <div className="txt_head3">{data.event?.eventTitle ?? ''}</div>
                            </div>
                            <div className="head__tool">
                                <div className={classNames({
                                    "btn btn-sm w-max": true,
                                    "btn-default": !isFavorite,
                                })} onClick={toggleFavorite}>
                                    <i className="icon gas-like"></i><span>찜하기</span></div>
                            </div>
                        </div>
                        <VideoPlayer
                            playOption={videoPlayOption}
                            ref={videoPlayerRef}
                        />
                        {/*<button onClick={pip}>pip</button>*/}
                        <div className="video__edit__wrap mt_24">
                            <article className="edit__tool mb_64">
                                {
                                    videoTypeList.map((it, index) =>
                                        <div
                                            onClick={(e)=>setSelectedVideoTypeIndex(index)}
                                            className={classNames({
                                                'btn btn-default btn-tiny': true,
                                                'active': index === selectedVideoTypeIndex,
                                            })}>
                                            {it.recType}
                                        </div>
                                    )
                                }
                            </article>

                            {
                                canDownload &&
                                <article className="edit_item mb_64">
                                    <div className="content_head mb_32">
                                        <div className="head_title">
                                            <div className="txt_head4">경기기록 구간 다운로드</div>
                                        </div>
                                        <div className="head__tool">
                                            <a className="btn btn-sm" onClick={handlePartialDownload}>다운로드 요청</a>
                                        </div>
                                    </div>
                                </article>
                            }

                            <article className="edit_item mb_64">
                                <div className="content_head mb_32">
                                    <div className="head_title">
                                        <div className="txt_head4">경기 정보</div>
                                    </div>
                                </div>
                                <div className="div__table__area">
                                    <div className="row">
                                        <dl>
                                            <dt>등록날짜</dt>
                                            <dd><span>{data.videocontent?.regDt}</span></dd>
                                        </dl>
                                        <dl>
                                            <dt>경기날짜</dt>
                                            <dd><span>{data.videocontent?.match_dt}</span></dd>
                                        </dl>
                                    </div>
                                    <div className="row">
                                        <dl>
                                            <dt>리그명</dt>
                                            <dd><span>{data.videocontent?.ma_title}</span></dd>
                                        </dl>
                                        <dl>
                                            <dt>카테고리</dt>
                                            <dd><span>{data.videocontent?.category}</span></dd>
                                        </dl>
                                    </div>
                                    <div className="row">
                                        <dl>
                                            <dt>홈팀</dt>
                                            <dd><span>{data.videocontent?.team_home}</span></dd>
                                        </dl>
                                        <dl>
                                            <dt>원정팀</dt>
                                            <dd><span>{data.videocontent?.team_away}</span></dd>
                                        </dl>
                                    </div>
                                    <div className="row">
                                        <dl>
                                            <dt>제목</dt>
                                            <dd><span>{data.videocontent?.title}</span></dd>
                                        </dl>
                                    </div>
                                </div>
                            </article>
                        </div>

                        <article className="edit_item">
                            <div className="content_head mb_32">
                                <div className="head_title">
                                    <div className="txt_head4">해시태그</div>
                                </div>
                            </div>
                            <div className="hash__tag__area">
                                {
                                    data.hash?.map(it => <span className="txt_tag">#{it}</span>)
                                }
                                {
                                    (data.hash?.length ?? 0) === 0 &&
                                    <span className="txt_sub">해시태그가 없습니다.</span>
                                }
                            </div>
                        </article>

                    </article>
                    <article className="content__wrap order__item order__sub">
                        <Tabs
                            // 탭 전체 렌더 https://github.com/reactjs/react-tabs/issues/294#issuecomment-669142441
                            forceRenderTabPanel={true}
                        >
                            <article className="content__tab__area mb_24">
                                <TabList
                                    className="content_tab">
                                    {
                                        showEventList &&
                                        <Tab>
                                            <div className="txt_head4 tab_title">경기기록</div>
                                        </Tab>
                                    }
                                    <Tab>
                                        <div className="txt_head4 tab_title">관련영상</div>
                                    </Tab>
                                    <Tab>
                                        <div className="txt_head4 tab_title">관련사진</div>
                                    </Tab>
                                </TabList>
                            </article>
                            {
                                showEventList &&
                                <TabPanel>
                                    <EventListWithPage
                                        page={eventPage}
                                        setPage={_setEventPage}
                                        totalSize={totalEventSize}
                                        eventList={showingEventList}
                                    />
                                </TabPanel>
                            }
                            <TabPanel>
                                <RelativeVideoList
                                    video={data}
                                    isEvent={true}
                                />
                            </TabPanel>
                            <TabPanel>
                                <RelativeImageList
                                    list={data.imagerelation}
                                />
                            </TabPanel>

                        </Tabs>

                    </article>
                </article>
            </div>


        </>
    )
}