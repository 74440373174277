import {getEventTimeOffsetText} from "../lib/videoOffsetCalcUtil";

/**
 *
 * @param {EventModel} event
 * @returns {string}
 */
export function makePreviewEventTitle(event) {
    const eventTitle = event.title.split('_');
    const eventNm = event.eventNm;
    // const teams = eventTitle[3].split(' vs ')
    // const homeTeam = teams[0]
    // const awayTeam = teams[1];
    const playerName = event.hname ?? '';
    const entryNo = event.entryno ?? '';
    const playerTeam = eventTitle[0] ?? '';
    const position = event.position ?? '';
    const time = eventTitle?.[4] ?? getEventTimeOffsetText(event.timeMisec, event.injuryTimeMisec);

    return `[${eventNm}] ${playerTeam} ${entryNo}${playerName}${position} ${time}`
}

/**
 *
 * @param {EventModel} event
 * @returns {string}
 */
export function makeEventTitle(event) {
    const eventTitle = event.title.split('_');
    const eventNm = event.eventNm;
    // const teams = eventTitle[3].split(' vs ')
    // const homeTeam = teams[0]
    // const awayTeam = teams[1];
    const playerName = event.hname;
    const entryNo = event.entryno;
    const playerTeam = eventTitle[0];
    const position = event.position;

    // return `${playerTeam} / ${entryNo}${playerName}${position}`
    return `${playerTeam}`
}

/**
 *
 * @param {EventModel} event
 * @returns {string}
 */
export function makeMatchTeamVerse(event) {
    // const eventTitle = event.title.split('_');
    // const teams = eventTitle[3].split(' vs ')

    // return eventTitle[3];

    const str = `${event.entryno ?? ''} ${event.hname ?? ''} ${event.position ?? ''}`;
    if(str.trim() === ""){
        return "선수 없음"
    }
    else {
        return str;
    }
}