import {createPromiseThunk, handleAsyncActions, reducerUtils,} from '../lib/asyncUtils';
import * as eventApi from "../apis/api/event";

//액션 타입 정의
const API_ACTIONS = {
    SEARCH_EVENT_BOARD: 'SEARCH_EVENT_BOARD',
    SEARCH_EVENT_BOARD_SUCCESS: 'SEARCH_EVENT_BOARD_SUCCESS',
    SEARCH_EVENT_BOARD_ERROR: 'SEARCH_EVENT_BOARD_ERROR',

    GET_EVENT_CODES: 'GET_EVENT_CODES',
    GET_EVENT_CODES_SUCCESS: 'GET_EVENT_CODES_SUCCESS',
    GET_EVENT_CODES_ERROR: 'GET_EVENT_CODES_ERROR',
};

Object.freeze(API_ACTIONS);

export const searchEventBoard = (searchParam) => createPromiseThunk(API_ACTIONS.SEARCH_EVENT_BOARD, eventApi.searchEvent)(searchParam);
export const getEventCodes = () => createPromiseThunk(API_ACTIONS.SEARCH_EVENT_BOARD, eventApi.getEventCodes)();

const initialState = {
    eventBoardSearchResult: reducerUtils.initial(),
    eventCodes: reducerUtils.initial(),
};

export default function eventBoardReducer(state = initialState, action) {
    switch (action.type) {
        case API_ACTIONS.SEARCH_EVENT_BOARD:
        case API_ACTIONS.SEARCH_EVENT_BOARD_ERROR:
        case API_ACTIONS.SEARCH_EVENT_BOARD_SUCCESS:
            return handleAsyncActions(API_ACTIONS.SEARCH_EVENT_BOARD, 'eventBoardSearchResult')(state, action);

        case API_ACTIONS.GET_EVENT_CODES:
        case API_ACTIONS.GET_EVENT_CODES_ERROR:
        case API_ACTIONS.GET_EVENT_CODES_SUCCESS:
            return handleAsyncActions(API_ACTIONS.SEARCH_EVENT_BOARD, 'eventCodes')(state, action);

        default:
            return state;
    }
}