import FlexFillCenter from "../FlexFillCenter";
import NotFound from "../NotFound";
import PageIndicator from "../PageIndicator";
import {ImageSearchParam} from "../../../apis/dto/SearchParam";
import GalleryGridItem from "./GalleryGridItem";

export default function GallerySearchResult({
    showList,
    showDialog,
    searchParam,
    setSearchParam,
    totalCount,
                                            }) {

    return <>

        <article className="content__wrap">
            {
                showList.length === 0 &&
                <FlexFillCenter children={<NotFound message={'검색 결과가 없습니다.'}/>}/>
            }

            <div className="">
                {
                    showList.map((it, index) =>
                       <GalleryGridItem
                           item={it}
                           index={index}
                           showDialog={showDialog}
                       />
                    )
                }
            </div>

        </article>
        <PageIndicator
            pageSize={ImageSearchParam.pageSize}
            page={searchParam?.page ?? 0}
            setPage={p => setSearchParam(new ImageSearchParam({
                ...searchParam,
                page: p,
            }))}
            itemCount={totalCount}
            usePageInput={true}
        />
    </>
}