import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLoadingModal} from "../../common/component/LoadingState";
import {clearSignup, emailAuth, resendEmail} from "../../modules/userReducer";
import {useLocation, useNavigate} from "react-router-dom";
import {ShowAlertModal} from "../../common/component/modal/AlertModal";
import useQuery from "../../common/useQuery";
import Config from "../../config/config";

export default function EmailAuth() {
    const codeLength = 6;
    const query = useQuery();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const emailAuthResult = useSelector(state => state.userReducer.emailAuthResult);
    const resendEmailResult = useSelector(state => state.userReducer.resendEmailResult);

    useEffect(()=> {
        if(query.get("uid") == null){
            ShowAlertModal({
                message: '가입 정보를 찾을 수 없습니다.',
                onConfirm: (modal)=> {
                    navigate('/');
                }
            });
        }
    }, [query])

    useLoadingModal([emailAuthResult, resendEmailResult]);

    const codeRefs = [
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
    ]
    const codeStates = [
        useState(),
        useState(),
        useState(),
        useState(),
        useState(),
        useState(),
    ]

    const handleCodeInput = (index, e) => {
        codeStates[index][1](e.target.value);
        if(e.target.value.length > 0){
            codeRefs[index + 1]?.current?.focus();
        }
        else {
            codeRefs[index - 1]?.current?.focus();
        }
    }

    useEffect(()=> {
        if(emailAuthResult.data != null){
            if(emailAuthResult.data?.data?.code === "00"){
                ShowAlertModal({
                    message: '이메일이 인증되었습니다.',
                    onConfirm: (modal) => {
                        dispatch(clearSignup());
                        navigate('/user/login');
                    }
                });
            }
            else {
                ShowAlertModal({
                    message: '인증코드를 확인해주세요.',
                    onConfirm: (modal) => {
                        dispatch(clearSignup());
                    }
                });
            }
        }
        else if(emailAuthResult.error != null){
            ShowAlertModal({
                message: '오류가 발생하였습니다.',
                onConfirm: (modal) => {
                    dispatch(clearSignup());
                }
            });
        }
    }, [emailAuthResult])

    useEffect(()=> {
        if(resendEmailResult.data != null){
            if(resendEmailResult.data?.data?.code != "00"){
                ShowAlertModal({
                    message: '이메일 발송에 실패하였습니다.',
                });
                dispatch(clearSignup());
            }
        }
        else if(resendEmailResult.error != null){
            ShowAlertModal({
                message: '이메일 발송에 실패하였습니다.',
            });
            dispatch(clearSignup());
        }
    }, [resendEmailResult])

    const clearCode = () => {
        codeStates.forEach(it => it[1]( ""));
        codeRefs[0].current?.focus();
    }

    const handleEmailAuth = () => {
        dispatch(emailAuth(query.get('uid'), codeStates.map(it => it[0]).join("")));
    }

    const handleResendEmail = () => {
        clearCode();
        dispatch(resendEmail({
            uid: query.get('uid'),
            userEmail: query.get('email'),
        }));
    }

    return (
        <div className="inner">
            <div className="sign-form-area">
                <div className="txt_head2 mb_48">이메일 인증</div>
                <div className="find_alert mb_24">메일로 발송된 인증코드 6자리를 입력하세요.</div>
                <div className="field__code">
                    {
                        codeStates.map((it, index) =>
                            <input ref={codeRefs[index]} type="" name="" maxLength="1" value={codeStates[index][0]} onChange={e => handleCodeInput(index, e)}/>
                        )
                    }
                </div>
                <div className="fied__btn mt_24">
                    <button className="btn sibmit" disabled={codeStates.filter(it => it[0]?.length > 0).length !== codeLength} onClick={handleEmailAuth}>회원가입 완료하기</button>
                </div>
                <div className="code_laert mt_48">
                    <div className="txt_sub mb_16">인증코드가 포함된 메일을 받지 못하셨나요?<br/>스팸메일함에도 확인 바랍니다.</div>
                    <button onClick={handleResendEmail}>인증메일 다시보내기</button>
                </div>
            </div>
        </div>
    )
}