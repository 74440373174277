import NotFound from "../../../../common/component/NotFound";
import FlexFillCenter from "../../../../common/component/FlexFillCenter";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Config from "../../../../config/config";
import {clearRelationList} from "../../../../modules/relationReducer";
import {useLoading} from "../../../../common/component/LoadingState";
import ThumbnailImage from "../../../../common/component/ThumbnailImage";
import PageIndicator from "../../../../common/component/PageIndicator";
import PreviewItemModel from "../../../../common/component/previewItem/model";


/**
 * 넘겨받은 관련 영상 정보를 별도로 검색하여 표출
 * totalList -> page & currentRefList -> videoRelationList -> showList -> 화면 표출
 * @param {VideoContentItem} video
 * @param list
 * @returns {JSX.Element}
 * @constructor
 */
export default function RelativeVideoList({video, isEvent = false}, ) {

    const pageSize = Config.videoSideContentPageSize;
    const dispatch = useDispatch();

    // 전체 항목
    const [totalList, setTotalList] = useState();

    // join 검색 한 결과
    const relationList = useSelector(state => state.relationReducer.videoRelationList);

    // 로딩 상태
    const {isLoading} = useLoading([relationList])

    // 현재 체이지
    const [page, setPage] = useState();

    // 가공된 join 검색 결과
    const [showList, setShowList] = useState([]);

    useEffect(() => {
        return () => {
            dispatch(clearRelationList());
        }
    }, [])

    useEffect(() => {
        if(relationList.data != null){
            const copy = [
                ...relationList.data,
            ]
            const targetList = copy
                .splice((page ?? 0) * pageSize, pageSize)
                .map(it => {
                    const title = video.videorelation?.find(it => it.refcid === it.videocontent?.cid) ?? it.videocontent?.title;
                    return PreviewItemModel.forVideoRelation(it, title);
                })
            // setCurrentRefList(
            //     targetList
            // )

            if(page == null){
                setPage(0);
            }
            setShowList(targetList)
        }
    }, [relationList, page])

    return (
        <article className="game__area">
            <div className="block__list size__mb">
                {
                    (showList.length ?? 0) === 0 &&
                    <FlexFillCenter children={<NotFound message={'관련 영상이 없습니다.'}/>}/>
                }
                <ul>
                    {
                        showList?.map((it, index) =>
                            <li key={`relative_video_item_${index}`}>
                                <Link to={it.link}>
                                    <div className="video__card">
                                        <div className="video__prev">
                                            <div className="video_thum">
                                                <ThumbnailImage src={it.thumbnail}/>
                                            </div>
                                        </div>
                                        <div className="video__info">
                                            <div className="txt_bold txt_primary">{it.category}</div>
                                            <div className="txt_subject">{it.title}</div>
                                            <div className="txt_sub">{it.date}</div>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        )
                    }
                </ul>

            </div>
            <PageIndicator
                pageSize={pageSize}
                page={page ?? 0}
                setPage={setPage}
                itemCount={relationList?.data?.length ?? 0}
                showPageSize={3}
            />
        </article>
    )
}