const ACTIONS = {
    PUT_PIP: 'PUT_PIP',
    POP_PIP: 'POP_PIP',
    CLEAR_PIP: 'CLEAR_PIP',
}

Object.freeze(ACTIONS);

//외부에서 dispatch할 때 사용
/**
 *
 * @param {PipInfo} info
 * @returns {{type: string, info}}
 */
export const putPip = (info) => ({type: ACTIONS.PUT_PIP, info})
export const popPip = (time) => ({type: ACTIONS.POP_PIP, payload: time})
export const clearPip = () => ({type: ACTIONS.CLEAR_PIP, })

const initialState = {
    pipVideoInfo: null,
    oldPipInfo: null,
};


export class PipInfo {
    /**
     *
     * @param {PlayerOption} playerOption
     * @param {string} backUrl
     * @param {number} time
     */
    constructor({
                    playerOption,
                    backUrl,
                    time,
                } = {}) {
        this.playerOption = playerOption;
        this.backUrl = backUrl ?? '/';
        this.time = time;
    }
}
/**
 * @param state
 * @param action
 * @returns {*|{videoFile: {data: null, loading: boolean, error: null}}}
 */
export default function pipReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.PUT_PIP:
            return {
                ...state,
                pipVideoInfo: action.info,
                oldPipInfo: null,
            }

        case ACTIONS.POP_PIP:
            const oldOne = state.pipVideoInfo ?? state.oldPipInfo;
            const time = action.payload ?? oldOne?.time;
            return {
                ...state,
                oldPipInfo: {
                    ...oldOne,
                    time: time,
                },
                pipVideoInfo: null,
            }


        case ACTIONS.CLEAR_PIP:
            return {
                ...state,
                oldPipInfo: null,
                pipVideoInfo: null,
            }

        default:
            return state;
    }
}