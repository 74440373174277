import {useEffect, useMemo, useState} from "react";
import * as imageApi from "../../../apis/api/image";
import {ImageSearchParam} from "../../../apis/dto/SearchParam";
import {useSelector} from "react-redux";
import ImageContentItem from "../../../apis/dto/ImageContentItem";

export default function useGalleryMainGrid({menuId, count}) {
    const loginData = useSelector(state => state.userReducer.loginData);
    const menus = useSelector(state => state.menuReducer.menus);
    const copyrightAc = useMemo(() => {
        return loginData?.copyright_list_ac === 1 ? null : 'Y';
    }, [loginData])


    const [state, setState] = useState({
        isLoading: false,
        list: [],
        hasError: false,
    });

    const fetch = async () => {
        if(state.isLoading){
            return;
        }
        setState(p => ({
            ...p,
            isLoading: true,
            hasError: false,
        }));
        try{

            let param = new ImageSearchParam({
                size: count,
                page: 0,
                keyword: '',
                menu: [menuId],
                menus: menus.data,
                copyright: copyrightAc,
            });
            let res = await imageApi.searchImage(param);

            setState(p => ({
                ...p,
                list: res.data.hits?.hits?.map(it => new ImageContentItem(it._source)) ?? [],
                isLoading: false,
                hasError: false,
            }));
        }
        catch (e) {
            setState(p => ({
                ...p,
                isLoading: false,
                hasError: true,
            }));
            console.error(e);
        }
    }

    useEffect(() => {
        fetch();
    }, [menuId]);

    return {
        isLoading: state.isLoading,
        list: state.list,
        hasError: state.hasError,
    }
}