import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLoadingModal} from "../../common/component/LoadingState";
import {clearLogin, login} from "../../modules/userReducer";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ShowAlertModal} from "../../common/component/modal/AlertModal";
import useSSO from "../../lib/useSSO";
import {ShowConfirmModal} from "../../common/component/modal/ConfirmModal";
import PasswordInput from "../../common/component/PasswordInput";



export default function LoginPage() {
    const location = useLocation();
    const redirectUrl = location?.state?.redirectUrl ?? '/';
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [uid, setUid] = useState('');
    const [pw, setPw] = useState('');
    const [rememberId, setRememberId] = useState();
    const [canLogin, setCanLogin] = useState(false);
    const loginResult = useSelector(state => state.userReducer.login);
    const loginData = useSelector(state => state.userReducer.loginData);
    const isSSOLogin = useSelector(state => state.userReducer.isSSOLogin);
    const {ssoLoginRequest, goSsoSignUp} = useSSO();

    useEffect(() => {
        setCanLogin(uid?.length > 0 && pw?.length > 0)
    }, [uid, pw]);


    useEffect(() => {
        if (loginResult.error || loginData == null) {
            dispatch(clearLogin());
        }
    }, []);

    useEffect(() => {
        if (rememberId == null) {
            setRememberId(localStorage.getItem("rememberId") == "true");
            setUid(localStorage.getItem("uid"));
        } else {
            localStorage.setItem("rememberId", rememberId);
        }
    }, [rememberId])

    const handleLogin = () => {
        if (rememberId) {
            localStorage.setItem("uid", uid);
        } else {
            localStorage.removeItem("uid");
        }

        dispatch(login({
            id: uid,
            pw: pw,
        }));
    }

    useEffect(() => {
        if (loginResult.error) {
            // navigate('/')
            ShowAlertModal({
                message: '오류가 발생하였습니다.',
                onConfirm: (ref) => {
                    dispatch(clearLogin());
                    ref.current.hideModal();
                }
            })
        }
        if (loginResult.data) {
            if(loginResult.data?.data?.data?.length == 0){
                ShowAlertModal({
                    message: '아이디 또는 비밀번호가 일치하지 않습니다.',
                    onConfirm: (ref) => {
                        dispatch(clearLogin());
                        ref.current.hideModal();
                    }
                })
            }

            // if (loginData.permitYN === 'Y') {
                // 로그인 성공, 승인 완료
                // SSO 연동 사용자 확인 확인
                if (loginData?.ssoYn?.toLowerCase() === 'y') {
                    if (!isSSOLogin) {
                        ShowAlertModal({
                            message: '회원님은 통합회원이므로 KFA 통합회원 로그인 페이지를 이용해 주시기 바랍니다.',
                            onConfirm: (ref) => {
                                ref.current.hideModal();
                                dispatch(clearLogin());
                                ssoLoginRequest();
                            }
                        })
                    }
                } else {
                    navigate('/sso/kfa/integrate')
                    return;
                }

                navigate(redirectUrl, {replace: true})
            // }
        }
    }, [loginResult, loginData]);

    useLoadingModal([loginResult]);

    return (
        <div className="inner">
            <div className="sign-form-area">
                <div className="txt_head2 mb_48">로그인</div>
                <div className="field__inp mb_12">
                    <input
                        type="text"
                        className="inp_id inp-valid"
                        placeholder="아이디를 입력해주세요"
                        value={uid}
                        onChange={e => setUid(e.target.value)}
                    />
                    <div className="focus-item">
                        <div className="btn-form del-form">
                            <i className="icon gas-delete-form"></i>
                        </div>
                    </div>
                </div>
                <div className="field__inp">
                    <PasswordInput
                        type="password"
                        className="inp_pwd inp-valid"
                        placeholder="비밀번호를 입력해주세요"
                        value={pw}
                        onChange={e => setPw(e.target.value)}
                        onKeyUp={(event) => {
                            if (event.key === 'Enter') {
                                handleLogin()
                            }
                        }}
                    />
                    <div className="focus-item">
                        <div className="btn-form trans-form">
                            <i className="icon gas-trans-form"></i>
                        </div>
                    </div>
                </div>
                <div className="field__inp auto-sign">
                    <div className="checkbox box__type01">
                        <input
                            type="checkbox"
                            checked={rememberId}
                            onChange={e => setRememberId(e.target.checked)}
                        />
                        <label>아이디 저장</label>
                    </div>
                </div>
                <div className="field__btn">
                    <button className="btn sibmit" id="submitBtn" disabled={!canLogin} onClick={handleLogin}>로그인
                    </button>
                </div>

                <div className="field__btn mt-4">
                    <button
                        className="btn submit"
                        onClick={e => ssoLoginRequest(redirectUrl)}>
                        KFA 통합회원 로그인
                    </button>
                </div>
                <div className="find__accound">
                    <a onClick={() => goSsoSignUp()} className="cursor-pointer">회원가입</a>
                    <Link to="/user/find/id">아이디 찾기</Link>
                    <Link to="/user/find/password">비밀번호 찾기</Link>
                </div>
            </div>
        </div>
    )
}