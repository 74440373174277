import {useEffect, useState} from "react";
import {createPortal} from "react-dom";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import {validatePassword} from "../../../util";
import PasswordInput from "../PasswordInput";

export default function PasswordChangeModal({uid, onSubmit, onClose}) {
    const container = document.querySelector('.password-change-modal-portal');
    return createPortal(<PasswordChangeModalContent onSubmit={onSubmit} onClose={onClose}/>, container)
}

export function PasswordChangeModalContent({onSubmit, onClose}) {
    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword1, setNewPassword1] = useState("")
    const [newPassword2, setNewPassword2] = useState("")
    const [newPassword1Ok, setNewPassword1Ok] = useState(false);
    const [newPassword2Ok, setNewPassword2Ok] = useState(false);

    const [canChange, setCanChange] = useState(false);

    useEffect(() => {
        setCanChange(
            currentPassword.length >= 6
            && validatePassword(newPassword1)
            && newPassword2 === newPassword1
        )

        setNewPassword1Ok(newPassword1.length === 0 || validatePassword(newPassword1))
        setNewPassword2Ok(newPassword2.length === 0 || (validatePassword(newPassword1) && newPassword1 === newPassword2))
    }, [currentPassword, newPassword1, newPassword2]);

    return <div className="modal-alert-wrap">
        <div
            className={classNames({
                'modal-dialog': true,
                'overflow-y-auto': false,
            })}
        >

            <div className="dim"></div>
            <div className="modal-resign">
                <div className="sign-form-area">
                    <div className="txt_head2 mb_48">비밀번호 변경하기</div>
                    <div className="mb_12">현재 비밀번호</div>
                    <div className="field__inp mb_24">
                        <PasswordInput
                            placeholder="비밀번호를 입력하세요."
                            value={currentPassword}
                            onChange={e => setCurrentPassword(e.target.value.trim())}
                        />
                    </div>
                    <div className="mb_12">새로운 비밀번호</div>
                    <div className={classNames({
                        'field__inp mb_24 vaild__inp ': true,
                        'verify ': !newPassword1Ok,
                    })}>
                        <PasswordInput
                            className="inp-valid"
                            placeholder="변경할 비밀번호를 입력하세요."
                            value={newPassword1}
                            onChange={e => setNewPassword1(e.target.value.trim())}
                        />
                    </div>
                    <div className="mb_12">새로운 비밀번호 확인</div>
                    <div className={classNames({
                        'field__inp mb_24 vaild__inp ': true,
                        'verify ': !newPassword2Ok,
                    })}>
                        <PasswordInput
                            className="inp-valid"
                            placeholder="비밀번호를 다시 한번 입력하세요."
                            value={newPassword2}
                            onChange={e => setNewPassword2(e.target.value.trim())}
                        />
                    </div>
                    <div className="txt_alert txt_sub">8자리 이상, 영문, 숫자, 특수문자을(를) 사용하세요.</div>
                    <div className="field__btn mt_24">
                        <div className="btn btn-cancel" onClick={onClose}>취소</div>
                        <button className="btn submit" disabled={!canChange} onClick={() => {
                            onClose()
                            onSubmit(currentPassword, newPassword1)
                        }
                        }>변경하기
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

}