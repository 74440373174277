import {searchEngineApiInstance} from '../utils/index';

export const getMenus = ()=>{
    return searchEngineApiInstance.post(
        ``,
        {
            "from": 0,
            "size": 9999,
            "sort": [
                {
                    "parentMenuId": {
                        "order": "asc",
                        "missing": "_first"
                    },
                    "menuOrder": "asc",
                },
            ],
        },
        {
            headers: {
                'subUrl': `/menu/_search`,
                "Content-Type": "application/json",
            },
        }
    );
}