import {createPromiseThunk, handleAsyncActions, reducerUtils,} from '../lib/asyncUtils';
import * as videoApi from "../apis/api/video";
import SearchParam, {VideoSearchParam} from "../apis/dto/SearchParam";

const ACTIONS = {
    SET_SEARCH_PARAM: "SET_SEARCH_PARAM"
}

Object.freeze(ACTIONS);

export const setSearchParam = (searchParam) =>  ({type:ACTIONS.SET_SEARCH_PARAM, payload: searchParam})

const initialState = {
    searchParam: new VideoSearchParam()
};

export default function searchReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.SET_SEARCH_PARAM:
            return handleAsyncActions(ACTIONS.SET_SEARCH_PARAM, 'searchParam')(state, action);

        default:
            return state;
    }
}