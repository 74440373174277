import {useEffect} from "react";
import {ShowAlertModal} from "../../../../common/component/modal/AlertModal";
import {clearRequestVODDownload} from "../../../../modules/userReducer";
import {useDispatch, useSelector} from "react-redux";


export default function useDownloadRequestModal() {
    const request = useSelector(state => state.userReducer.requestDownload);
    const dispatch = useDispatch();
    useEffect(() => {
        // if(requestVODDownloadResult.loading){
        //     ShowAlertModal({
        //         message: '다운로드 요청중입니다. 잠시 기다려 주세요',
        //     })
        // }
        // else
        if(request?.error != null){
            ShowAlertModal({
                message: '구간 다운로드 요청중 오류가 발생했습니다',
            })
            dispatch(clearRequestVODDownload())
        }
        else if(request?.data != null){
            if(request.data?.data?.code != "00"){
                ShowAlertModal({
                    message: `오류가 발생했습니다 - ${request.data?.data?.message ?? ''}`,
                })
            }
            else {
                ShowAlertModal({
                    message: '다운로드 요청 성공',
                    onConfirm: () => {

                    }
                })
            }
            dispatch(clearRequestVODDownload())
        }
    }, [request])

}