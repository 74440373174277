import {createRef, forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {createRoot} from "react-dom/client";
import classNames from "classnames";
import React from 'react';
import SearchFilter from "./SearchFilter";
import SearchFilterHeader from "./SearchFilter/Header";
import SearchFilterBody from "./SearchFilter/Body";
import {UserType} from "../../../../resource/menus/mypage";


const yearListGenerator = function* () {
    const currentYear = new Date().getFullYear()
    for (let i = 1986; i <= currentYear; i++) {
        yield {name: i.toString(), value: i};
    }
}

const SearchModal = forwardRef(({initialSearchParam, userType, isEvent, isLeague, isImage, id, menuSelects, onConfirm, onCancel, show, league2Menus,}, ref) => {

    const getIsUsingSavedParam = () => {
        return localStorage.getItem(`search_${id}_param_save`) === "true";
    }

    const getSavedParam = () => {
        const paramString = localStorage.getItem(`search_${id}_param`);
        let param = JSON.parse(paramString);
        return {
            keyword: new Set(param?.keyword),
            year: new Set(param?.year),
            league: new Set(param?.league),
            league2: new Set(param?.league2),
            startDate: param?.startDate,
            endDate: param?.endDate,
        }
    }

    const saveParam = (param) => {
        const p = {
            keyword: [...param.keyword],
            year: [...param.year],
            league: [...param.league],
            league2: [...param.league2],
            startDate: param?.startDate,
            endDate: param?.endDate,
        }
        localStorage.setItem(`search_${id}_param`, JSON.stringify(p));
    }

    const [isShow, setIsShow] = useState(show ?? false);
    const [firstShow, setFirstShow] = useState(true);
    const [localSearchParam, setLocalSearchParam] = useState(initialSearchParam);
    const [visibleFilters, setVisibleFilters] = useState(new Set(['keyword', 'year', 'league', 'league2', 'date']));
    const [isUsingSavedParam, setIsUsingSavedParam] = useState(()=> getIsUsingSavedParam());

    useEffect(() => {
        if(isUsingSavedParam != null){
            localStorage.setItem(`search_${id}_param_save`, isUsingSavedParam);
            if(isUsingSavedParam){
                const param = getSavedParam();
                setLocalSearchParam(param);
            }
            else {
                localStorage.removeItem(`search_${id}_param`);
            }
        }
    }, [isUsingSavedParam]);

    useImperativeHandle(ref, () => ({
        showModal() {
            setIsShow(true)
        },
        hideModal() {
            setIsShow(false)
        },
    }));

    useEffect(() => {
        if (isShow) {
            setFirstShow(false);
        }
    }, [isShow])

    const makeYearList = () => {
        const list = [];
        for (const year of yearListGenerator()) {
            list.push(year);
        }
        return [{itemList: list}];
    }

    const handleConfirm = () => {
        const param = validatePeriodParam(localSearchParam);

        setIsShow(false);
        if(isUsingSavedParam){
            saveParam(param);
        }
        if (onConfirm) {
            onConfirm(ref, param);
        }
    }

    const validatePeriodParam = (param) => {

        const startDate = param?.date?.startDate;
        const endDate = param?.date?.endDate;

        if(!(startDate && endDate)) return param;
        if(dateStringToNumber(startDate) < dateStringToNumber(endDate)) return param;

        const validatedParam = {
            ...param,
            date: {
                startDate: endDate,
                endDate: startDate
            }
        }

        setLocalSearchParam(validatedParam);
        return validatedParam;
    }

    const dateStringToNumber = (dateString) => {
        return Number(dateString.split('-').join(''));
    }

    const handleCancel = () => {
        setIsShow(false)
        if (onCancel) {
            onCancel(ref);
        }
    }

    useEffect(() => {
        console.log("searchParam in Modal Changed! : ", localSearchParam)
    }, [localSearchParam])

    const onVisibilityToggle = (id) => {
        console.log(id, "visibility toggled");
        setVisibleFilters(prevState => {
            if (prevState.has(id)) {
                prevState.delete(id)
            } else {
                prevState.add(id)
            }
            console.log(prevState)
            return new Set([...prevState]);
        })
    }

    const handleFilterChange = (id, filters) => {
        setLocalSearchParam(param => ({
            ...param,
            [id]: filters
        }));
    }

    const reset = () => {
        setLocalSearchParam({
            keyword: new Set(),
            year: new Set(),
            date: {},
            league: new Set(),
            league2: new Set(),
            shareYn: new Set(),
        })
    }

    console.log('userType', userType)

    return <div className={'modal-search-wrap'}>
        <div className={classNames(
            'filter-md-wrap',
            {
                'fade': true,
                'hide': !isShow,
            })}
             style={{
                 display: firstShow ? 'none' : 'unset',
             }}>
            <div className="dim"></div>
            <div className="content-area">
                <div className="close" onClick={e => setIsShow(false)}><i className="icon gas-close-big"></i></div>
                <div className="scroll__wrap">
                    <div className="scroll__inner">
                        {/*{*/}
                        {/*    userType === 'M' && !isEvent &&*/}
                        {/*    <SearchFilter*/}
                        {/*        id="shareYn"*/}
                        {/*        filters={localSearchParam?.shareYn}*/}
                        {/*        onFiltersChanged={handleFilterChange}*/}
                        {/*        visible={true}*/}
                        {/*        text={'미디어 공유 목록 필터'}*/}
                        {/*        onVisibilityToggle={onVisibilityToggle}>*/}
                        {/*        <SearchFilterHeader.Text*/}
                        {/*            title="미디어 공유"*/}
                        {/*        />*/}
                        {/*        <SearchFilterBody.CheckBoxYN/>*/}
                        {/*    </SearchFilter>*/}
                        {/*}*/}
                        <SearchFilter
                            id="keyword"
                            filters={localSearchParam?.keyword ?? new Set()}
                            onFiltersChanged={handleFilterChange}
                            visible={true}
                            // visible={visibleFilters.has('keyword')}
                            onVisibilityToggle={onVisibilityToggle}>
                            <SearchFilterHeader.Text
                                title="검색어"
                            />
                            <SearchFilterBody.Keyword/>
                        </SearchFilter>
                        {
                            !isEvent && !isImage &&
                            <SearchFilter
                                id="year"
                                filters={localSearchParam?.year ?? new Set()}
                                onFiltersChanged={handleFilterChange}
                                visible={visibleFilters.has('year')}
                                onVisibilityToggle={onVisibilityToggle}>
                                <SearchFilterHeader.ItemList
                                    title="경기년도"
                                />
                                <SearchFilterBody.ItemList items={makeYearList()}/>
                            </SearchFilter>
                        }
                        {
                            isImage &&
                            <>
                                <SearchFilter
                                    id="league2"
                                    filters={[]}
                                    onFiltersChanged={handleFilterChange}
                                    visible={visibleFilters.has('date')}
                                    date={localSearchParam.date}
                                >
                                    <SearchFilterHeader.Text title="촬영일"/>
                                    <SearchFilterBody.Date/>
                                </SearchFilter>
                            </>
                        }
                        {
                            isEvent &&
                            <SearchFilter
                                id="league2"
                                filters={localSearchParam?.league2 ?? new Set()}
                                onFiltersChanged={handleFilterChange}
                                visible={visibleFilters.has('league2')}
                                onVisibilityToggle={onVisibilityToggle}
                                nameText="menuNm"
                                valueText="menuId"
                                items={league2Menus}
                            >
                                <SearchFilterHeader.ItemList title="리그명"/>
                                <SearchFilterBody.ItemList/>
                            </SearchFilter>
                        }
                        <SearchFilter
                            id="league"
                            filters={localSearchParam?.league ?? new Set()}
                            onFiltersChanged={handleFilterChange}
                            visible={visibleFilters.has('league')}
                            onVisibilityToggle={onVisibilityToggle}
                            nameText="menuNm"
                            valueText="menuId"
                            items={menuSelects}
                        >
                            {
                                isEvent &&
                                <SearchFilterHeader.ItemList title="경기기록"/>
                            }
                            {
                                !isEvent && isLeague &&
                                <SearchFilterHeader.ItemList title="리그명"/>
                            }
                            {
                                !isEvent && !isLeague &&
                                <SearchFilterHeader.ItemList title="카테고리"/>
                            }
                            <SearchFilterBody.ItemList/>
                        </SearchFilter>
                    </div>
                </div>
                <div className="filter_controll">
                    <div className="checkbox box__type01">
                        <input type="checkbox" name="" id="filterSave" checked={isUsingSavedParam} onChange={e => setIsUsingSavedParam(e.target.checked)}/>
                        <label>선택한 필터 저장</label>
                    </div>
                    <div className="btn btn-default" id="resetBtn" onClick={reset}><i className="icon gas-reset ml_4"></i>초기화</div>
                    <div className="btn" id="filterSet" onClick={handleConfirm}>검색 필터
                        적용
                    </div>
                </div>
            </div>
        </div>
    </div>
})


/**
 * DOM에
 <SearchModal/>
 선언 이후 사용 가능
 * @param initialSearchParam 초기 SearchParam.
 * @param onConfirm
 * @param onCancel
 * @param userType
 * @param id
 * @param menuSelects
 * @param isEvent
 * @param isLeague
 * @param league2Menus
 * @returns
    {
        JSX.Element
    }
 * @constructor
 */
export const ShowSearchModal = (
    initialSearchParam,
    onConfirm,
    onCancel,
    userType,
    id,
    menuSelects,
    isEvent,
    isLeague = true,
    league2Menus,
) => {
    const ref = createRef();
    const newModal = <SearchModal
        ref={ref}
        userType={userType}
        isEvent={isEvent}
        isLeague={isLeague}
        show={true}
        id={id}
        initialSearchParam={initialSearchParam}
        onConfirm={onConfirm}
        onCancel={onCancel}
        menuSelects={menuSelects}
        league2Menus={league2Menus}
    />

    console.log("Creating modal with: ", initialSearchParam)

    const container = document.querySelectorAll('.modal-search-wrap')[0]
    console.log(container, "created")
    const modalRoot = createRoot(container);
    modalRoot.render(newModal)
    return ref;
}

export const ShowSearchModal2 = ({
                                     initialSearchParam,
                                     onConfirm,
                                     onCancel,
                                     userType,
                                     id,
                                     menuSelects,
                                     isEvent,
                                     isLeague = true,
                                     isImage = false,
                                     league2Menus,
                                 }) => {
    const ref = createRef();
    const newModal = <SearchModal
        ref={ref}
        isEvent={isEvent}
        userType={userType}
        isLeague={isLeague}
        isImage={isImage}
        show={true}
        id={id}
        initialSearchParam={initialSearchParam}
        onConfirm={onConfirm}
        onCancel={onCancel}
        menuSelects={menuSelects}
        league2Menus={league2Menus}
    />

    console.log("Creating modal with: ", initialSearchParam)

    const container = document.querySelectorAll('.modal-search-wrap')[0]
    console.log(container, "created")
    const modalRoot = createRoot(container);
    modalRoot.render(newModal)
    return ref;
}


export default SearchModal;