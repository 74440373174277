import GalleryMainGridCategoryItem from "./GalleryMainGridCategoryItem";
import {useSelector} from "react-redux";
import {useMemo} from "react";

export default function GalleryMain() {
    const menus = useSelector(state => state.menuReducer.menus)
    const galleryMenus = useMemo(() => {
        return menus?.data?.find(it => it.contentType === 'image')?.children ?? [];
    }, [menus])

    return <div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:gap-20 lg:p-20 p-10 gap-10">
            {
                galleryMenus.map(it => <GalleryMainGridCategoryItem menu={it} />)
            }
        </div>
    </div>
}
