

export const majorEventCodeList = [
    {
        "eventCd": "A31",
        "eventNm": "경고",
    },
    {
        "eventCd": "A41",
        "eventNm": "퇴장",
    },
    {
        "eventCd": "B13",
        "eventNm": "유효슈팅",
    },
    {
        "eventCd": "A11",
        "eventNm": "득점",
    },
    {
        "eventCd": "A21",
        "eventNm": "도움",
    },
    {
        "eventCd": "B12",
        "eventNm": "슈팅",
    }
];

export const excludeCodeList = [
    "B72"
]

export const eventCodeList =
    [
        {
            "eventCd": "C33",
            "eventNm": "GETPK",
            "sortSeq": 40,
            "useYn": "Y",
            "regId": "admin",
            "regDt": "2022-07-17T18:55:17+09:00",
            "modId": "admin",
            "modDt": "2022-09-08T03:31:45+09:00",
            "mappings": [
                {
                    "idx": 56,
                    "sysType": "vs",
                    "flag": 33,
                    "esyn": "",
                    "name": "GETPK",
                    "useYn": "Y",
                    "eventCd": "C33",
                    "iconType": null,
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-09-08T03:31:29+09:00",
                    "modId": "admin",
                    "modDt": "2022-09-08T03:31:29+09:00"
                }
            ]
        },
        {
            "eventCd": "C32",
            "eventNm": "PK",
            "sortSeq": 39,
            "useYn": "Y",
            "regId": "admin",
            "regDt": "2022-07-17T18:55:17+09:00",
            "modId": "admin",
            "modDt": "2022-07-17T18:55:17+09:00",
            "mappings": [
                {
                    "idx": 55,
                    "sysType": "vs",
                    "flag": 32,
                    "esyn": "",
                    "name": "PK",
                    "useYn": "Y",
                    "eventCd": "C32",
                    "iconType": "pk",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                }
            ]
        },
        {
            "eventCd": "B72",
            "eventNm": "PSO",
            "sortSeq": 12,
            "useYn": "Y",
            "regId": "admin",
            "regDt": "2022-07-17T18:55:17+09:00",
            "modId": "admin",
            "modDt": "2022-09-06T15:38:40+09:00",
            "mappings": [

            ]
        },
        {
            "eventCd": "A31",
            "eventNm": "경고",
            "sortSeq": 6,
            "useYn": "Y",
            "regId": "admin",
            "regDt": "2022-07-17T18:55:17+09:00",
            "modId": "admin",
            "modDt": "2022-07-17T18:55:17+09:00",
            "mappings": [
                {
                    "idx": 3,
                    "sysType": "ama",
                    "flag": 31,
                    "esyn": "",
                    "name": "경고",
                    "useYn": "Y",
                    "eventCd": "A31",
                    "iconType": "yellow",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-09-06T18:11:00+09:00",
                    "modId": "admin",
                    "modDt": "2022-09-06T18:11:00+09:00"
                },
                {
                    "idx": 15,
                    "sysType": "nat",
                    "flag": 31,
                    "esyn": "",
                    "name": "경고",
                    "useYn": "Y",
                    "eventCd": "A31",
                    "iconType": "yellow",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                },
                {
                    "idx": 26,
                    "sysType": "vs",
                    "flag": 3,
                    "esyn": "",
                    "name": "경고",
                    "useYn": "N",
                    "eventCd": "A31",
                    "iconType": "",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                }
            ]
        },
        {
            "eventCd": "A51",
            "eventNm": "교체in",
            "sortSeq": 8,
            "useYn": "Y",
            "regId": "admin",
            "regDt": "2022-07-17T18:55:17+09:00",
            "modId": "admin",
            "modDt": "2022-07-17T18:55:17+09:00",
            "mappings": [
                {
                    "idx": 5,
                    "sysType": "ama",
                    "flag": 51,
                    "esyn": "",
                    "name": "선수교체in",
                    "useYn": "Y",
                    "eventCd": "A51",
                    "iconType": "change",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                },
                {
                    "idx": 17,
                    "sysType": "nat",
                    "flag": 51,
                    "esyn": "",
                    "name": "교체in",
                    "useYn": "Y",
                    "eventCd": "A51",
                    "iconType": "change",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                },
                {
                    "idx": 28,
                    "sysType": "vs",
                    "flag": 30,
                    "esyn": "",
                    "name": "선수교체",
                    "useYn": "N",
                    "eventCd": "A51",
                    "iconType": "change",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                }
            ]
        },
        {
            "eventCd": "A52",
            "eventNm": "교체out",
            "sortSeq": 9,
            "useYn": "Y",
            "regId": "admin",
            "regDt": "2022-07-17T18:55:17+09:00",
            "modId": "admin",
            "modDt": "2022-07-17T18:55:17+09:00",
            "mappings": [
                {
                    "idx": 6,
                    "sysType": "ama",
                    "flag": 52,
                    "esyn": "",
                    "name": "선수교체out",
                    "useYn": "Y",
                    "eventCd": "A52",
                    "iconType": "change",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                },
                {
                    "idx": 18,
                    "sysType": "nat",
                    "flag": 52,
                    "esyn": "",
                    "name": "교체out",
                    "useYn": "Y",
                    "eventCd": "A52",
                    "iconType": "change",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                }
            ]
        },
        {
            "eventCd": "A21",
            "eventNm": "도움",
            "sortSeq": 5,
            "useYn": "Y",
            "regId": "admin",
            "regDt": "2022-07-17T18:55:17+09:00",
            "modId": "admin",
            "modDt": "2022-07-17T18:55:17+09:00",
            "mappings": [
                {
                    "idx": 2,
                    "sysType": "ama",
                    "flag": 21,
                    "esyn": "",
                    "name": "도움",
                    "useYn": "Y",
                    "eventCd": "A21",
                    "iconType": "",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                },
                {
                    "idx": 14,
                    "sysType": "nat",
                    "flag": 21,
                    "esyn": "",
                    "name": "도움",
                    "useYn": "Y",
                    "eventCd": "A21",
                    "iconType": "goal",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                },
                {
                    "idx": 25,
                    "sysType": "vs",
                    "flag": 47,
                    "esyn": "",
                    "name": "어시스트",
                    "useYn": "N",
                    "eventCd": "A21",
                    "iconType": null,
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                }
            ]
        },
        {
            "eventCd": "A11",
            "eventNm": "득점",
            "sortSeq": 1,
            "useYn": "Y",
            "regId": "admin",
            "regDt": "2022-07-17T18:55:17+09:00",
            "modId": "admin",
            "modDt": "2022-07-17T18:55:17+09:00",
            "mappings": [
                {
                    "idx": 1,
                    "sysType": "ama",
                    "flag": 11,
                    "esyn": "",
                    "name": "득점",
                    "useYn": "Y",
                    "eventCd": "A11",
                    "iconType": "goal",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                },
                {
                    "idx": 11,
                    "sysType": "nat",
                    "flag": 11,
                    "esyn": "",
                    "name": "득점",
                    "useYn": "Y",
                    "eventCd": "A11",
                    "iconType": "goal",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                },
                {
                    "idx": 22,
                    "sysType": "vs",
                    "flag": 31,
                    "esyn": "",
                    "name": "골인",
                    "useYn": "N",
                    "eventCd": "A11",
                    "iconType": "",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                }
            ]
        },
        {
            "eventCd": "C42",
            "eventNm": "선방",
            "sortSeq": 48,
            "useYn": "Y",
            "regId": "admin",
            "regDt": "2022-07-17T18:55:17+09:00",
            "modId": "admin",
            "modDt": "2022-07-17T18:55:17+09:00",
            "mappings": [
                {
                    "idx": 64,
                    "sysType": "vs",
                    "flag": 42,
                    "esyn": "",
                    "name": "선방",
                    "useYn": "Y",
                    "eventCd": "C42",
                    "iconType": "",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                }
            ]
        },
        {
            "eventCd": "B12",
            "eventNm": "슈팅",
            "sortSeq": 2,
            "useYn": "Y",
            "regId": "admin",
            "regDt": "2022-07-17T18:55:17+09:00",
            "modId": "admin",
            "modDt": "2022-07-17T18:55:17+09:00",
            "mappings": [
                {
                    "idx": 12,
                    "sysType": "nat",
                    "flag": 12,
                    "esyn": "N",
                    "name": "슈팅",
                    "useYn": "Y",
                    "eventCd": "B12",
                    "iconType": "",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                },
                {
                    "idx": 23,
                    "sysType": "vs",
                    "flag": 14,
                    "esyn": "",
                    "name": "슈팅",
                    "useYn": "N",
                    "eventCd": "B12",
                    "iconType": "",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                }
            ]
        },
        {
            "eventCd": "A81",
            "eventNm": "승부차기",
            "sortSeq": 14,
            "useYn": "Y",
            "regId": "admin",
            "regDt": "2022-07-17T18:55:17+09:00",
            "modId": "admin",
            "modDt": "2022-09-06T15:38:28+09:00",
            "mappings": [
                {
                    "idx": 10,
                    "sysType": "ama",
                    "flag": 81,
                    "esyn": "",
                    "name": "승부차기",
                    "useYn": "Y",
                    "eventCd": "A81",
                    "iconType": "",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-09-06T15:14:29+09:00",
                    "modId": "admin",
                    "modDt": "2022-09-06T15:14:29+09:00"
                },
                {
                    "idx": 21,
                    "sysType": "nat",
                    "flag": 71,
                    "esyn": "",
                    "name": "승부차기",
                    "useYn": "Y",
                    "eventCd": "A81",
                    "iconType": "",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                }
            ]
        },
        {
            "eventCd": "C23",
            "eventNm": "실책",
            "sortSeq": 32,
            "useYn": "Y",
            "regId": "admin",
            "regDt": "2022-07-17T18:55:17+09:00",
            "modId": "admin",
            "modDt": "2022-09-14T16:38:39+09:00",
            "mappings": [
                {
                    "idx": 48,
                    "sysType": "vs",
                    "flag": 23,
                    "esyn": "",
                    "name": "실책",
                    "useYn": "Y",
                    "eventCd": "C23",
                    "iconType": null,
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-09-06T15:24:25+09:00",
                    "modId": "admin",
                    "modDt": "2022-09-06T15:24:25+09:00"
                }
            ]
        },
        {
            "eventCd": "C02",
            "eventNm": "오프사이드",
            "sortSeq": 16,
            "useYn": "Y",
            "regId": "admin",
            "regDt": "2022-07-17T18:55:17+09:00",
            "modId": "admin",
            "modDt": "2022-07-17T18:55:17+09:00",
            "mappings": [
                {
                    "idx": 31,
                    "sysType": "vs",
                    "flag": 2,
                    "esyn": "",
                    "name": "오프사이드",
                    "useYn": "Y",
                    "eventCd": "C02",
                    "iconType": "",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                }
            ]
        },
        {
            "eventCd": "B13",
            "eventNm": "유효슈팅",
            "sortSeq": 3,
            "useYn": "Y",
            "regId": "admin",
            "regDt": "2022-07-17T18:55:17+09:00",
            "modId": "admin",
            "modDt": "2022-07-17T18:55:17+09:00",
            "mappings": [
                {
                    "idx": 13,
                    "sysType": "nat",
                    "flag": 12,
                    "esyn": "Y",
                    "name": "유효슈팅",
                    "useYn": "Y",
                    "eventCd": "B13",
                    "iconType": "shoot",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                },
                {
                    "idx": 24,
                    "sysType": "vs",
                    "flag": 15,
                    "esyn": "",
                    "name": "유효슈팅",
                    "useYn": "N",
                    "eventCd": "B13",
                    "iconType": "shoot",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                }
            ]
        },
        {
            "eventCd": "A61",
            "eventNm": "자책골",
            "sortSeq": 10,
            "useYn": "Y",
            "regId": "admin",
            "regDt": "2022-07-17T18:55:17+09:00",
            "modId": "admin",
            "modDt": "2022-07-17T18:55:17+09:00",
            "mappings": [
                {
                    "idx": 7,
                    "sysType": "ama",
                    "flag": 61,
                    "esyn": "",
                    "name": "자책골",
                    "useYn": "Y",
                    "eventCd": "A61",
                    "iconType": "goal",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                },
                {
                    "idx": 19,
                    "sysType": "nat",
                    "flag": 61,
                    "esyn": "",
                    "name": "자책골",
                    "useYn": "Y",
                    "eventCd": "A61",
                    "iconType": "goal",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-09-06T15:25:35+09:00",
                    "modId": "admin",
                    "modDt": "2022-09-06T15:25:35+09:00"
                },
                {
                    "idx": 29,
                    "sysType": "vs",
                    "flag": 36,
                    "esyn": "",
                    "name": "자책골",
                    "useYn": "N",
                    "eventCd": "A61",
                    "iconType": "goal",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-09-06T15:25:59+09:00",
                    "modId": "admin",
                    "modDt": "2022-09-06T15:25:59+09:00"
                }
            ]
        },
        {
            "eventCd": "C26",
            "eventNm": "코너킥",
            "sortSeq": 35,
            "useYn": "Y",
            "regId": "admin",
            "regDt": "2022-07-17T18:55:17+09:00",
            "modId": "admin",
            "modDt": "2022-07-17T18:55:17+09:00",
            "mappings": [
                {
                    "idx": 51,
                    "sysType": "vs",
                    "flag": 26,
                    "esyn": "",
                    "name": "코너킥",
                    "useYn": "Y",
                    "eventCd": "C26",
                    "iconType": "",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                }
            ]
        },
        {
            "eventCd": "A41",
            "eventNm": "퇴장",
            "sortSeq": 7,
            "useYn": "Y",
            "regId": "admin",
            "regDt": "2022-07-17T18:55:17+09:00",
            "modId": "admin",
            "modDt": "2022-07-17T18:55:17+09:00",
            "mappings": [
                {
                    "idx": 4,
                    "sysType": "ama",
                    "flag": 41,
                    "esyn": "",
                    "name": "선수퇴장",
                    "useYn": "Y",
                    "eventCd": "A41",
                    "iconType": "red",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-09-06T18:11:06+09:00",
                    "modId": "admin",
                    "modDt": "2022-09-06T18:11:06+09:00"
                },
                {
                    "idx": 16,
                    "sysType": "nat",
                    "flag": 41,
                    "esyn": "",
                    "name": "퇴장",
                    "useYn": "Y",
                    "eventCd": "A41",
                    "iconType": "red",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                },
                {
                    "idx": 27,
                    "sysType": "vs",
                    "flag": 4,
                    "esyn": "",
                    "name": "퇴장",
                    "useYn": "N",
                    "eventCd": "A41",
                    "iconType": "",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-07-17T19:24:28+09:00",
                    "modId": "admin",
                    "modDt": "2022-07-17T19:24:28+09:00"
                }
            ]
        },
        {
            "eventCd": "C27",
            "eventNm": "프리킥",
            "sortSeq": 36,
            "useYn": "Y",
            "regId": "admin",
            "regDt": "2022-07-17T18:55:17+09:00",
            "modId": "admin",
            "modDt": "2022-09-06T15:37:00+09:00",
            "mappings": [
                {
                    "idx": 52,
                    "sysType": "vs",
                    "flag": 27,
                    "esyn": "",
                    "name": "프리킥",
                    "useYn": "Y",
                    "eventCd": "C27",
                    "iconType": "",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-09-06T15:24:31+09:00",
                    "modId": "admin",
                    "modDt": "2022-09-06T15:24:31+09:00"
                }
            ]
        },
        {
            "eventCd": "ZZZ",
            "eventNm": "CUSTOM",
            "sortSeq": 37,
            "useYn": "Y",
            "regId": "admin",
            "regDt": "2022-07-17T18:55:17+09:00",
            "modId": "admin",
            "modDt": "2022-09-06T15:37:00+09:00",
            "mappings": [
                {
                    "idx": 999,
                    "sysType": "vs",
                    "flag": -1,
                    "esyn": "",
                    "name": "CUSTOM",
                    "useYn": "Y",
                    "eventCd": "ZZZ",
                    "iconType": "",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-09-06T15:24:31+09:00",
                    "modId": "admin",
                    "modDt": "2022-09-06T15:24:31+09:00"
                },

                {
                    "idx": 999,
                    "sysType": "nat",
                    "flag": -1,
                    "esyn": "",
                    "name": "CUSTOM",
                    "useYn": "Y",
                    "eventCd": "ZZZ",
                    "iconType": "",
                    "remarks": "",
                    "regId": "admin",
                    "regDt": "2022-09-06T15:24:31+09:00",
                    "modId": "admin",
                    "modDt": "2022-09-06T15:24:31+09:00"
                }
            ]
        }
    ];