

// 유의어
const synonym = [
    ["득점", "골", "goal"],
    ["슈팅", "슛", "shooting", "shoot"],
    ["유효슈팅", "on target shot"],
    ["도움", "어시스트", "assist"],
    ["경고", "옐로우카드", "yellow card"],
    ["퇴장", "레드카드", "red card"],
    ["교체", "substitution "],
    ["자책골", "자살골", "own goal"],
    ["승부차기", "pso"],
    ["오프사이드", "offside", "옵사이드"],
    ["실책"],
    ["코너킥", "coner kick"],
    ["프리킥", "free kick"],
    ["pk", "페널티킥", "패널티킥", "penalty kick"],
    ["선방", "세이브", "save"],
    ["패스", "pass"],
    ["롱패스", "long pass"],
    ["태클", "tackle"],
    ["탈압박", "press resistance"],
    ["개인돌파", "breakthrough", "돌파"],
    ["수비", "defense", "인상적인수비"],
    ["드리블", "dribble"],
    ["fifa", "피파"],
    ["월드컵", "worldcup"],
    ["국가대표", "국대", "national team", "에이팀", "a team", "a 팀"]
]


const Config = {
    livePollingTime: 60 * 1000,
    videoSideContentPageSize: 5,
    synonym,
}

Object.freeze(Config)

export default Config;