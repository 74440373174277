import {useEffect, useMemo, useState} from "react";
import classNames from "classnames";
import useWindowDimensions from "../useWindowDimensions";

export default function PageIndicator({showPageSize, itemCount, pageSize, page, setPage, usePageInput = false}) {

    const {isMobile} = useWindowDimensions();
    const _showPageSize = useMemo(() => showPageSize ?? (isMobile ? 5 : 10), [showPageSize, isMobile]);

    const [maxPage, setMaxPage] = useState(Math.ceil(itemCount/pageSize));
    const [showingPageList, setShowingPageList] = useState([0,1,2]);

    const [pageInput, setPageInput] = useState(page+1);

    useEffect(() => {
        setPageInput(page+1)
    }, [page])

    useEffect(()=> {
        const maxPage = Math.ceil(itemCount/pageSize);
        setMaxPage(maxPage);

        let showingFirst = page - Math.floor(_showPageSize/2);
        if(showingFirst < 0){
            showingFirst = 0;
        }
        let showingLast = page + Math.ceil(_showPageSize/2);
        if(showingLast > maxPage){
            showingLast = maxPage;
        }
        if(maxPage >= _showPageSize){
            const showPageCount = showingLast - showingFirst;
            const lackPage = _showPageSize - showPageCount;
            if(lackPage > 0){
                if(showingFirst === 0){
                    showingLast += lackPage;
                }
                else {
                    showingFirst -= lackPage;
                }
            }
        }

        setShowingPageList(Array.from({length: showingLast - showingFirst}, (_, i) => i + showingFirst))

    }, [itemCount, pageSize, page, _showPageSize])

    const handleFirst = () => {
        setPage?.(0)
    }

    const handlePrev = () => {
        if(page > 0){
            setPage?.(page-1)
        }
    }

    const handleLast = () => {
        setPage?.(maxPage-1)
    }

    const handleNext = () => {
        if(page < maxPage-1){
            setPage?.(page+1)
        }
    }

    const handlePage = (page) => {
        setPage?.(page)
    }

    const onInputPageGo = () => {
        let num = Number(pageInput);
        if(isNaN(num)){
            return;
        }
        if(num < 1) {
            num = 1;
        }
        else if(num > maxPage) {
            num = maxPage;
        }
        setPageInput(num);
        handlePage(num-1);
    }


    return (
        <div className="pagenate__area mt_16">
            <ul>
                <li><a onClick={handleFirst}><i className="icon gas-first"></i></a></li>
                <li><a onClick={handlePrev}><i className="icon gas-prev"></i></a></li>
                {
                    showingPageList.map(it =>
                        <li className={classNames({
                            'active': it === page,
                        })}>
                            <a onClick={(e)=> handlePage(it)}>{it+1}</a></li>
                    )
                }
                <li><a onClick={handleNext}><i className="icon gas-next"></i></a></li>
                <li><a onClick={handleLast}><i className="icon gas-last"></i></a></li>
                {
                    !isMobile && usePageInput &&
                    <>
                        <li>
                            <input
                                className="w-[100px]"
                                type="number"
                                value={`${pageInput ?? 1}`}
                                min={1}
                                max={maxPage}
                                onChange={e => setPageInput(e.target.value)}
                                onKeyDown={({key, nativeEvent}) => {
                                    if (key === "Enter" && pageInput !== '') {
                                        onInputPageGo();
                                    }
                                }}
                            />
                        </li>
                        <li><a className="p-4 !w-[unset] !h-[unset]" onClick={onInputPageGo}>이동</a></li>
                    </>
                }
            </ul>
            {
                isMobile && usePageInput &&
                <ul className="mt-3">
                    <li>
                        <input
                            className="w-[100px]"
                            type="number"
                            value={`${pageInput ?? 1}`}
                            min={1}
                            max={maxPage}
                            onChange={e => setPageInput(e.target.value)}
                            onKeyDown={({key, nativeEvent}) => {
                                if (key === "Enter" && pageInput !== '') {
                                    onInputPageGo();
                                }
                            }}
                        />
                    </li>
                    <li><a className="p-4 !w-[unset] !h-[unset]" onClick={onInputPageGo}>이동</a></li>
                </ul>
            }
        </div>
    )
}