import {apiInstance, searchEngineApiInstance} from "../utils";
import {ChangeInfoPreset} from "../../config/email/ChangeInfoPreset";

export const login = ({
                          id,
                          pw
                      }) => {
    return apiInstance.post(
        `/api/cms/user/login`,
        {
            "uid": id,
            "userPwd": pw,
        },
    );
}

export const loginRefresh = (
    authKey,
) => {
    return apiInstance.post(
        `/api/cms/user/getUserInfobyAuthKey`,
        {},
    );
}


export const idDupCheck = ({uid}) => {
    return apiInstance.post(
        `/api/cms/user/userIdCheck`,
        {
            uid,
        },
    );
}

export const emailDupCheck = ({userEmail}) => {
    return apiInstance.post(
        `/api/cms/user/userEmailCheck`,
        {
            userEmail,
        },
    );
}

export const signup = (param) => {
    return apiInstance.post(
        `/api/cms/user/regist`,
        param,
    );
}

export const emailAuth = (uid, code) => {
    return apiInstance.post(
        `/api/cms/user/emailAuth`,
        {
            uid,
            authNo: code,
        },
    );
}

export const emailResend = ({uid, userEmail}) => {
    return apiInstance.post(
        `/api/cms/user/send_auth_mail`,
        {
            uid,
            userEmail,
        },
    );
}


export const changeEmail = ({uid, userEmail}) => {
    return apiInstance.post(
        `/api/cms/user/changeEmail`,
        {
            uid,
            _new_email: userEmail,
        },
    );
}

export const findId = (param) => {
    return apiInstance.post(
        `/api/cms/user/send_uid`,
        param,
    );
}

export const changePassword = (param) => {
    return apiInstance.post(
        `/api/cms/user/changePassword`,
        param,
    );
}

export const changeUserInfo = (param) => {
    return apiInstance.post(
        `/api/cms/user/changeInfo`,
        param,
    );
}

export const resetPassword = (param) => {
    return apiInstance.post(
        `/api/cms/user/send_temp_password`,
        param,
    );
}

export const userType = () => {
    return apiInstance.get(
        `/api/cms/user/userTypeList`,
    );
}

export const alertList = (params) => {
    return apiInstance.post(
        `/api/cms/user/alertList`, params,
    );
}

export const alertCount = (params) => {
    return searchEngineApiInstance.post(
        ``,
        {},
        {
            headers: {
                subUrl: '/alert/_search',
            }
        }
    );
}


export const deleteAlert = (params) => {
    return apiInstance.post(
        `/api/cms/user/alertDelete`, params,
    );
}


export const resignUser = ({uid, userPwd, resignRemarks}) => {
    return apiInstance.post(`/api/cms/user/resign`,
        {
            uid,
            userPwd,
            resignRemarks,
        },
    )
}

/**
 * @param {{userId:string, _pageNo:number, _pageSize: number, _fromDt: string, _toDt: string, _sortField: string, _sortDirection: string}} params
 * @return {Promise<AxiosResponse<any>>}
 */
export const getFavoriteContents = (params) => {
    return apiInstance.post(`/api/cms/user/faverList`, params,)
}

/**
 * @param {{userId:string, userLevelCd: number, cid: number, contentType: string}} params
 * @return {Promise<AxiosResponse<any>>}
 */
export const addFavoriteContent = (params) => {
    return apiInstance.post(`/api/cms/user/faverAdd`, params,)
}

/**
 * @param {{userId:string, contentType: string, cid: number}} params
 * @return {Promise<AxiosResponse<any>>}
 */
export const deleteFavoriteContent = (params) => {
    return apiInstance.post(`/api/cms/user/favorDelete`, params,)
}

export const deleteDownload = (params) => {
    return apiInstance.post(`/api/cms/user/downloadDelete`, params,)
}

/**
 * @param {DownloadLogSearchParam} params
 */
export const getDownloadLog = (params) => {
    return apiInstance.post(`/api/cms/user/downloadList`, params);
}

export const requestDownload = (params) => {
    return apiInstance.post('/api/cms/user/downloadAdd', params, );
}

export const downloadVOD = (params) => {
    return apiInstance.post('/api/cms/user/downloadCount', params, );
}