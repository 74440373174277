import './App.css';
import HomePage from "./page/home";
import {BrowserRouter, Navigate, Outlet, Route, Routes} from "react-router-dom";
import RootContainer from "./common/scaffold";
import {useDispatch, useSelector} from "react-redux";
import {useLoadingModal} from "./common/component/LoadingState";
import {getMenus} from "./modules/menuReducer";
import {useEffect, useState} from "react";
import ScrollToTop from "./common/component/ScrollToTop";
import MenuWrapper from "./common/scaffold/MenuWrapper";
import UserMenuWrapper from "./common/scaffold/UserMenuWrapper";
import TermsPage from "./page/term/TermsPage";

// Board
import VideoBoardPage from "./page/board/VideoBoard";
import EventBoardPage from "./page/board/EventBoard";
import ImageBoard from "./page/board/ImageBoard";
import FavoriteVideoBoard from "./page/user/mypage/favoriteMedia/FavoriteVideoBoard";
import FavoriteImageBoard from "./page/user/mypage/favoriteMedia/FavoriteImageBoard";

// User
import LoginPage from "./page/user/LoginPage";
import FindIdPage from "./page/user/FindIdPage";
import FindPassPage from "./page/user/FindPassPage";
import EmailAuth from "./page/user/EmailAuth";
import AlertListPage from "./page/user/mypage/AlertListPage";
import {loginRefresh} from "./modules/userReducer";
import ChangePassPage from "./page/user/mypage/ChangePassPage";
import MyPageMenuWrapper from "./page/user/mypage/MyPageMenuWrapper";
import UploadManagePage from "./page/user/mypage/UploadManagePage";
import LiveBoardPage from "./page/live/LiveBoardPage";
import MyInfoPage from "./page/user/mypage/MyInfoPage";
import DownloadLogPage from "./page/user/mypage/downloadLog/container";
import LiveDetailPage from "./page/live/LiveDetailPage";
import GAContainer from "./page/ga/GAContainer";
import GamePlayPageContainer from "./page/play/gamePlay/container";
import {getAuthKey} from "./util/storageUtil";
import EventPlayPageContainer from "./page/play/eventPlay/container";
import FavoriteMediaTabWrapper from "./page/user/mypage/favoriteMedia";
import LoadingModal from "./common/component/modal/LoadingModal";
import KFASSOCallbackPage from "./common/component/user/sso/KFASSOCallbackPage";
import {ssoSilentLogin} from "./lib/useSSO";
import queryString from "query-string";
import KFASSOIntegratePage from "./common/component/user/sso/KFASSOIntegratePage";
import KFASSOSilentLoginPage from "./common/component/user/sso/KFASSOSilentLoginPage";

function EmptyWrapper() {
    return <div className="container__">
        <Outlet/>
    </div>
}

function App() {

    const dispatch = useDispatch();
    const menus = useSelector(state => state.menuReducer.menus)
    const loginResult = useSelector(state => state.userReducer.login);
    const loginData = useSelector(state => state.userReducer.loginData);
    const {isLoading, hasError} = useLoadingModal([menus, loginResult]);
    const [loginTried, setLoginTried] = useState(false);

    useEffect(() => {
        dispatch(getMenus());
        const token = getAuthKey();
        if (token != null && token.length > 0) {
            dispatch(loginRefresh(token));
        } else {
            const {sso} = queryString.parse(window.location.search);
            if(
                window.location.pathname !== '/sso/kfa/callback'
                && sso !== 'fail'
            ) {
                // ssoSilentLogin();
            }
            setLoginTried(true);
        }
    }, []);

    useEffect(() => {
        if (loginResult.error || loginResult.data) {
            setLoginTried(true);
        }
    }, [loginResult])

    if (isLoading || !loginTried) {
        return <LoadingModal/>
    }

    const createBoardRoute = (menu1) => {
        const isVideo = menu1.contentType === 'video';
        const isEvent = menu1.contentType === 'event';
        const isLive = menu1.contentType === 'live';
        const isImage = menu1.contentType === 'image';
        if (isImage) {
            return (
                <Route path={menu1.menuUrl}>
                    <Route path="" element={<ImageBoard menu={menu1}/>}/>
                    {
                        menu1.children.length > 0 &&
                        menu1.children.map(menu2 =>
                            <>
                                <Route path=":category" element={<ImageBoard/>}/>
                            </>
                        )
                    }
                </Route>
            )
        } else if (isEvent) {
            return (
                <Route path={menu1.menuUrl}>
                    <Route path="" element={<EventBoardPage menu={menu1}/>}/>
                    {
                        menu1.children.length > 0 &&
                        menu1.children.map(menu2 =>
                            <>
                                <Route path=":category" element={<EventBoardPage/>}/>
                                <Route path=":category/:id" element={<GamePlayPageContainer/>}/>
                                <Route path="video/:id" element={<EventPlayPageContainer/>}/>
                            </>
                        )
                    }
                </Route>
            )
        } else if (isLive) {
            // console.log(menu1)
            return <Route path={menu1.menuUrl}>
                <Route path="" element={<LiveBoardPage menu={menu1}/>}/>
                <Route path=":id" element={<LiveDetailPage/>}/>
            </Route>
        }
        // isVideo
        return (
            <Route path={menu1.menuUrl}>
                <Route path="" element={<VideoBoardPage menu={menu1}/>}/>
                {
                    menu1.children.length > 0 &&
                    menu1.children.map(menu2 =>
                        <>
                            <Route path=":category" element={<VideoBoardPage/>}/>
                            <Route path=":category/:id" element={<GamePlayPageContainer/>}/>
                        </>
                    )
                }
            </Route>
        )
    }

    return (
        <>
            <div className="App">
                <BrowserRouter>
                    <ScrollToTop/>
                    <Routes>
                        <Route path={""} element={<GAContainer/>}>
                            <Route path="/" element={<RootContainer/>}>
                                <Route path='' element={<MenuWrapper/>}>
                                    {/*<Route path='/photos' element={<HomePage/>}/>*/}
                                    <Route path='' element={<HomePage/>}/>
                                    <Route path='terms' element={<TermsPage/>}/>
                                    <Route path='terms/:tab' element={<TermsPage/>}/>

                                    <Route path="video/:id" element={<GamePlayPageContainer/>}/>
                                    <Route path='/videos' element={<HomePage/>}/>
                                    {
                                        menus.data?.map(menu1 => createBoardRoute(menu1))
                                    }
                                    <Route path='mypage' element={<MyPageMenuWrapper/>}>
                                        <Route path='info' element={<MyInfoPage/>}/>
                                        <Route path='notification' element={<AlertListPage/>}/>
                                        <Route path='upload' element={<UploadManagePage/>}/>
                                        <Route path='download' element={<DownloadLogPage/>}/>
                                        <Route path='favorite' element={<FavoriteMediaTabWrapper/>}>
                                            <Route path='video' element={<FavoriteVideoBoard/>}/>
                                            <Route path='image' element={<FavoriteImageBoard/>}/>
                                        </Route>
                                    </Route>
                                </Route>

                                <Route path='mypage' element={<EmptyWrapper/>}>
                                    <Route path='changepass' element={<ChangePassPage/>}/>
                                </Route>

                                <Route path='user' element={<UserMenuWrapper/>}>
                                    <Route path='login' element={<LoginPage/>}/>
                                    <Route path='find/password' element={<FindPassPage/>}/>
                                    <Route path='find/id' element={<FindIdPage/>}/>
                                    <Route path='auth/email' element={<EmailAuth/>}/>
                                </Route>


                                <Route path='sso' element={<UserMenuWrapper/>}>
                                    <Route path='kfa/callback' element={<KFASSOCallbackPage/>}/>
                                    <Route path='kfa/integrate' element={<KFASSOIntegratePage/>}/>
                                    <Route path='kfa/silentLogin' element={<KFASSOSilentLoginPage/>}/>
                                </Route>


                            </Route>
                            <Route
                                path="*"
                                element={<Navigate to="/"/>}
                            />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </div>
        </>
    );
}

export default App;
