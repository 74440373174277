import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import FlexFillCenter from "./FlexFillCenter";
import ErrorBoundary from "./ErrorBoundary";
import AlertModal2 from "./modal/AlertModal2";
import {useEffect, useState} from "react";

export function LoginAwareWrapper({children}) {
    const navigate = useNavigate();
    const loginData = useSelector(state => state.userReducer.loginData);
    const location = useLocation();

    const [domReady, setDomReady] = useState(false) // AlertModal을 즉시 그리면 portal 대상 DOM이 없는 상태로 오류 발생

    useEffect(() => {
        setDomReady(true)
    }, [])

    if (loginData == null) {
        return (
            <ErrorBoundary
                fill={true}
            >
                <FlexFillCenter absolute={true}>로그인이 필요한 기능입니다</FlexFillCenter>
                {
                    domReady &&
                    <AlertModal2
                        message={"로그인이 필요한 기능입니다"}
                        onConfirm={e => navigate("/user/login", {state: {redirectUrl: location.pathname}})}
                    />
                }
            </ErrorBoundary>
        );
    }
    return (
        <ErrorBoundary
            fill={true}
        >
            {children}
        </ErrorBoundary>
    )
}