import BoardSearchBox from "../../../board/component/BoardSearchBox";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {deleteDownload, setDownloadVideoLogSearchParam} from "../../../../modules/userReducer";
import {DownloadLogSearchParam} from "./container";
import VideoDownloadLogTable from "./VideoDownloadLogTable";
import useDownloadRequestDeleteWithModal from "./useDownloadRequestDelete";


const jobStatuses = [
    {
        name: "대기중",
        value: "ready"
    },
    {
        name: "업로드중",
        value: "working"
    },
    {
        name: "업로드 중지",
        value: "stop"
    },
    {
        name: "업로드 실패",
        value: "fail"
    },
    {
        name: "업로드 성공",
        value: "success"
    }
];

export default function VideoLog() {
    const loginData = useSelector(state => state.userReducer.loginData);
    const [searchParam, setSearchParam] = useState(new DownloadLogSearchParam({
        "uid": loginData.uid,
        "userType": loginData.userType,
        "sourceType": "V",
        "_pageNo": 1,
        "_pageSize": 8,
        "keyword": "",
        "startDate": "",
        "endDate": "",
    }))
    useDownloadRequestDeleteWithModal(searchParam);

    const {
        data: downloadLogList,
        loading: listLoading,
        error: listError
    } = useSelector(state => state.userReducer.downloadVideoLogList);


    const dispatch = useDispatch();

    const deleteResult = useSelector(state => state.userReducer.deleteDownloadResult);

    useEffect(() => {
        dispatch(setDownloadVideoLogSearchParam(searchParam))
    }, [searchParam, deleteResult?.loading === false]);

    const changePage = (page) => {
        setSearchParam(s => ({
            ...s,
            _pageNo: page+1,
        }))
    }

    return <div className="order__item">

        <BoardSearchBox
            initialParam={searchParam}
            menus={jobStatuses}
            menuValueText="value"
            menuNameText="name"
            useMediaSearchFilter={false}
            onParamChanged={(param) => {
                console.log(DownloadLogSearchParam.toDownloadLogSearchParam(param))
            }}
        />

        <div className="content_body">
            {
                downloadLogList != null ?
                    <VideoDownloadLogTable
                        downloadLogList={downloadLogList}
                        onPageMove={changePage}
                        onDelete={(ids) => {
                            dispatch(deleteDownload(ids))
                        }}
                    /> : null
            }
        </div>
    </div>
}