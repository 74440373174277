import {Switch} from "@mui/material";

export default function KFASwitch({checked, onChange}) {
    return (
        <Switch
            checked={checked}
            sx={{

                "&.MuiSwitch-root .Mui-checked": {
                    color: "#E6002D"
                },
                "&.MuiSwitch-root .MuiSwitch-track": {
                    backgroundColor: "#ff5557 !important"
                }
            }}
            onChange={onChange}/>
    )
}