import {useDispatch, useSelector} from "react-redux";
import {forwardRef, useEffect, useRef, useState} from "react";
import VideoPlayer, {PlayerOption} from "./videoPlayer";
import {clearPip, popPip} from "../../modules/pipReducer";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import Config from "../../config/config";

const PipPlayer = forwardRef(
    ({}, ref) => {
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const loginData = useSelector(state => state.userReducer.loginData);
        const pipInfo = useSelector(state => state.pipReducer.pipVideoInfo);
        const containerRef = useRef();
        const [show, setShow] = useState(false);
        const videoRef = useRef();

        useEffect(() => {
            if(loginData == null){
                dispatch(clearPip());
            }
        }, [loginData]);

        useEffect(()=> {
            if(pipInfo != null){
                setShow(true);
            }
            else {
                setShow(false);
            }
        }, [pipInfo]);

        useEffect(()=> {
            if(show){
                console.log(pipInfo);
                const callback = () => {
                    videoRef.current?.seek(pipInfo.time ?? 0);
                    videoRef.current?.play();
                    setTimeout(()=> {
                        // FIXME Safari canPlay 안오는 문제로, init인 경우를 대비해 일정 간격 지정
                        videoRef.current?.play();
                    }, 1000)
                }
                const initCallback = () => {
                    videoRef.current?.removePlayerInitCallback(initCallback);
                    callback();
                }

                const loadCallback = () => {
                    videoRef.current?.removePlayerLoadedCallback(loadCallback);
                    callback();
                }

                // containerRef.current?.style?.display = show ? 'block' :
                videoRef.current?.initialize({
                    ...pipInfo?.playerOption,
                    useFloating: true,
                });
                videoRef.current?.makePip();
                videoRef.current?.addPlayerLoadedCallback(loadCallback);
                videoRef.current?.addPlayerInitCallback(initCallback);
            }
            else {
                videoRef.current?.dispose();
            }
        }, [show])

        const close = () => {
            dispatch(clearPip());
        }

        const backDetail = () => {
            navigate(pipInfo.backUrl);
            dispatch(popPip(videoRef.current?.getCurrentPlayTime()));
        }

        return (
            <div
                // className={'card-5'}
                className={'pip-container'}
                ref={containerRef}
                style={{
                    display: show === true ? 'block' : 'none' ,
                }}>
                <div
                    style={{
                        aspectRatio: 16 / 9,
                    }}
                >
                    <VideoPlayer
                        id={'pipPlayer'}
                        ref={videoRef}
                    />
                    <div className={'video_overlay'}>
                        <button onClick={backDetail}>
                            <i className="fa-solid fa-arrow-up-right-from-square fa-flip-horizontal"></i>
                        </button>

                        <button onClick={close}>
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
)

export default PipPlayer;