
/**
 * @deprecated
 * @param {[MatchInfo]} matchInfoList
 * @param game
 * @returns {[MatchInfo]}
 */
export const applyMatchOffset = (matchInfoList, game) => {
    if(game == null){
        return matchInfoList;
    }
    let fh = null;
    let sh = null;
    if(game.timeFh != null && game.timeFh > 0 && game.timeSh != null && game.timeSh > 0){
        fh = game.timeFh * 60;
        sh = game.timeSh * 60;
    }
    else if(game.playingTime != null){
        let time = Number(game.playingTime);
        if(time > 0){
            fh = time/2 * 60;
            sh = time/2 * 60;
        }
    }
    let fs = matchInfoList.find(it => it.id === 'FS');
    let ss = matchInfoList.find(it => it.id === 'SS')
    if(fh != null){
        if(fs != null){
            fs.endTime = fs.time + fh;
        }
        if(ss != null) {
            ss.startTime = fh;
        }
    }
    if(sh != null){
        if(ss != null){
            ss.endTime = ss.time + sh;
        }
    }

    return matchInfoList
}


export const matchInfoListToVideoOffset = (videoOffsetList) => {
    const videoOffset = {};
    for(let index in videoOffsetList){
        const offsetItem = videoOffsetList[index];
        if(offsetItem.id === "FS"){
            videoOffset.firstHalfStartPos = offsetItem.time;
        }
        else  if(offsetItem.id === "FE"){
            videoOffset.firstHalfEndPos = offsetItem.time;
        }
        else if(offsetItem.id === "SS"){
            videoOffset.secondHalfStartPos = offsetItem.time;
        }
        else if(offsetItem.id === "SE"){
            videoOffset.secondHalfEndPos = offsetItem.time;
        }
        else if(offsetItem.id === "SE"){
            videoOffset.secondHalfEndPos = offsetItem.time;
        }
        else if(offsetItem.id === "EFS"){
            videoOffset.extraFirstHalfStartPos = offsetItem.time;
        }
        else if(offsetItem.id === "EFE"){
            videoOffset.extraFirstHalfEndPos = offsetItem.time;
        }
        else if(offsetItem.id === "ESS"){
            videoOffset.extraSecondHalfStartPos = offsetItem.time;
        }
        else if(offsetItem.id === "ESE"){
            videoOffset.extraSecondHalfEndPos = offsetItem.time;
        }
        else if(offsetItem.id === "PSS"){
            videoOffset.shootOutStartPos = offsetItem.time;
        }
        else if(offsetItem.id === "PSE"){
            videoOffset.shootOutEndPos = offsetItem.time;
        }
    }
    return videoOffset;
}

/**
 * 00:00 시간을 초로 변환
 * @param mmss
 * @returns {number}
 */
export const mmssToSec = (mmss) => {
    const split = mmss.split(':');
    if(split.length === 3){
        const [hourStr, minuteStr, secondStr] = mmss.split(':');
        return hourStr * 60 * 60 + minuteStr * 60 + secondStr * 1
    }
    else if(split.length === 2){
        const [minuteStr, secondStr] = mmss.split(':');
        return minuteStr * 60 + secondStr * 1
    }
    else if(split.length === 1){
        const [secondStr] = mmss.split(':');
        return secondStr * 1;
    }
    else {
        return 0;
    }
}
/**
 * 초 값을 00:00으로 변환
 * @param sec
 * @returns {`${string}:${string}`}
 */
export const secToMmss = (sec) => {
    return `${Math.floor(sec/60).toString().padStart(2, '0')}:${(Math.round(sec%60)).toString().padStart(2, '0')}`;
}

/**
 * 초 값을 00:00으로 변환
 * @param sec
 * @returns {`${string}:${string}:${string}`}
 */
export const secToHHmmss = (sec) => {
    return `${Math.floor(sec/3600).toString().padStart(2, '0')}:${Math.floor((sec/60) % 60).toString().padStart(2, '0')}:${(Math.round(sec%60)).toString().padStart(2, '0')}`;
}
export const findIconType = (eventCodeList, eventCd) => {
    return eventCodeList.find(code => code.eventCd === eventCd)?.mappings?.find(it => true)?.iconType;
}


/**
 * @deprecated
 * timeMMSS mm:ss & injuryTimeMMSS mm:ss or null 및 videoOffset을 통해 이벤트 시간을 영상 시간으로 변환
 * @param {string} timeMMSS 이벤트 시간 (표준) 또는 승부차기시 O/X
 * @param {string|null}injuryTimeMMSS 이벤트 시간 (연장)
 * @param halfTime
 * @param fullTime
 * @param extraFirst
 * @param extraSecond
 * @returns {*}
 */
export const getEventTimeOffsetText = (
    timeMMSS,
    injuryTimeMMSS,
    halfTime = 45 * 60,
    fullTime = halfTime * 2,
    extraFirst = fullTime + 15 * 60,
    extraSecond = extraFirst + 30 * 60,
) => {
    if(timeMMSS === 'O' || timeMMSS  ==='X') { // 승부차기
        return `승부차기 ${timeMMSS}`
    }
    if(timeMMSS.includes("+")){
        timeMMSS = timeMMSS.replaceAll(" ", "").split("+")[0];
    }
    const time = mmssToSec(timeMMSS);
    let injuryTime = null;
    if(injuryTimeMMSS != null){
        injuryTime = mmssToSec(injuryTimeMMSS)
    }
    if(time <= halfTime && injuryTimeMMSS == null){
        return `전반${timeMMSS}`
    }
    else if(time <= halfTime && injuryTimeMMSS != null){
        return `전반${secToMmss(halfTime)}+${injuryTimeMMSS}`
    }
    else if(time <= fullTime && injuryTimeMMSS == null){
        return `후반${secToMmss(time)}`
    }
    else if(time <= fullTime && injuryTimeMMSS != null){
        if(injuryTime < 15*60){
            return `후반${secToMmss(fullTime)}+${injuryTimeMMSS}`
        }
        else if(injuryTime < 30*60){
            return `연전${secToMmss(injuryTime)}`
        }
        else {
            return `연후${secToMmss(injuryTime)}`
        }

    }
    else if(time <= extraFirst && injuryTimeMMSS == null){
        return `연전${secToMmss(time - fullTime)}`
    }
    else if(time <= extraFirst && injuryTimeMMSS != null){
        return `연전${secToMmss(time - fullTime)}+${injuryTimeMMSS}`
    }
    else if(time < extraSecond && injuryTimeMMSS == null){
        return `연후${secToMmss(time - extraFirst)}`
    }
    else if(time < extraSecond && injuryTimeMMSS != null){
        return `연후${secToMmss(time - extraFirst)}+${injuryTimeMMSS}`
    }
    if(time >= extraSecond){
        let time1 = time;
        if(injuryTime != null){
            time1 += injuryTime;
        }
        return `승부차기${secToMmss(time1)}`;
    }
}

export const sortEvent = (event) => {
    return [...event].sort((a, b) => {
        const timeDiff = mmssToSec(a.timeMisec) - mmssToSec(b.timeMisec)
        if (timeDiff !== 0) {
            return timeDiff;
        } else {
            return mmssToSec(a.injuryTimeMisec ?? '0') - mmssToSec(b.injuryTimeMisec ?? '0');
        }
    })
}


export default class VideoOffsetUtil{
    constructor({
                    halfTime,
                    fullTime,
                    extraTime,
                }) {
        this.halfTime = halfTime;
        this.fullTime = fullTime;
        this.extraTime = extraTime;
        this.extraFirst = this.fullTime + extraTime;
        this.extraSecond = this.fullTime + extraTime + extraTime;
    }

    static fromPlayingAndHalfTime({
                                      playingTime,
                                      halfTime,
                                  }) {
        const playTime = Number(playingTime);
        let halfTimeNumber =  Number(halfTime);
        let ht = 0;
        if(halfTimeNumber != null && halfTimeNumber > 0){
            ht = halfTimeNumber * 60;
        }
        else if(playTime != null && !isNaN(playTime) && playTime > 0){
            ht = Math.floor((playTime / 2) * 60);
        }
        if(ht === 0) {
            ht = 45 * 60;
        }

        // 대칭 경기만 처리
        const ft = halfTime * 2
        const et = 15*60

        return new VideoOffsetUtil({
            halfTime: ht,
            fullTime: ft,
            extraTime: et,
        })
    }

    static fromGame(game){
        return VideoOffsetUtil.fromPlayingAndHalfTime({
            playingTime: game?.playingTime,
            halfTime: game?.timeFh,
        });
    }

    static fromPortalVideo(video) {
        let data = video?.data?.data?._source;
        return VideoOffsetUtil.fromPlayingAndHalfTime({
            playingTime: data?.playing_time,
            halfTime: data?.time_fh,
        });
    }

    findIconType(eventCodeList, eventCd){
        return eventCodeList.find(code => code.eventCd === eventCd)?.mappings?.find(it => true)?.iconType;
    }

    makeTitle(playerTime, matchInfoList, nowEvent, playerData){
        const {time, injuryTime} = this.videoOffsetToEventTime(playerTime, matchInfoList,);
        const timeMisec = secToMmss(time);
        const injuryTimeMisec = injuryTime == null ? null : secToMmss(injuryTime);
        let player = playerData?.home?.find(it => it.aplayerIdx === nowEvent?.aplayerIdx)
        let isHomePlayer = player != null;
        let titleList = [];
        if(player == null){
            player = playerData?.away.find(it => it.aplayerIdx === nowEvent?.aplayerIdx)
        }
        if(player != null) {
            titleList.push(
                isHomePlayer ? playerData.homeTeam : playerData.awayTeam,
            )
        }

        if(player != null) {
            titleList.push(
                `${player.entryno}${player.hname}${player.position}`
            )
        }

        titleList.push(
            nowEvent.eventNm,
        );

        if(player != null) {
            titleList.push(
                isHomePlayer ? `${playerData.homeTeam} vs ${playerData.awayTeam}` : `${playerData.awayTeam} vs ${playerData.homeTeam}`
            )
        }
        titleList.push(
            this.getEventTimeOffsetText(timeMisec, injuryTimeMisec)
        )
        return titleList.join('_')
    }

    makeTitle2(playerRef, nowEvent, playerData){
        return this.makeTitle(playerRef.current.getCurrentPlayTime(), playerRef.current.getOption().matchInfoList, nowEvent, playerData)
    }

    /**
     * [00:00 ~ 45:00] 전반
     * [45:00 + 00:01 ~ 45:00 + 15:00] 전반
     * (45:00 ~ 90:00] 후반
     * (90:00 ~ 105:00] 연전
     * [105:00 + 00:01 ~ 105:00 + 15:00] 연전
     * (105:00 ~ 120:00] 연후
     * [120:00 + 00:01 ~ 120:00 + 15:00] 연후
     * timeMMSS mm:ss & injuryTimeMMSS mm:ss or null 및 videoOffset을 통해 이벤트 시간을 영상 시간으로 변환
     * @param {string} timeMMSS 이벤트 시간 (표준) 또는 승부차기시 O/X
     * @param {string|null}injuryTimeMMSS 이벤트 시간 (연장)
     * @param {[MatchInfo]} videoOffsetList
     * @param {string} eventCd
     * @returns {*}
     */

    eventToVideoOffsetWithList(timeMMSS, injuryTimeMMSS, videoOffsetList, eventCd){
        const videoOffset = matchInfoListToVideoOffset(videoOffsetList);
        if(timeMMSS === 'O' || timeMMSS  ==='X') { // 승부차기
            return videoOffset.shootOutStartPos
        }
        else {
            const time = mmssToSec(timeMMSS);
            let injuryTime = null;
            if(injuryTimeMMSS != null){
                injuryTime = mmssToSec(injuryTimeMMSS)
            }
            if(time < this.halfTime){
                return videoOffset.firstHalfStartPos + time
            }
            else if(time === this.halfTime){
                if(injuryTime == null){
                    return videoOffset.firstHalfStartPos + time
                }
                else {
                    return videoOffset.firstHalfStartPos + this.halfTime + injuryTime
                }
            }
            else if(time < this.fullTime) {
                return videoOffset.secondHalfStartPos - this.halfTime + time
            }
            else if(time === this.fullTime) {
                if(injuryTime == null){
                    return videoOffset.secondHalfStartPos - this.halfTime + time
                }
                else {
                    return videoOffset.secondHalfStartPos + this.halfTime + injuryTime
                }
            }
            else if(time < this.extraFirst) {
                if(videoOffset.extraFirstHalfStartPos != null){
                    return videoOffset.extraFirstHalfStartPos + time - this.fullTime
                }
                else {
                    return videoOffset.secondHalfStartPos - this.halfTime + time
                }
            }
            else if(time === this.extraFirst) {
                if(injuryTime == null){
                    if(videoOffset.extraFirstHalfStartPos != null){
                        return videoOffset.extraFirstHalfStartPos + time - this.fullTime
                    }
                    else {
                        return videoOffset.secondHalfStartPos - this.halfTime + time
                    }
                }
                else {
                    if(videoOffset.extraFirstHalfStartPos != null){
                        return videoOffset.extraFirstHalfStartPos + injuryTime
                    }
                    else {
                        return videoOffset.secondHalfStartPos + this.halfTime + injuryTime
                    }
                }
            }
            else if(time < this.extraSecond) {
                if(videoOffset.extraSecondHalfStartPos != null){
                    return videoOffset.extraSecondHalfStartPos + time - this.extraFirst
                }
                else {
                    return videoOffset.secondHalfEndPos - this.halfTime + time
                }
            }
            else if(time === this.extraSecond) {
                if(eventCd === "A81") { // 승부차기
                    if(injuryTime == null){
                        return (videoOffset.shootOutStartPos ?? videoOffset.extraSecondHalfEndPos ?? videoOffset.secondHalfEndPos ) + time - this.extraSecond;
                    }
                    else {
                        return (videoOffset.shootOutStartPos ?? videoOffset.extraSecondHalfEndPos ?? videoOffset.secondHalfEndPos) + injuryTime
                    }
                }
                else if(injuryTime == null){
                    if(videoOffset.extraSecondHalfStartPos != null){
                        return videoOffset.extraSecondHalfStartPos + time - this.extraFirst
                    }
                    else {
                        return videoOffset.secondHalfEndPos - this.halfTime + time
                    }
                }
                else {
                    if(videoOffset.extraSecondHalfStartPos != null){
                        return videoOffset.extraSecondHalfStartPos + injuryTime
                    }
                    else {
                        return videoOffset.secondHalfEndPos + this.halfTime + 15*60 + injuryTime
                    }
                }
            }
            else {
                if(injuryTime == null){
                    return (videoOffset.shootOutStartPos ?? videoOffset.extraSecondHalfEndPos ?? videoOffset.secondHalfEndPos ) + time - this.extraSecond;
                }
                else {
                    return (videoOffset.shootOutStartPos ?? videoOffset.extraSecondHalfEndPos ?? videoOffset.secondHalfEndPos) + injuryTime
                }
            }
        }
    }

    /**
     *
     * @param event
     * @param videoOffsetList
     * @param {GameModel}gameModel
     * @returns {{startTime: number, endTime: number}}
     */
    getSection(event, videoOffsetList, gameModel){
        const videoOffset = matchInfoListToVideoOffset(videoOffsetList);
        const time = mmssToSec(event.timeMisec);

        let injuryTime = null;
        if(event.injuryTimeMisec != null){
            injuryTime = mmssToSec(event.injuryTimeMisec)
        }

        let startTime = null;
        let startInjuryTime = null;
        let startTimes = event.startTime.split('+');
        if(startTimes.length > 0){
            startTime = mmssToSec(startTimes[0])
        }
        if(startTimes.length > 1) {
            startInjuryTime = mmssToSec(startTimes[1])
        }

        let endTime = null;
        let endInjuryTime = null;
        let endTimes = event.endTime.split('+');
        if(endTimes.length > 0){
            endTime = mmssToSec(endTimes[0])
        }
        if(endTimes.length > 1) {
            endInjuryTime = mmssToSec(endTimes[1])
        }

        if(time <= this.halfTime){
            if(injuryTime == null){
                const startPos = videoOffset.firstHalfStartPos;
                const endPos = videoOffset.firstHalfStartPos + this.halfTime;
                const eventTime = videoOffset.firstHalfStartPos + time; // 이벤트 시간
                const estimateStartTime = startPos + startTime
                const estimateEndTime = startPos + endTime
                return {
                    startTime: Math.min(eventTime, Math.max(startPos, estimateStartTime)), // 전반 시작보다 커야하고, 이벤트 시간보다 작아야함
                    endTime: Math.max(eventTime, Math.min(endPos, estimateEndTime)) // 전반 종료보다 작아야하고, 이벤트 시간보다 커야함
                }
            }
            else {
                const startPos = videoOffset.firstHalfStartPos + this.halfTime;
                const endPos = videoOffset.firstHalfStartPos + this.halfTime + this.extraTime;
                const eventTime = startPos + injuryTime; // 이벤트 시간
                const estimateStartTime = startPos + startTime - this.halfTime + (startInjuryTime ?? 0);
                const estimateEndTime = startPos + endTime - this.halfTime + (endInjuryTime ?? 0);
                return {
                    startTime: Math.min(eventTime, Math.max(startPos, estimateStartTime)), // 전반연장보다 크고, 이벤트 시간보다 작아야함
                    endTime: Math.max(eventTime, Math.min(endPos,  estimateEndTime)) // 전반연장종료보다 작고, 이벤트 시간보다 커야함
                }
            }
        }
        else if(time <= this.fullTime) {
            if(injuryTime == null){
                const startPos = videoOffset.secondHalfStartPos;
                const endPos = videoOffset.secondHalfStartPos + this.halfTime;
                const eventTime = videoOffset.secondHalfStartPos + time - this.halfTime; // 이벤트 시간
                const estimateStartTime = startPos + startTime - this.halfTime
                const estimateEndTime = startPos + endTime - this.halfTime
                return {
                    startTime: Math.min(eventTime, Math.max(startPos, estimateStartTime)), // 후반 시작보다 크고, 이벤트 시간보다 작아야함
                    endTime: Math.max(eventTime, Math.min(endPos, estimateEndTime))// 후반 종료보다 작고, 이벤트 시간보다 커야함
                }
            }
            else {
                if(injuryTime <= this.extraTime){ // 90+15 이내 후반연장
                    const startPos = videoOffset.secondHalfStartPos + this.halfTime;
                    const endPos = startPos + this.extraTime;
                    const eventTime = startPos + injuryTime; // 이벤트 시간
                    const estimateStartTime = startPos + startTime - this.fullTime + (startInjuryTime ?? 0);
                    const estimateEndTime = startPos + endTime - this.fullTime + (endInjuryTime ?? 0);
                    return {
                        startTime: Math.min(eventTime, Math.max(startPos, estimateStartTime)), // 연장전반시작보다 크고, 이벤트 시간보다 작아야함
                        endTime: Math.max(eventTime, Math.min(endPos, estimateEndTime)) // 연장전반종료보다 작고, 이벤트 시간보다 커야함
                    }
                }
                else { // 연장 후반
                    const startPos = this.getExtraSecondStartTime(videoOffset, this.halfTime) // 연장후반 시간 없으면 연장전반 + this.extraTime or 후반시작 + this.halfTime + this.extraTime
                    const endPos = startPos + this.extraTime;
                    const eventTime = startPos + injuryTime - this.extraTime; // 이벤트 시간
                    const estimateStartTime = startPos + startTime - this.extraFirst + (startInjuryTime ?? 0);
                    const estimateEndTime = startPos + endTime - this.extraFirst + (endInjuryTime ?? 0);
                    return {
                        startTime: Math.min(eventTime, Math.max(startPos, estimateStartTime)), // 연장전반시작보다 크고, 이벤트 시간보다 작아야함
                        endTime: Math.max(eventTime, Math.min(endPos, estimateEndTime)) // 연장전반종료보다 작고, 이벤트 시간보다 커야함
                    }
                }
            }
        }
        // 연장 전반
        else if(time <= this.extraFirst) {
            if(injuryTime == null){
                const startPos = this.getExtraFirstStartTime(videoOffset) // 연장 전반 시간 없으면, 후반시작
                const endPos = startPos + this.extraTime;
                const eventTime = startPos + time - this.fullTime; // 이벤트 시간
                const estimateStartTime = startPos + startTime - this.fullTime + (startInjuryTime ?? 0);
                const estimateEndTime = startPos + endTime - this.fullTime + (endInjuryTime ?? 0);
                return {
                    startTime: Math.min(eventTime, Math.max(startPos, estimateStartTime)), // 연장전반시작보다 크고, 이벤트 시간보다 작아야함
                    endTime: Math.max(eventTime, Math.min(endPos, estimateEndTime)) // 연장전반종료보다 작고, 이벤트 시간보다 커야함
                }
            }
            else {
                // 연장 전반 연장
                const startPos = this.getExtraFirstStartTime(videoOffset) + this.extraTime // 연장후반 시간 없으면 연장전반 + this.extraTime or 후반시작  + this.extraTime
                const endPos = startPos + this.extraTime;
                const eventTime = startPos + injuryTime; // 이벤트 시간
                const estimateStartTime = startPos + startTime - this.fullTime + (startInjuryTime ?? 0);
                const estimateEndTime = startPos + endTime - this.fullTime + (endInjuryTime ?? 0);
                return {
                    startTime: Math.min(eventTime, Math.max(startPos, estimateStartTime)), // 연장전반시작보다 크고, 이벤트 시간보다 작아야함
                    endTime: Math.max(eventTime, Math.min(endPos, estimateEndTime)) // 연장전반종료보다 작고, 이벤트 시간보다 커야함
                }
            }
        }
        else if(time < this.extraSecond) { // 연장 후반 ~
            if(injuryTime == null){
                const startPos = this.getExtraSecondStartTime(videoOffset) // 연장후반 시간 없으면 연장전반 + this.extraTime or 후반시작  + this.extraTime
                const endPos = startPos + this.extraTime;
                const eventTime = startPos + time - this.extraFirst; // 이벤트 시간
                const estimateStartTime = startPos + startTime - this.extraFirst + (startInjuryTime ?? 0);
                const estimateEndTime = startPos + endTime - this.extraFirst + (endInjuryTime ?? 0);
                return {
                    startTime: Math.min(eventTime, Math.max(startPos, estimateStartTime)), // 연장전반시작보다 크고, 이벤트 시간보다 작아야함
                    endTime: Math.max(eventTime, Math.min(endPos, estimateEndTime)) // 연장전반종료보다 작고, 이벤트 시간보다 커야함
                }
            }
            else {
                // 연장 후반 연장
                const startPos = this.getExtraSecondStartTime(videoOffset) + this.extraTime // 연장후반 시간 없으면 연장전반 + this.extraTime or 후반시작  + this.extraTime
                const endPos = startPos + this.extraTime;
                const eventTime = startPos + injuryTime - this.extraTime; // 이벤트 시간
                const estimateStartTime = startPos + startTime - this.extraFirst + (startInjuryTime ?? 0);
                const estimateEndTime = startPos + endTime - this.extraFirst + (endInjuryTime ?? 0);
                return {
                    startTime: Math.min(eventTime, Math.max(startPos, estimateStartTime)), // 연장후반시작보다 크고, 이벤트 시간보다 작아야함
                    endTime: Math.max(eventTime, Math.min(endPos, estimateEndTime)) // 연장후반종료보다 작고, 이벤트 시간보다 커야함
                }
            }
        }
        else { // 승부차기
            const startPos = this.getShootOutStartTime(videoOffset) // 연장후반 시간 없으면 연장전반 + this.extraTime or 후반시작 + this.halfTime + this.extraTime
            const endPos = startPos + this.extraTime;
            const eventTime = startPos + time - this.extraSecond + (injuryTime ?? 0); // 이벤트 시간
            const estimateStartTime = startPos + startTime - this.extraSecond + (startInjuryTime ?? 0);
            const estimateEndTime = startPos + endTime - this.extraSecond + (endInjuryTime ?? 0);
            return {
                startTime: Math.min(eventTime, Math.max(startPos, estimateStartTime)), // 연장후반시작보다 크고, 이벤트 시간보다 작아야함
                endTime: Math.max(eventTime, Math.min(endPos, estimateEndTime)) // 연장후반종료보다 작고, 이벤트 시간보다 커야함
            }
        }
    }


    /**
     * @deprecated
     * @param time
     * @param injuryTime
     * @param videoOffsetList
     * @param eventCd
     * @param limitNextSection
     * @returns {number|*}
     */
    eventToVideoOffsetWithListWithSec(time, injuryTime, videoOffsetList, eventCd, limitNextSection = false) {
        const videoOffset = matchInfoListToVideoOffset(videoOffsetList);
        if(time < this.halfTime){
            return videoOffset.firstHalfStartPos + time
        }
        else if(time === this.halfTime){
            if(injuryTime == null){
                return videoOffset.firstHalfStartPos + time
            }
            else {
                if(limitNextSection){
                    return Math.min(videoOffset.firstHalfStartPos + this.halfTime + injuryTime, videoOffset.secondHalfStartPos)
                }
                else {
                    return videoOffset.firstHalfStartPos + this.halfTime + injuryTime
                }
            }
        }
        else if(time < this.fullTime) {
            return videoOffset.secondHalfStartPos - this.halfTime + time
        }
        else if(time === this.fullTime) {
            if(injuryTime == null){
                return videoOffset.secondHalfStartPos - this.halfTime + time
            }
            else {
                if(limitNextSection && videoOffset.extraFirstHalfStartPos != null){
                    return Math.min(videoOffset.secondHalfStartPos + this.halfTime + injuryTime, videoOffset.extraFirstHalfStartPos)
                }
                else {
                    return videoOffset.secondHalfStartPos + this.halfTime + injuryTime
                }
            }
        }
        else if(time < this.extraFirst) {
            if(videoOffset.extraFirstHalfStartPos != null){
                return videoOffset.extraFirstHalfStartPos + time - this.fullTime
            }
            else {
                return videoOffset.secondHalfStartPos - this.halfTime + time
            }
        }
        else if(time === this.extraFirst) {
            if(injuryTime == null){
                if(videoOffset.extraFirstHalfStartPos != null){
                    return videoOffset.extraFirstHalfStartPos + time - this.fullTime
                }
                else {
                    return videoOffset.secondHalfStartPos - this.halfTime + time
                }
            }
            else {
                if(videoOffset.extraFirstHalfStartPos != null){
                    return videoOffset.extraFirstHalfStartPos + injuryTime
                }
                else {
                    if(limitNextSection && videoOffset.extraSecondHalfStartPos != null){
                        return Math.min(videoOffset.secondHalfStartPos + this.halfTime + injuryTime, videoOffset.extraSecondHalfStartPos)
                    }
                    else {
                        return videoOffset.secondHalfStartPos + this.halfTime + injuryTime
                    }
                }
            }
        }
        else if(time < this.extraSecond) {
            if(videoOffset.extraSecondHalfStartPos != null){
                return videoOffset.extraSecondHalfStartPos + time - this.extraFirst
            }
            else {
                return videoOffset.secondHalfEndPos - this.halfTime + time
            }
        }
        else if(time === this.extraSecond) {
            if(eventCd === "A81") { // 승부차기
                if(injuryTime == null){
                    return (videoOffset.shootOutStartPos ?? videoOffset.extraSecondHalfEndPos ?? videoOffset.secondHalfEndPos ) + time - this.extraSecond;
                }
                else {
                    return (videoOffset.shootOutStartPos ?? videoOffset.extraSecondHalfEndPos ?? videoOffset.secondHalfEndPos) + injuryTime
                }
            }
            else if(injuryTime == null){
                if(videoOffset.extraSecondHalfStartPos != null){
                    return videoOffset.extraSecondHalfStartPos + time - this.extraFirst
                }
                else {
                    return videoOffset.secondHalfEndPos - this.halfTime + time
                }
            }
            else {
                if(videoOffset.extraSecondHalfStartPos != null){
                    return videoOffset.extraSecondHalfStartPos + injuryTime
                }
                else {
                    return videoOffset.secondHalfEndPos + this.halfTime + 15*60 + injuryTime
                }
            }
        }
        else {
            if(injuryTime == null){
                return (videoOffset.shootOutStartPos ?? videoOffset.extraSecondHalfEndPos ?? videoOffset.secondHalfEndPos ) + time - this.extraSecond;
            }
            else {
                return (videoOffset.shootOutStartPos ?? videoOffset.extraSecondHalfEndPos ?? videoOffset.secondHalfEndPos) + injuryTime
            }
        }
    }

    getExtraFirstStartTime(videoOffset){
        return videoOffset.extraFirstHalfStartPos ?? (videoOffset.secondHalfStartPos + this.halfTime)
    }

    getExtraSecondStartTime(videoOffset){
        return videoOffset.extraSecondHalfStartPos ?? (this.getExtraFirstStartTime(videoOffset,) + this.extraTime)
    }

    getShootOutStartTime(videoOffset){
        return videoOffset.shootOutStartPos ?? (this.getExtraSecondStartTime(videoOffset) + this.extraTime)
    }


    /**
     *
     * timeMMSS mm:ss & injuryTimeMMSS mm:ss or null 및 videoOffset을 통해 이벤트 시간을 영상 시간으로 변환
     * @param {string} timeMMSS 이벤트 시간 (표준) 또는 승부차기시 O/X
     * @param {string|null}injuryTimeMMSS 이벤트 시간 (연장)
     * @returns {*}
     */
    getEventTimeOffsetText(timeMMSS, injuryTimeMMSS,){
        if(timeMMSS === 'O' || timeMMSS  ==='X') { // 승부차기
            return `승부차기 ${timeMMSS}`
        }
        if(timeMMSS.includes("+")){
            timeMMSS = timeMMSS.replaceAll(" ", "").split("+")[0];
        }
        const time = mmssToSec(timeMMSS);
        let injuryTime = null;
        if(injuryTimeMMSS != null){
            injuryTime = mmssToSec(injuryTimeMMSS)
        }
        if(time <= this.halfTime && injuryTimeMMSS == null){
            return `전반${timeMMSS}`
        }
        else if(time <= this.halfTime && injuryTimeMMSS != null){
            return `전반${secToMmss(this.halfTime)}+${injuryTimeMMSS}`
        }
        else if(time <= this.fullTime && injuryTimeMMSS == null){
            return `후반${secToMmss(time)}`
        }
        else if(time <= this.fullTime && injuryTimeMMSS != null){
            if(injuryTime < 15*60){
                return `후반${secToMmss(this.fullTime)}+${injuryTimeMMSS}`
            }
            else if(injuryTime < 30*60){
                return `연전${secToMmss(injuryTime)}`
            }
            else {
                return `연후${secToMmss(injuryTime)}`
            }

        }
        else if(time <= this.extraFirst && injuryTimeMMSS == null){
            return `연전${secToMmss(time - this.fullTime)}`
        }
        else if(time <= this.extraFirst && injuryTimeMMSS != null){
            return `연전${secToMmss(time - this.fullTime)}+${injuryTimeMMSS}`
        }
        else if(time <= this.extraSecond && injuryTimeMMSS == null){
            return `연후${secToMmss(time - this.extraFirst)}`
        }
        else if(time <= this.extraSecond && injuryTimeMMSS != null){
            return `연후${secToMmss(time - this.extraFirst)}+${injuryTimeMMSS}`
        }
        if(time > this.extraSecond){
            let time1 = time;
            if(injuryTime != null){
                time1 += injuryTime;
            }
            return `승부차기${secToMmss(time1)}`;
        }
    }

    /**
     * @param {[MatchInfo]} matchInfoList
     * @param game
     * @returns {[MatchInfo]}
     */
    applyMatchOffset(matchInfoList, game){
        if(game == null){
            return matchInfoList;
        }
        let fh = null;
        let sh = null;
        if(game.timeFh != null && game.timeFh > 0 && game.timeSh != null && game.timeSh > 0){
            fh = game.timeFh * 60;
            sh = game.timeSh * 60;
        }
        else if(game.playingTime != null){
            let time = Number(game.playingTime);
            if(time > 0){
                fh = time/2 * 60;
                sh = time/2 * 60;
            }
        }
        let fs = matchInfoList.find(it => it.id === 'FS');
        let ss = matchInfoList.find(it => it.id === 'SS')
        if(fh != null){
            if(fs != null){
                fs.endTime = fs.time + fh;
            }
            if(ss != null) {
                ss.startTime = fh;
            }
        }
        if(sh != null){
            if(ss != null){
                ss.endTime = ss.time + sh;
            }
        }

        return matchInfoList
    }

    /**
     *
     * @param videoTime
     * @param {[MatchInfo]} matchInfos
     * @returns {{time: string, injuryTime: number}}
     */
    videoOffsetToEventTime(videoTime, matchInfos){
        const videoOffset = matchInfoListToVideoOffset(matchInfos);
        const extraFirstStart = this.getExtraFirstStartTime(videoOffset);
        const extraSecondStart = this.getExtraSecondStartTime(videoOffset);
        const shootOutStartTime = this.getShootOutStartTime(videoOffset);
        if(videoTime < videoOffset.firstHalfStartPos){
            return {
                time: 0,
                injuryTime: null,
            }
        }
        // 전반 시간 내
        else if( videoOffset.firstHalfStartPos <= videoTime && videoTime < videoOffset.firstHalfStartPos + this.halfTime ){
            let res = {
                time: videoTime - videoOffset.firstHalfStartPos,
                injuryTime: null,
            };
            if(res.time >= this.halfTime){
                let time = res.time;
                res.time = this.halfTime
                res.injuryTime = time - this.halfTime;
            }
            return res;
        }
        // 전반 시간 이후, 후반 시간 전 (전반 연장)
        else if(videoTime < videoOffset.secondHalfStartPos){
            return {
                time: this.halfTime,
                injuryTime: videoTime - videoOffset.firstHalfStartPos - this.halfTime,
            };
        }
        // 후반전
        else if( videoOffset.secondHalfStartPos <= videoTime && videoTime < videoOffset.secondHalfStartPos + this.halfTime ){
            let res = {
                time: videoTime - videoOffset.secondHalfStartPos + this.halfTime,
                injuryTime: null,
            };
            if(res.time >= this.fullTime){
                let time = res.time;
                res.time = this.fullTime
                res.injuryTime = time - this.fullTime;
            }
            return res;
        }

        // 후반 시간 이후, 연장 시간 전 (전반 연장)
        else if(videoTime < extraFirstStart){
            return {
                time: this.fullTime,
                injuryTime: videoTime - videoOffset.secondHalfStartPos - this.halfTime,
            };
        }

        // 연장 전반?
        else if( extraFirstStart <= videoTime && videoTime <= (extraFirstStart + this.extraTime) ){
            if(videoOffset.extraFirstHalfStartPos == null){
                // 연장전 정보가 없으면 전반 연장
                return {
                    time: this.fullTime,
                    injuryTime: videoTime - videoOffset.secondHalfStartPos - this.halfTime,
                }
            }
            else {
                return {
                    time: videoTime - extraFirstStart + this.fullTime,
                    // injuryTime: videoTime - videoOffset.extraFirstHalfStartPos,
                }
            }
        }

        // 연장 전반 연장
        else if( extraSecondStart > videoTime ){
            return {
                time: this.extraFirst,
                injuryTime: videoTime - extraFirstStart - this.extraTime,
            }
        }

        // 연장 후반
        else if( extraSecondStart <= videoTime && videoTime <= extraSecondStart + this.extraTime ){
            return {
                time: videoTime - extraSecondStart + this.extraFirst,
                // injuryTime: videoTime - videoOffset.extraSecondHalfStartPos,
            }
        }

        // 승부차기
        else if( shootOutStartTime != null && shootOutStartTime <= videoTime){
            return {
                time: videoTime - shootOutStartTime + this.extraSecond,
                // injuryTime: videoTime - videoOffset.shootOutStartPos,
            }
        }

        // 연장 후반 연장
        else if( extraSecondStart + this.extraTime + this.extraTime > videoTime ){
            return {
                time: this.extraSecond,
                injuryTime: videoTime - extraSecondStart - this.extraTime,
            }
        }
        // 연장전반 없을때 연장
        else if( videoOffset.firstHalfEndPos <= videoTime && videoTime <= videoOffset.secondHalfStartPos){
            return {
                time: this.halfTime,
                injuryTime: videoTime - videoOffset.firstHalfEndPos,
            }
        }

        // 연장후반 없을때 연장
        else if( videoOffset.secondHalfEndPos <= videoTime){
            return {
                time: this.fullTime,
                injuryTime: videoTime - videoOffset.secondHalfStartPos - this.halfTime,
            }
        }

        else {
            return {
                time: videoTime,
                injuryTime: null,
            }
        }
    }
}