import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo, useRef, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {useLoadingModal} from "../../common/component/LoadingState";
import {clearLive, getLive} from "../../modules/liveReducer";
import {LiveModel} from "../../common/component/home/Live";
import FlexPlayer from "../../common/component/flexPlayer";
import {getWatermark} from "../../modules/watermarkReducer";
import Config from "../../config/config";
import {MessageError} from "../../common/component/ErrorBoundary";
import Attachments from "../../common/component/Attachments";
import Hashtags from "../../common/component/Hashtags";
import ReactGA from "react-ga4";
import {LoginAwareWrapper} from "../../common/component/LoginAwareWrapper";
import ChromecastButton from "../../lib/chromecast/ChromeCastButton";
import BlurryPoster from "./BlurryPoster";
import LiveCountdown from "./LiveCountdown";


export default function LiveDetailPage() {

    return (
        <LoginAwareWrapper>
            <LiveDetail/>
        </LoginAwareWrapper>
    )
}


function LiveDetail() {
    const dispatch = useDispatch();
    const location = useLocation();
    const param = useParams();
    const loginData = useSelector(state => state.userReducer.loginData);
    const live = useSelector(state => state.liveReducer.live);
    const [state, setState] = useState(new LiveModel());
    const [liveState, setLiveState] = useState('ready');
    const [showPlayer, setShowPlayer] = useState(false);
    const [playerOption, setPlayerOption] = useState();
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const videoPlayerRef = useRef();

    const onChromeCastConnected = () => {
        videoPlayerRef.current?.play();
    }
    const onChromeCastDisconnected = () => {
        videoPlayerRef.current?.pause();
    }

    const liveDetailAc = useMemo(()=> {
        return loginData.live_detail_ac >= 1;
    }, []);
    const isVideoPlaying = () => {
        // console.log(videoPlayerRef.current?.getState())
        let isPlaying = videoPlayerRef.current?.getState() === 'playing';
        return isPlaying ? 'Y' : 'N';
    }

    useEffect(()=> {
        const watchStart = new Date();
        dispatch(getWatermark());
        dispatch(getLive(param.id, isVideoPlaying()))
        const polling = setInterval(()=> {
            dispatch(getLive(param.id, isVideoPlaying()))
        }, Config.livePollingTime);
        return () => {
            dispatch(clearLive())
            clearInterval(polling);
            const watchEnd = new Date();
            ReactGA.send({
                hitType: 'timing',
                timingCategory: 'live_watch',
                timingVar: param.id,
                timingValue: watchEnd.getTime() - watchStart.getTime(),
            });
        }
    }, [location]);

    useEffect(()=> {
        if(live.data != null){
            const tokenUrl = {};
            const data = live.data?.data?._source;
            Object.keys(live.data?.data).filter(it => it.startsWith('tokenUrl')).forEach(key => tokenUrl[key] = live.data?.data?.[key]);
            if(live.data?.code === "82"){
                throw new MessageError("라이브 시청 권한이 없습니다.")
            }
            else if(data == null){
                throw new MessageError("라이브 정보를 찾을 수 없습니다.")
            }
            else {
                setIsFirstLoad(false);
                setState(new LiveModel({
                    ...data,
                    tokenUrl,
                }));
            }
        }
    }, [live.data])

    useEffect(()=> {
        setLiveState(state.state);
    }, [state.state]);

    useEffect(() => {
        switch (liveState) {
            case 'ready': {
                setShowPlayer(false);
                break;
            }
            case 'onair': {
                setShowPlayer(true);
                break;
            }
            case 'end' : {
                setShowPlayer(false);
                break;
            }
            default: {
                setShowPlayer(false);
                break;
            }
        }
    }, [liveState])

    useEffect(()=> {
        if(state.ownLive){
            // const token1080p = state.tokenUrl?.tokenUrl_myRecord_1080p;
            // const token720p = state.tokenUrl?.tokenUrl_myRecord_720p;
            // const token360p = state.tokenUrl?.tokenUrl_myRecord_360p;
            // let tokenParam = '';

            // const tokenUrl = data.tokenUrl[`tokenUrl_${videoSource.fileId}`];
            // if(state.tokenUrl_myRecord?.trim() != ""){
            //     tokenParam = `?token=${state.tokenUrl_myRecord}`;
            // }
            const option = {
                // image: state.poster,
                sources: [
                    {
                        label: 'FHD',
                        type: 'hls',
                        file: `${state.ownLive?.cdnUrl_1920}`,
                    },
                    {
                        label: 'HD',
                        type: 'hls',
                        file: `${state.ownLive?.cdnUrl_720}`,
                    },
                    {
                        label: 'SD',
                        type: 'hls',
                        file: `${state.ownLive?.cdnUrl_360}`,
                    },
                ],
            }
            console.log(option);
            setPlayerOption(option)
        }
    }, [state.ownLive])

    useEffect(()=> {
        if(showPlayer){
            videoPlayerRef.current?.initialize(playerOption);
        }
    }, [showPlayer])

    const {isLoading} = useLoadingModal([live], !isFirstLoad);

    if(isLoading && isFirstLoad){
        return "" // 로딩중
    }

    if(liveDetailAc === false){
        throw new MessageError("라이브 시청 권한이 없습니다.")
    }

    return <div className="inner">
        <article className="content__wrap">
            <article className="content__wrap">
                <div className="content_head mb_32">
                    <div className="head_title">
                        <div className="txt_head3 !max-w-max">{state.title}</div>
                    </div>
                    <div className="head__tool">
                        {/* <!-- <div class="btn btn-default btn-sm"><i class="icon gas-like"></i><span>찜하기</span></div> --> */}
                    </div>
                </div>
                {/*<div className="video__player__area">*/}
                {
                    showPlayer &&
                    <>
                        <FlexPlayer
                            ref={videoPlayerRef}
                            playerOption={playerOption}
                        />
                        <ChromecastButton
                            url={state.ownLive?.cdnUrl_1920}
                            onConnected={onChromeCastConnected}
                            onDisconnected={onChromeCastDisconnected}
                        />
                    </>
                }
                {
                    !showPlayer &&
                    <div
                        style={{
                            aspectRatio: 16 / 9,
                        }}
                    >
                        <BlurryPoster
                            posterImage={state.poster}
                        >
                            {
                                liveState === 'end' &&
                                <span className="sm:text-[48px] font-bold text-[26px] w-full h-full flex justify-center items-center live_text_border">
                                    생방송이 종료되었습니다.
                                </span>
                            }
                            {
                                liveState !== 'end' &&
                                <LiveCountdown liveStartTime={state.encStartDt}/>
                            }
                        </BlurryPoster>
                    </div>
                }
                <div className="video__edit__wrap mt_64">
                    <article className="game__info mb_64">
                        <div className="content_head mb_32">
                            <div className="head_title">
                                <div className="txt_head4">경기 정보</div>
                            </div>
                        </div>
                        <div className="div__table__area table__row__sub">
                            <div className="row">
                                <dl>
                                    <dt>경기날짜</dt>
                                    <dd><span>{state.videocontent?.match_dt}</span></dd>
                                </dl>
                                <dl>
                                    <dt>등록날짜</dt>
                                    <dd><span>{state.videocontent?.regDt}</span></dd>
                                </dl>
                            </div>
                            <div className="row">
                                <dl>
                                    <dt>리그명</dt>
                                    <dd><span>{state.videocontent?.ma_title}</span></dd>
                                </dl>
                                <dl>
                                    <dt>카테고리</dt>
                                    <dd><span>{state.videocontent?.category}</span></dd>
                                </dl>
                            </div>
                            <div className="row">
                                <dl>
                                    <dt>홈팀</dt>
                                    <dd><span>{state.videocontent?.team_home}</span></dd>
                                </dl>
                                <dl>
                                    <dt>원정팀</dt>
                                    <dd><span>{state.videocontent?.team_away}</span></dd>
                                </dl>
                            </div>
                            <div className="row">
                                <dl>
                                    <dt>제목</dt>
                                    <dd><span>{state.videocontent?.title}</span></dd>
                                </dl>
                            </div>
                        </div>
                    </article>
                    <article className="order__wrap order__twin">
                        <article className="order__item">
                            <Attachments
                                list={state?.attach}
                            />
                        </article>
                        <article className="order__item">
                            <Hashtags
                                list={state?.hash}
                            />
                        </article>
                    </article>
                </div>
            </article>
        </article>
    </div>
}