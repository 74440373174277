

const _apiBaseURl = process.env.REACT_APP_SSO_API_URL;
const _ssoAccountUrl = process.env.REACT_APP_SSO_BASE_URL;
const _ssoPortalUrl = process.env.REACT_APP_SSO_PORTAL_URL;
const _ssoSilentBaseUrl = process.env.REACT_APP_SSO_SILENT_BASE_URL;

export const KFASSOConfig = {
    apiBaseURl: _apiBaseURl,
    ssoBaseUrl: _ssoAccountUrl,
    authorizeUri: `${_ssoSilentBaseUrl}/auth/realms/kfa/protocol/openid-connect/auth`,
    clientId: process.env.REACT_APP_SSO_CLIENT_ID,
    response_type: 'code',
    scope: 'openid',

    // logout
    endSessionUri: `${_ssoSilentBaseUrl}/auth/realms/kfa/protocol/openid-connect/logout`,

    signUpUrl: `${_ssoPortalUrl}/kfa/jon/join.do`,
    integrateUrl: `${_ssoPortalUrl}/kfa/tsr/tuser.do`,
    passwordChangeUrl: `${_ssoPortalUrl}/kfa/pwc/pwChange.do`,
    infoChangeUrl: `${_ssoPortalUrl}/kfa/emi/updtUser.do`,
    resignUrl: `${_ssoPortalUrl}/kfa/sur/siteMagUserlist.do`,
    serviceList: `${_ssoPortalUrl}/kfa/sur/siteMagUserlist.do`,
    faqUrl: `${_ssoPortalUrl}/kfa/faq/nolog/FaqUserlist.do`,
}