import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import myPageMenu from "../../../resource/menus/mypage";
import {clearLogin} from "../../../modules/userReducer";
import {useDispatch, useSelector} from "react-redux";
import {ShowAlertModal} from "../../../common/component/modal/AlertModal";
import {ShowConfirmModal} from "../../../common/component/modal/ConfirmModal";
import useSSO from "../../../lib/useSSO";

const flattenObjectArr = (objList) => {
    const arr = [];

    for (let i = 0; i < objList.length; i ++) {
        let nextDepth = objList[i]['children'];
        arr.push(objList[i]);
        // 다음 depth가 있을때까지
        while (nextDepth !== undefined) {
            arr.push(...nextDepth);
            nextDepth = nextDepth['children'];
        }
    }
    console.log(arr)

    return arr;
}

export default function MyPageMenuWrapper() {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedMenu, setSelectedMenu] = useState(0);
    const dispatch = useDispatch();
    const loginData = useSelector(state => state.userReducer.loginData);
    const {isSSOUser, ssoLogout} = useSSO();

    useEffect(() => {
        const path = location.pathname
        const currentMenu = flattenObjectArr(myPageMenu).findIndex((menu) => menu.path === path);
        if (currentMenu === -1)
            navigate("/mypage/info", {replace: true})
        else setSelectedMenu(currentMenu);
    }, [location.pathname]);

    useEffect(() => {
        if (loginData == null) {
            ShowAlertModal({
                message: "로그인이 필요한 기능입니다.",
                onConfirm: () => {
                    navigate("/user/login", {state: {redirectUrl: location.pathname}})
                }
            });
        }
    },);

    const isActive = (idx) => idx === selectedMenu ? "active" : ""

    return loginData != null ? <div className="inner">
        <article className="content__wrap order__wrap">
            <div className="order__item gnb">
                <nav className="page__gnb__menu">
                    <ul>
                        {
                            myPageMenu.map(({icon, text, path, upload_ac, download_ac}, idx) => {
                                // 권한 없으면 표시 안함
                                if(upload_ac != null){
                                    if(!upload_ac.includes(loginData.upload_ac)){
                                        return '';
                                    }
                                }
                                if(download_ac != null){
                                    if(!download_ac.includes(loginData.download_ac)){
                                        return '';
                                    }
                                }

                                return (
                                    <li key={icon}>
                                        <Link className={isActive(idx)} to={path}>
                                            <i className={`icon gas-m-${icon}`}></i>{text}
                                        </Link>
                                    </li>
                                )
                            })
                        }
                        <li>
                            <a className={"cursor-pointer"}
                               onClick={() => {
                                   ShowConfirmModal({
                                       message: "정말로 로그아웃하시겠습니까?",
                                       onConfirm: async (ref) => {
                                           ref.current.hideModal()
                                           dispatch(clearLogin());
                                           navigate("/user/login")
                                       }
                                   })
                               }}>
                                <i className="icon gas-m-logout"></i>로그아웃
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className="order__item">
                <Outlet/>
            </div>
        </article>
    </div> : null;
}