import GalleryMainGridItem from "./GalleryMainGridItem";
import useGalleryMainGrid from "./useGalleryMainGrid";
import {useMemo, useState} from "react";
import {makePath, makeThumbnailUrl, scheme} from "../../../util/url";
import {CircularProgress} from "@mui/material";
import ItemsCarousel from "react-items-carousel/src";
import _ from "lodash";
import {Link} from "react-router-dom";
import GalleryModal2 from "../modal/GalleryModal2";
import {useSelector} from "react-redux";


const size = 4;
const totalPage = 3;

export default function GalleryMainGridCategoryItem({menu}) {

    const loginData = useSelector(state => state.userReducer.loginData);
    const {isLoading, list, hasError} = useGalleryMainGrid({menuId: menu.menuId, count: size * totalPage});
    const [activeItemIndex, setActiveItemIndex] = useState(0);

    const [currentImg, setCurrentImg] = useState(null); // modal current

    const showDialog = (img, index) => {
        setCurrentImg(index);
    }

    const _list = useMemo(() => {
        return list.map(it => ({
            thumbSrc: makeThumbnailUrl(it.imagecontent),
            src: makePath(scheme,it.file?.thumb_cdnUrl,it.imagecontent.thumb_path,it.file.posterFile),
            menuNm: it.imagecontent?.menuNm ?? '',
            title: it.imagecontent?.photo_info ?? '',
            ...it,
        }));
    }, [list]);

    const indicator = useMemo(() => {
        let target;
        if(activeItemIndex >= 0){
            target = activeItemIndex % totalPage;
        }
        else {
            target = (totalPage - Math.abs(activeItemIndex) % totalPage) % totalPage;
        }
        return Array.from({length: totalPage}, (_, i) => i === target);
    }, [activeItemIndex]);

    return (
        <div className="flex flex-col items-center justify-center">
            <Link
                className="txt_head4 mb-4"
                to={`?menu=${menu.menuUrl}`}>{menu.menuNm}
            </Link>
            {
                isLoading &&
                <div className="flex w-full h-full justify-center items-center">
                    <CircularProgress
                        style={{
                            color: '#E6002D'
                        }}
                    />
                </div>
            }

            {
                hasError &&
                <div className="flex w-full h-full justify-center items-center">
                    <div>오류가 발생하였습니다.</div>
                </div>
            }

            <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={1}
                infiniteLoop={true}
                classes={{
                    wrapper: 'wrapper w-full aspect-video',
                    itemsWrapper: 'itemsWrapper w-full !h-full',
                    itemsInnerWrapper: 'itemsInnerWrapper flex flex-wrap',
                    itemWrapper: 'itemWrapper w-[50%] h-[50%]',
                }}
                leftChevron={<button><img src="/image/asset/icon/slide_prev.svg" alt="prev"/></button>}
                rightChevron={<button><img src="/image/asset/icon/slide_next.svg" alt="next"/></button>}
                outsideChevron={true}
                chevronWidth={30}
            >

                {
                    !hasError && !isLoading &&
                    _.chunk(_list, 4).map((it, page) => <div className="aspect-video flex flex-wrap">
                        {
                            it.map((it, index) =>
                                <GalleryMainGridItem
                                    index={index + page * size}
                                    item={it}
                                    showDialog={showDialog}
                                />)
                        }
                    </div>)
                }
            </ItemsCarousel>
            {
                !hasError && !isLoading &&
                <div className="flex gap-2 mt-4">
                    {
                        indicator.map(it =>
                            <div
                                style={{
                                    backgroundColor: it ? 'red': 'white',
                                    borderRadius: '100%',
                                    width: '15px',
                                    aspectRatio: 1,
                                }}
                            />
                        )
                    }
                </div>
            }
            {
                currentImg != null &&
                <GalleryModal2
                    user={loginData}
                    index={currentImg}
                    list={_list}
                    onClose={e => setCurrentImg(null)}
                />
            }
        </div>
    )
}