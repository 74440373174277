import {Link, useLocation} from "react-router-dom";
import WhiteLogo from "../../../resource/img/asset/logo/logo_white.png";
import axios from 'axios';
import classNames from "classnames";
import {createPortal} from "react-dom";
import {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {clearTotalSearch, getTotalSearch} from "../../../modules/totalSearchReducer";
import {useLoadingModal} from "../LoadingState";
import PreviewList from "../../../page/home/component/previewList";
import {sum} from "lodash";
import GalleryModal2 from "./GalleryModal2";
import {getHotHashtagList} from "../../../modules/hotHashtagReducer";
import {deleteRecentKeywords, fetchRecentKeywords, saveRecentKeyword} from "../../../util/storageUtil";
import {makeSearchKeywords} from "../../../util/BoardSearchQueryUtil";
import {Collapse} from "@mui/material";
import {getPlayerList} from "../../../apis/api/player";

const WholeSearchModal = ({onClose}) => {
    const container = document.querySelector('.whole-search-modal-portal');
    return createPortal(<WholeSearchModalContent onClose={onClose}/>, container)
};

const WholeSearchModalContent = ({onClose}) => {
    const dispatch = useDispatch();
    const totalSearchResult = useSelector(state => state.totalSearchReducer.searchResult);
    const searchKeyword = useSelector(state => state.totalSearchReducer.keyword);
    const loginData = useSelector(state => state.userReducer.loginData);
    const isLoading = useLoadingModal([totalSearchResult]);
    const location = useLocation();
    const [locationKey, setLocationKey] = useState(location.key);
    const [totalCount, setTotalCount] = useState(0);
    const [imageList, setImageList] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState();
    const [list, setList] = useState([]);
    // const [searchFocus, setSearchFocus] = useState(true);
    const hotHashtagList = useSelector(state => state.hotHashtagReducer.hotHashtagList);
    const [keywords, setKeywords] = useState([]);
    const [shouldUpdateRecentKeywords, setShouldUpdateRecentKeywords] = useState(false);

    useEffect(() => {
        if (shouldUpdateRecentKeywords) {
            setKeywords(fetchRecentKeywords());
            setShouldUpdateRecentKeywords(false);
        }
    }, [shouldUpdateRecentKeywords]);

    useEffect(() => {
        if (locationKey !== location.key) {
            onClose();
        }
    }, [location]);

    useEffect(() => {
        dispatch(getHotHashtagList());
        setKeywords(fetchRecentKeywords());
        return () => {
            dispatch(clearTotalSearch());
        }
    }, []);

    useEffect(() => {
        if (totalSearchResult.loading) {

        } else if (totalSearchResult.error) {
            console.error(totalSearchResult.error);
        } else if (totalSearchResult.data) {
            console.log(totalSearchResult.data);
            const counts = Object.keys(totalSearchResult.data).map(it => {
                return totalSearchResult.data[it].count
            });
            setTotalCount(sum(counts));
            let newList = {
                ...totalSearchResult.data,
                ['갤러리']: {
                    ...totalSearchResult.data['갤러리'],
                    list: totalSearchResult.data['갤러리'].list.map((it, index) => ({
                        ...it,
                        action: (e) => {
                            e?.preventDefault();
                            setCurrentImageIndex(index);
                        }
                    }))
                }
            }
            // newList['갤러리'].list =
            setList(newList)
            setImageList(newList['갤러리'].orgList)
        }
    }, [totalSearchResult])

    const handleSearch = (keywords) => {
        if(keywords[0].includes('#')){
            dispatch(getTotalSearch(null, keywords[0].substring(1)));
        } else {
            saveRecentKeyword(keywords);
            dispatch(getTotalSearch(keywords));
            setShouldUpdateRecentKeywords(true);
        }
    }

    return <>
        <div className="modal-whole-search-wrap">
            <div
                className={classNames({
                    'modal-dialog': true,
                    'overflow-y-auto': true,
                })}
            >
                <div className="content-area">
                    <div className="modal-head">
                        <Link to={"/"} className="logo"><img className="w-full h-[56px] object-contain"  src={WhiteLogo}/></Link>
                        <div className="close" onClick={e => onClose?.()}><i className="icon gas-close"></i></div>
                    </div>
                    <div className="modal-body">
                        <div className="search__todo__area"
                            // onFocus={() => setSearchFocus(true)}
                            // onBlur={() => setSearchFocus(false)}
                        >
                            <SearchBox onSearch={handleSearch}/>
                            <SearchKeywordRecommendation
                                focus={true} // 검색결과 항목 클릭 미스 문제로 영역 숨기기, 애니메이션 비활성화
                                hotHashtagList={
                                    hotHashtagList?.data?.data.hits.hits.map(value => value._source.hothashtag[0])
                                    ?? []}
                                keywords={keywords}
                                onSearch={handleSearch}
                                onRecentKeywordsChange={() => {
                                    setShouldUpdateRecentKeywords(true)
                                }}
                            />
                            <SearchResult keyword={searchKeyword?.join(" ")} cnt={totalCount}/>

                            <div className="search__content" style={{width: '100%'}}>
                                {
                                    list != null &&
                                    Object.keys(list).map(it => {
                                        const data = list[it];
                                        if(data?.list?.length === 0){
                                            return ''
                                        }
                                        return (
                                            <div
                                                key={it}>
                                                <PreviewList
                                                    item={data}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
            currentImageIndex != null &&
            <GalleryModal2
                list={imageList}
                index={currentImageIndex}
                user={loginData}
                onClose={e => setCurrentImageIndex(null)}
            />
        }
    </>
}

const PlayerSuggestList = ({keywords, onKeywordChange}) => {

    const [keywordResultMap, setKeywordResultMap] = useState({});
    const orderedKeyList = useMemo(() => {
        let list = Object.keys(keywordResultMap);
        list.sort((a, b) => a.localeCompare(b));
        return list;
    }, [keywordResultMap]);
    const show = useMemo(() => Object.keys(keywordResultMap).find(key => keywordResultMap[key]?.list.filter(it => it !== key.replace(/_/g, ' ')).length > 0) != null, [keywordResultMap]);

    const fetch = async (keywords) => {
        // 키워드가 없어지면 해당 요청을 취소하고 목록에서 제거
        let oldKeys = Object.keys(keywordResultMap);
        for(let i in oldKeys) {
            let key = oldKeys[i];
            let keep = keywords.includes(key);
            if(!keep) {
                keywordResultMap[key]?.cancel?.cancel('text change');
                setKeywordResultMap(p => {
                    let n = {...p};
                    delete n[key];
                    console.log(n);
                    return n;
                })
            }
        }

        for(let i in keywords) {
            let keyword = keywords[i];
            let data = keywordResultMap[keyword];
            if(data == null || data?.list?.length === 0) {
                let cancel = axios.CancelToken.source();
                setKeywordResultMap(p => ({
                    ...p,
                    [keyword]: {
                        cancel,
                        list: [],
                    }
                }))
                try{
                    let res = await getPlayerList([keyword.replace(/_/g, ' ')], cancel.token);
                    let list = res.data?.hits?.hits?.map(it => it._source?.playerkey);
                    setKeywordResultMap(p => ({
                        ...p,
                        [keyword]: {
                            list,
                        }
                    }))
                }
                catch(e) {
                    // cancel ignore
                    // console.error(e);
                }
            }
        }
    }

    useEffect(() => {
        fetch(keywords);
    }, [keywords]);

    const onItemClick = (key, item) => {
        let index = keywords.indexOf(key);
        if(index > -1) {
            let newList = [...keywords];
            newList[index] = item.replace(/ /g, '_');
            onKeywordChange(newList);
        }
    }

    return (
        <Collapse in={show}>
            <div className="search_support_area w-full flex items-start gap-2">
                <div className="txt_sub txt_head5 flex-shrink-0">동명 선수 : </div>
                <div className="w-full flex gap-2 flex-shrink-0 flex-wrap mt-1">
                    {
                        orderedKeyList.map(key => {
                            let list = keywordResultMap[key]?.list;
                            // 동명이인이 없는 경우, 본인 이름, 본인이름(생년) 2개만 조회 됨.
                            list = list.filter(it => it !== key.replace(/_/g, ' '));
                            list.sort((a, b) => a.localeCompare(b));
                            if(list == null || list.length === 0) {
                                return ''
                            }
                            return <>{
                                list.map(it => <span className="flex-shrink-0 cursor-pointer" onClick={() => onItemClick(key, it)}>{it.replace(/ /g, "_")}</span>)
                            }</>
                        })
                    }
                </div>
            </div>
        </Collapse>
    )
}

const SearchBox = ({onSearch}) => {
    const [keyword, setKeyword] = useState('');
    const [searchKeywords, setSearchKeywords] = useState([]);

    const search = (keywords) => {
        setSearchKeywords(keywords);
        onSearch(keywords.map(it => it.replace(/_/g, ' ')));
        setKeyword(keywords.join(" "));
    }

    const addKeyword = (keyword) => {
        const keywords = makeSearchKeywords(keyword);
        search(keywords);
    }

    return <div className="w-full flex flex-col">
        <div className="search__inp">
            <input type="text" placeholder="검색어를 입력해 주세요"
                   value={keyword}
                   onChange={e => setKeyword(e.target.value)}
                   onKeyDown={({key, nativeEvent}) => {
                       if (!nativeEvent.isComposing && key === 'Enter' && keyword !== null && keyword?.trim() !== "") {
                           addKeyword(keyword);
                       }
                   }}
            />
            <button
                className="searchBtn"
                onClick={e => {
                    if (keyword !== null && keyword?.trim() !== "") addKeyword(keyword)
                }}>
                <i className="icon gas-search-big"/>
            </button>
        </div>
        <div className="mt-3"/>
        <PlayerSuggestList
            keywords={searchKeywords}
            onKeywordChange={search}
        />
    </div>
}

const recommendationStyle = (focus) => {
    return {
        maxHeight: focus ? '500px' : '0px',
        overflow: 'hidden',
        transitionDuration: '0.8s',
        transitionProperty: 'max-height'
    };
}

const SearchKeywordRecommendation = ({focus, hotHashtagList, keywords, onSearch, onRecentKeywordsChange}) => {
    return <article className="search__item" style={recommendationStyle(focus)}>
        <div className="order__wrap order__twin mt_64">
            <RecentSearchKeywords keywords={keywords} onSearch={onSearch}
                                  onRecentKeywordsChange={onRecentKeywordsChange}/>
            <HotHashtagList hotHashtagList={hotHashtagList} onSearch={onSearch}/>
        </div>
    </article>;
}

const RecentSearchKeywords = ({keywords, onSearch, onRecentKeywordsChange}) => {
    return <div className="order__item">
        <div className="todo__inner">
            <div className="txt_sub txt_head5">최근 검색어</div>
            <ul className="todo__list">
                {
                    keywords?.map((keyword, index) => <li key={index}><span onClick={() => {
                        const keywordList = makeSearchKeywords(keyword);
                        onSearch(keywordList)
                    }}>{keyword}</span>
                        <button onClick={() => {
                            deleteRecentKeywords(keyword)
                            onRecentKeywordsChange();
                        }
                        }/>
                    </li>)
                }
            </ul>
        </div>
    </div>;
}

const HotHashtagList = ({hotHashtagList, onSearch}) => {
    const onClickHotHashtag = (hashtag) => {
        onSearch([hashtag]);
    }

    return <div className="order__item">
        <div className="hash__inner">
            <div className="txt_sub txt_head5">인기 해시태그</div>
            <ul className="hash__list">
                {
                    hotHashtagList?.map((hashtag, index) => <li key={index} onClick={() => {onClickHotHashtag(`#${hashtag.hashNm}`)}}>
                        <span>{`${index + 1}. #${hashtag.hashNm}`}</span></li>)
                }
            </ul>
        </div>
    </div>
}

const SearchResult = ({keyword, cnt}) =>
    <div className="search__result">
        {
            cnt != null && cnt > 0 &&
            <div className="resultTxt">
                <span className="keyword">{keyword}</span>
                <span>검색결과가 {cnt}개 있습니다.</span>
            </div>
        }
        {
            (cnt == null || cnt == 0) &&
            <div className="resultTxt">
                <span className="keyword">{keyword}</span>
                <span>검색 결과가 없습니다</span>
            </div>
        }
    </div>

export default WholeSearchModal;