import axios from 'axios';
import {getAuthKey} from "../../util/storageUtil";
import {loginRefresh} from "../api/user";
import {debounceFunction} from "../../lib/util";

export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const SEARCH_ENGINE_API_BASE_URL = process.env.REACT_APP_SEARCH_ENGINE_API_URL;

// 모든 API 요청 이후 5초뒤 토큰 갱신
let tokenRefreshDebounce = debounceFunction(async ()=> {
    await loginRefresh();
    console.debug('token refresh');
}, 5 * 1000);


export const apiInstance =  axios.create({
    baseURL: API_BASE_URL,
    timeout: 10000,
    paramsSerializer: (paramObj) => {
        const params = new URLSearchParams()
        for (const key in paramObj) {
            if(paramObj[key] !== undefined){
                params.append(key, paramObj[key])
            }
        }

        return params.toString()
    },
});

export const searchEngineApiInstance =  axios.create({
    baseURL: SEARCH_ENGINE_API_BASE_URL,
    timeout: 10000,
    paramsSerializer: (paramObj) => {
        const params = new URLSearchParams()
        for (const key in paramObj) {
            if(paramObj[key] !== undefined){
                params.append(key, paramObj[key])
            }
        }

        return params.toString()
    },
});

apiInstance.interceptors.response.use(
    function (response) {
        // console.log(response);
        return response
    },
    function (error) {
        console.log(error?.response?.data);
        if(error?.response?.data === "만료된 JWT 입니다."){
            return window.location.href = '/login?reason=expired'
        }
        else if(error?.response?.data === "유효하지 않은 토큰입니다."){
            return window.location.href = '/login'
        }
        throw error;
    }
);

apiInstance.interceptors.request.use(
    function (config) {
        // console.log('config', config);
        config.headers['authKey'] = getAuthKey();
        config.headers['Content-Type'] = "application/json"
        if(!config.url.startsWith('/api/cms/user/getUserInfobyAuthKey')){
            tokenRefreshDebounce();
        }
        return config;
    },
);

searchEngineApiInstance.interceptors.request.use(
    function (config) {
        // console.log('config', config);
        config.headers['authKey'] = getAuthKey();
        if(!config.url.startsWith('/api/cms/user/getUserInfobyAuthKey')){
            tokenRefreshDebounce();
        }
        return config;
    },
);