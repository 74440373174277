import classNames from "classnames";
import {useEffect, useState} from "react";
import TimePicker from 'react-time-picker';
import {mmssToSec, secToHHmmss, secToMmss} from "../../../../lib/videoOffsetCalcUtil";
import {ShowAlertModal} from "../../../../common/component/modal/AlertModal";


export class PartialDownloadState{
    constructor({
                    sourceType,
                    uid,
                    video_cid,
                    video_fileId,
                    fileTitle,
                    startPos,
                    endPos,
                    duration,
                } = {}) {
        this.sourceType = sourceType;
        this.uid = uid;
        this.video_cid = video_cid;
        this.video_fileId = video_fileId;
        this.fileTitle = fileTitle;
        this.startPos = startPos;
        this.endPos = endPos;
        this.duration = duration;
    }

}

export default function PartialDownloadTable({videoPlayerRef, initialState, onSubmit}) {

    const [now, setNow] = useState(new Date().format('yyyyMMdd_HHmmss'));
    const [isOpen, setIsOpen] = useState(false);
    const [state, setState] = useState(null);
    const [startPos, setStartPos] = useState('00:00:00');
    const [endPos, setEndPos] = useState('00:00:00');
    const [duration, setDuration] = useState('-');

    useEffect(() => {
        try{
            console.log('change duration');
            let start = new Date(`2000-01-01 ${startPos}`)
            let end = new Date(`2000-01-01 ${endPos}`)
            let diff = end.getTime() - start.getTime();
            if(diff < 0){
                setDuration('-');
            }
            else {
                setDuration(secToMmss(diff/1000));
            }
        }
        catch (e) {
            console.error(e);
        }
    }, [startPos, endPos])

    useEffect(() => {
        if(initialState != null){
            setState({
                ...initialState,
                fileTitle: `${initialState?.fileTitle?.replace('.mp4', '')}_${startPos}_${endPos}_${now}.mp4`
            });
        }
    }, [initialState])

    useEffect(() => {
        setState(s => ({
            ...s,
            fileTitle: `${initialState?.fileTitle?.replace('.mp4', '')}_${startPos}_${endPos}_${now}.mp4`
        }))
    }, [startPos, endPos])

    const handlePartialDownload = () => {
        let start = mmssToSec(startPos);
        let end = mmssToSec(endPos);
        if(startPos == null || endPos == null || (startPos >= endPos) ){
            ShowAlertModal({
                message: '요청 구간을 확인해주세요'
            })
            return;
        }
        onSubmit?.(new PartialDownloadState({
            ...initialState,
            fileTitle: state.fileTitle.replace(".mp4", ""),
            startPos: startPos,
            endPos: endPos,
            duration: mmssToSec(duration),
        }))
    }

    const getCurrentTimeToStart = () => {
        const sec = videoPlayerRef.current?.getCurrentPlayTime();
        setStartPos(secToHHmmss(sec))
    }

    const getCurrentTimeToEnd = () => {
        const sec = videoPlayerRef.current?.getCurrentPlayTime();
        setEndPos(secToHHmmss(sec))
    }

    if(state == null){
        return '';
    }

    return (
        <article className="edit_item mb_64">
            <div className="content_head mb_32">
                <div className="head_title">
                    <div className="txt_head4">구간 다운로드 요청</div>
                </div>
                <div className="head__tool">
                    {
                        !isOpen &&
                        <div
                            className="btn btn-default btn-tiny"
                            onClick={e => setIsOpen(true)}
                        >
                            <span>구간선택</span>
                        </div>
                    }
                    {
                        isOpen &&
                        <div
                            className="btn btn-default btn-tiny"
                            onClick={e => setIsOpen(false)}
                        >
                            <span>구간선택</span>
                            <i className="icon gas-minus"></i>
                        </div>
                    }
                </div>
            </div>
            {
                isOpen &&
                <div className="div__table__area">
                    <div className="row">
                        <dl>
                            <dt className="th_sd">시작점</dt>
                            <dd>
                                <div className="timer__inp" id="timer_start">
                                    {/*<input type="" name="" className="timer__inp" value="00:00:00"/>*/}
                                    <TimePicker
                                        className={"timer__inp"}
                                        format={"HH:mm:ss"}
                                        onChange={setStartPos}
                                        value={startPos}
                                        maxDetail={'second'}
                                        disableClock={true}
                                        clearIcon={null}
                                    />
                                    <button className="ml-1" onClick={getCurrentTimeToStart}>
                                        <i className="fa-solid fa-clock"></i>
                                    </button>
                                    {/*<div className="btn-spinner-up"></div>*/}
                                    {/*<div className="btn-spinner-down"></div>*/}
                                </div>
                            </dd>
                        </dl>
                        <dl>
                            <dt className="th_sd">종료점</dt>
                            <dd>
                                <div className="timer__inp" id="timer_end">
                                    <TimePicker
                                        className={"timer__inp"}
                                        format={"HH:mm:ss"}
                                        onChange={setEndPos}
                                        value={endPos}
                                        maxDetail={'second'}
                                        disableClock={true}
                                        clearIcon={null}
                                    />
                                    <button className="ml-1"  onClick={getCurrentTimeToEnd}>
                                        <i className="fa-solid fa-clock"></i>
                                    </button>
                                    {/*<input type="" name="" className="timer__inp" value="00:00:00"/>*/}
                                    {/*<div className="btn-spinner-up"></div>*/}
                                    {/*<div className="btn-spinner-down"></div>*/}
                                </div>
                            </dd>
                        </dl>
                        <dl>
                            <dt className="th_sd">영상길이</dt>
                            <dd>
                                <div className="txt_time">
                                    <i className="icon gas-timer2"></i>
                                    <span>{duration}</span>
                                </div>
                            </dd>
                        </dl>
                    </div>
                    <div className="row">
                        <dl>
                            <dt className="th_sd">제목</dt>
                            <dd>
                                <input
                                    type="" name=""
                                    value={state.fileTitle}
                                    onChange={e => setState(s => ({...s, fileTitle: e.target.value}))}
                                />
                                <a className="btn btn-sm" onClick={handlePartialDownload}>다운로드 요청</a>
                            </dd>
                        </dl>
                    </div>
                </div>
            }
        </article>
    )
}