import {useEffect, useMemo, useState} from "react";
import {ShowSearchModal} from "../../../common/component/modal/serachModal";
import {getAsFlat} from "../../../lib/menu/menuUtil";
import {useSelector} from "react-redux";
import {makeSearchKeywords} from "../../../util/BoardSearchQueryUtil";

/**
 * @param key currentMenu.menuId와 같이 route가 바뀌면서 변하는 값을 넣어 페이지가 변해도 필터가 유지되는 것을 막아줍니다.
 * @param {SearchParam} initialParam 추후 route에서 param state를 가져오는 것을 구현하기 위해 만들었습니다.
 * @param onParamChanged
 * @param {boolean} detail 상세검색 모드인지?
 * @param {Array<Menu>} menus
 * @return {JSX.Element}
 * @constructor
 */
const EventBoardSearchBox = ({currMenu, initialParam, onParamChanged, menus, showingLeagueParent}) => {
    const [keywordInput, setKeywordInput] = useState("");
    const [showingLeague, setShowingLeague] = useState([]);
    const [showingLeague2, setShowingLeague2] = useState([]);
    const {data: menuData} = useSelector(state => state.menuReducer.menus);
    const loginData = useSelector(state => state.userReducer.loginData);
    const userType = useMemo(() => {
        return loginData?.userType
    }, [loginData])

    useEffect(() => {
        if (initialParam?.league != null) {
            const flatMenu = getAsFlat(menus);
            setShowingLeague([...initialParam.league].map(league => flatMenu.find(it => it.menuId === league)));
        } else setShowingLeague([]);
        if (initialParam?.league2 != null) {
            const flatMenu = getAsFlat(menuData);
            setShowingLeague2([...initialParam.league2].map(league => flatMenu.find(it => it.menuId === league)));
        } else setShowingLeague([]);
    }, [initialParam])

    const addKeyword = (keyword) => {
        // 추가할 키워드가 있는 경우 localParam의 keywords에 추가 (useEffect에 의해 자동 reload)
        if (keyword !== "") {
            const keywordList = makeSearchKeywords(keyword);
            onParamChanged({
                ...initialParam,
                keyword: new Set([...(initialParam?.keyword ?? []), ...keywordList]),
            })
            setKeywordInput("");
            // 그 외에는 이벤트 emit (검색버튼 동작을 위함)
        }
    }

    const onRemoved = (field, id) => () => {
        initialParam?.[field].delete(id);
        onParamChanged(initialParam);
    }

    useEffect(() => {
        setKeywordInput("");
    }, [currMenu]);

    const createMenuCodeTree = () => {
        return [
            {
                menuId: 34,
                menuNm: '이벤트기록',
                itemList: menus,
            },
            {
                menuId: 35,
                menuNm: '기타기록',
                itemList: [ // itemList가 없으면 회색 글씨로 나옴.
                    {
                        menuId: '',
                        menuNm: '',
                    }
                ]
            }
        ];
    }

    return <article className="search__wrap mb_64">
        <div className="search__field__group">
            <div className="item__gorup space">
                <div className="search__inp item">
                    <input
                        type="text"
                        placeholder="리스트 내 검색"
                        value={keywordInput}
                        onChange={({target}) => setKeywordInput(target.value)}
                        onKeyDown={({key, nativeEvent}) => {
                            if (!nativeEvent.isComposing && key === "Enter" && keywordInput !== '') {
                                addKeyword(keywordInput);
                            }
                        }}
                    />
                    {
                        <button className="searchBtn" onClick={() => {
                            if (keywordInput !== '') {
                                addKeyword(keywordInput);
                            }
                        }}><i className="icon gas-search"></i></button>
                    }

                </div>
                {
                    menus &&
                    <div
                        className="btn btn-default btn-lg"
                        onClick={
                            () => {
                                ShowSearchModal({
                                        ...initialParam,
                                        league: new Set([...initialParam.league, ...initialParam.leagueParent]),
                                    }, (modalRef, params) => {
                                        modalRef.current.hideModal();
                                        const res = {
                                            ...initialParam,
                                            keyword: new Set(params.keyword),
                                            year: new Set(params.year),
                                            league: new Set([...params.league].filter(it => ((it !== 34) && (it !== 35)))),
                                            league2: new Set(params.league2),
                                            leagueParent: new Set([...params.league].filter(it => ((it === 34) || (it === 35)))),
                                        };
                                        console.log("Finally, SearchModal returned: ", params, res);
                                        onParamChanged(res)
                                    },
                                    () => '',
                                    userType,
                                    null,
                                    createMenuCodeTree(),
                                    true,
                                    false,
                                    menuData[1].children.map(it => ({...it, itemList: it.children}))
                                )
                            }
                        }
                    >상세검색</div>
                }
            </div>

        </div>
        <hr className={"mt_16 mb_16"}/>
        <div className="search__tag__area">
            <div className="u_label" onClick={() => onParamChanged({
                keyword: new Set(),
                year: new Set(),
                league: new Set(),
            })}>전체삭제
            </div>
            <div className="search__tag">
                {
                    [...(initialParam?.keyword ?? [])].map((keyword) => <>
                            <div className="text">{keyword}</div>
                            <div className="delete mr-4" onClick={onRemoved('keyword', keyword)}><i
                                className="icon gas-del-circle"></i></div>
                        </>
                    )
                }

                {
                    [...(showingLeague2 ?? [])].map((league) => <>
                            <div className="text">{league?.menuNm}</div>
                            <div className="delete mr-4" onClick={onRemoved('league2', league.menuId)}><i
                                className="icon gas-del-circle"></i></div>
                        </>
                    )
                }
                {
                    [...(showingLeagueParent ?? [])].map((leagueParent) => <>
                            <div className="text">{leagueParent.menuNm}</div>
                            <div className="delete mr-4" onClick={onRemoved('leagueParent', leagueParent.menuId)}><i
                                className="icon gas-del-circle"></i></div>
                        </>
                    )
                }
                {
                    [...(showingLeague ?? [])].map((league) => <>
                            <div className="text">{league?.menuNm}</div>
                            <div className="delete mr-4" onClick={onRemoved('league', league.menuId)}><i
                                className="icon gas-del-circle"></i></div>
                        </>
                    )
                }

            </div>
        </div>
    </article>
}

export default EventBoardSearchBox