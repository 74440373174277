import PreviewGridItem from "../../common/component/previewItem/PreviewGridItem";
import {useLocation, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {findMenuWithDfs, getAllChildren, getAsFlat} from "../../lib/menu/menuUtil";
import {useEffect, useMemo, useState} from "react";
import {useLoadingModal} from "../../common/component/LoadingState";
import {searchVideoBoard} from "../../modules/videoBoardReducer";
import VideoContentItem from "../../apis/dto/VideoContentItem";
import PreviewItemModel from "../../common/component/previewItem/model";
import PageIndicator from "../../common/component/PageIndicator";
import {VideoSearchParam} from "../../apis/dto/SearchParam";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import PreviewListItem from "../../common/component/previewItem/PreviewListItem";
import classNames from "classnames";
import BoardSearchBox from "./component/BoardSearchBox";
import FlexFillCenter from "../../common/component/FlexFillCenter";
import NotFound from "../../common/component/NotFound";
import queryString from "query-string";
import {LabelCombobox2} from "../../common/component/LabelCombobox2";

export default function VideoBoard({menu}) {
    const pageSize = 8;
    const dispatch = useDispatch();
    const loginData = useSelector(state => state.userReducer.loginData);
    const menus = useSelector(state => state.menuReducer.menus);
    const searchList = useSelector(state => state.videoBoardReducer.videoBoardSearchResult);
    const location = useLocation();
    const [locationSearchParam, setLocationSearchParam] = useSearchParams();
    const [showList, setShowList] = useState([]);
    const [searchParam, setSearchParam] = useState(null);
    const [styleTabIndex, setStyleTabIndex] = useState(0);

    const isGamePlayCategory = useMemo(() => {
        return  menu.matchMappingYN === 'Y'
    }, [menu])

    useLoadingModal([menus, searchList])

    useEffect(() => {
        if (searchParam != null) {
            dispatch(searchVideoBoard(searchParam))
        }
    }, [searchParam])

    useEffect(() => {
        if (location.search) {
            const query = queryString.parse(location.search);
            let param = VideoSearchParam.fromHistory(query, menus.data, copyrightAc(), canShareYn());
            if (param.page == null || isNaN(param.page)) { // 전체검색애서 keyword만 들고 넘어온 경우
                const children = getAllChildren(menu);
                param = new VideoSearchParam({
                    ...param,
                    page: 0,
                    size: pageSize,
                    menuIds: menu?.teamMenuYN === 'Y' ? [...menu?.memucode?.map(it => it.code4)] : [...children.map(it => it.menuId), menu.menuId],
                    copyright: copyrightAc(),
                    shareYn: shareYn(param.shareYn),
                })
            }
            setSearchParam(param);
        } else initState();
    }, [location.search]);

    useEffect(() => {
        if(!menus.data || !searchList) return;

        const menuList = getAsFlat(menus.data);
        const list = searchList.data?.data?.hits?.hits.map(it => new VideoContentItem(it._source));

        if(isGamePlayCategory){
            setShowList(
                list?.map(it => PreviewItemModel.fromGamePlayVideoContentItem(menuList, it))
            )
        }
        else {
            setShowList(
                list?.map(it => PreviewItemModel.fromVideoContentItem(menuList, it))
            )
        }
    }, [searchList.data, menus.data])

    const initState = () => {
        // SearchParam 비었을 때.
        const children = getAllChildren(menu);
        let param = new VideoSearchParam({
            page: 0,
            size: pageSize,
            menuIds: menu?.teamMenuYN === 'Y' ? [...menu?.memucode?.map(it => it.code4)] : [...children.map(it => it.menuId), menu.menuId],
            copyright: copyrightAc(),
        })
        if (menu.menuId === 5) {
            param = param.toRegDtDesc()
        }
        _setSearchParam(param, {replace: true});
    }

    /**
     * @param param
     * @param options React-Router-Dom 의 navigation option 입니다.
     * @return {JSX.Element}
     * @private
     */
    const _setSearchParam = (param, options) => {
        setLocationSearchParam(param.toHistory(), options)
    }

    const copyrightAc = () => {
        return loginData?.copyright_list_ac === 1 ? null : 'Y';
    }

    const canShareYn = () => {
        return loginData?.userType === 'M';
    }

    const shareYn = (old) => {
        return canShareYn() && old === 'Y' ? 'Y': null;
    }

    const setPage = (page) => {
        _setSearchParam(new VideoSearchParam({
            ...searchParam,
            page: page,
            menus: menus.data,
        }))
    }

    const handleParamChange = (newParam) => {
        if (newParam != null)
            _setSearchParam(new VideoSearchParam({
                ...newParam,
                menuIds: searchParam?.menuIds,
                page: 0,
                menus: menus.data,
            }))
    }

    const handleSortChange = (e) => {
        window.sort = e;
        console.log('handleSortChange', searchParam);
        _setSearchParam(new VideoSearchParam({
            ...searchParam,
            recType: window.recType,
            sortTarget: e?.split(' ')?.[0] ?? 'matchDt',
            sort: e?.split(' ')?.[1] ?? 'desc',
        }))
    }

    const handleVideoTypeFilterChange = (e) => {
        window.recType = e;
        console.log('handleVideoTypeFilterChange', searchParam);
        _setSearchParam(new VideoSearchParam({
            ...searchParam,
            recType: e,
            sortTarget: window.sort?.split(' ')?.[0] ?? 'matchDt',
            sort: window.sort?.split(' ')?.[1] ?? 'desc',
        }))
    }


    const getTitle = () => {
        const league = getLeague();
        const menuName = findMenuWithDfs(menu, Number(league))?.menuNm;

        return menuName ?? menu.menuNm;
    }

    const getLeague = () => {
        const query = queryString.parse(location.search);

        if(!query.league || query.league instanceof Array) return null;
        return query.league;
    }

    return (
        <>
            <div className="inner">
                <BoardSearchBox
                    currMenu={menu.menuId}
                    initialParam={searchParam}
                    onParamChanged={handleParamChange}
                    detail
                    id={menu.menuId}
                    menus={getAllChildren(menu)}
                    menuComboboxTransformer={(menus) => getAsFlat(menus.data).filter(menu => menu.contentType === "video" && menu.depth !== 1)}
                />
                <article className="content__wrap mt_64">
                    <Tabs
                        forceRenderTabPanel={true}
                        onSelect={setStyleTabIndex}
                    >
                        <article className="content__tab__area mb_24">
                            <div className="content_head">
                                <div className="head_title">
                                    <div className="txt_head2">{getTitle()}</div>
                                    <div
                                        className="txt_head6 txt_sub">{searchList?.data?.data?.hits?.total?.value}</div>
                                </div>
                                <TabList>
                                    <div className="head__tool">
                                        <LabelCombobox2
                                            className={`${menu.menuId}_recType`}
                                            selectValue={searchParam?.recType ?? ''}
                                            comboboxData={VideoSearchParam.videoTypeFilterList}
                                            onChange={handleVideoTypeFilterChange}
                                        />
                                        <LabelCombobox2
                                            className={`${menu.menuId}_sort`}
                                            selectValue={`${searchParam?.sortTarget ?? 'matchDt'} ${searchParam?.sort ?? 'desc'}`}
                                            comboboxData={VideoSearchParam.videoOrderList}
                                            onChange={handleSortChange}
                                        />
                                        <Tab>
                                            <div
                                                className={classNames({
                                                    "btn btn-revers btn-lg": true,
                                                    "active": styleTabIndex === 0,
                                                })}
                                            >
                                                <i className="icon gas-block"></i>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div
                                                className={classNames({
                                                    "btn btn-revers btn-lg": true,
                                                    "active": styleTabIndex === 1,
                                                })}
                                            >
                                                <i className="icon gas-buger"></i>
                                            </div>
                                        </Tab>
                                    </div>
                                </TabList>
                            </div>
                        </article>
                        <TabPanel>
                            <article className="game__area mt_48">
                                <div className={classNames({
                                    'block__list': true,
                                    '!h-full': showList?.length > 0,
                                })}>
                                    {
                                        (showList?.length ?? 0) === 0 &&
                                        <FlexFillCenter children={<NotFound message={'검색 결과가 없습니다.'}/>}/>
                                    }
                                    <ul>
                                        {
                                            showList?.map(data =>
                                                <li>
                                                    <PreviewGridItem
                                                        {...data}
                                                    />
                                                </li>
                                            )
                                        }
                                    </ul>
                                    <div className="pagenate__area">
                                        <PageIndicator
                                            pageSize={pageSize}
                                            page={searchParam?.page ?? 0}
                                            setPage={setPage}
                                            itemCount={searchList?.data?.data?.hits?.total?.value}
                                            usePageInput={true}
                                        />
                                    </div>
                                </div>
                            </article>
                        </TabPanel>
                        <TabPanel>
                            <div className="table__area table__list mb_48">
                                <table>
                                    <colgroup>
                                        <col width="94px"/>
                                        <col width=""/>
                                        <col width="94px"/>
                                        <col width=""/>
                                        <col width="115px"/>
                                        <col width="94px"/>
                                        <col width=""/>
                                        <col width=""/>
                                    </colgroup>
                                    <thead>
                                    <tr>
                                        <th>번호</th>
                                        <th>대표이미지</th>
                                        <th>년도</th>
                                        <th>대회명</th>
                                        <th>경기 날짜</th>
                                        <th>시간</th>
                                        <th>홈팀</th>
                                        <th>원정팀</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        showList?.map(data =>
                                            <PreviewListItem
                                                {
                                                    ...data
                                                }
                                            />
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagenate__area">
                                <PageIndicator
                                    pageSize={pageSize}
                                    page={searchParam?.page ?? 0}
                                    setPage={setPage}
                                    itemCount={searchList?.data?.data?.hits?.total?.value}
                                    usePageInput={true}
                                />
                            </div>
                        </TabPanel>
                    </Tabs>
                </article>
            </div>
        </>
    )
}
