
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {useState} from "react";

export default function PasswordInput({value, onChange, className, placeholder, onKeyUp, onKeyDown}) {

    const [show, setShow] = useState(false);

    return <div className="flex w-full password-input">
        <input
            type={
                show ? 'text' : 'password'
            }
            className={`${className} w-full flex-1`}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
        />

        <div className="mr-4"/>
        <button
            tabIndex={-1}
            onClick={e => setShow(p => !p)}
        >
            {
                show && <VisibilityIcon/>
            }
            {
                !show && <VisibilityOffIcon/>
            }
        </button>
        <div className="mr-4"/>

    </div>
}