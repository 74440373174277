

export default function SponsorFooter() {
    return (
        <div className="flex flex-col justify-center items-center p-4 pb-10 bg-[#121212]">
            <div className="m-10">
                이 사이트는 <span className="txt_bold">문화체육관광부</span>와 <span className="txt_bold">국민체육진흥공단</span>의 <span className="txt_bold">지원</span>을 받아 제작되었습니다.
            </div>
            <div className="flex items-center justify-center h-[60px]">
                <div className="h-[60px]">
                    <img
                        className="object-contain h-full"
                        src="/image/asset/logo/sponsor1.png"
                    />
                </div>
                <div className="mb-4 h-[40px]">
                    <img
                        className="object-contain h-full"
                        src="/image/asset/logo/sponsor2.png"
                    />
                </div>
            </div>
        </div>
    )
}