import {scheme} from "../../../util/url";

export default class EventModel{
    constructor({
                    eventId,
                    cid,
                    menuId,
                    eventCd,
                    thumbnail,
                    title,
                    startTime,
                    eventTime,
                    endTime,
                    eventNm,
                    timeMisec,
                    injuryTimeMisec,
                    hname,
                    entryno,
                    position,
                    matchName,
                    regDt,
                    teamHome,
                    teamAway,
                    videocontent,
                }) {
        this.videocontent = videocontent;
        this.eventId = eventId;
        this.cid = cid;
        this.menuId = menuId;
        this.eventCd = eventCd;
        this.thumbnail = thumbnail;
        this.title = title;
        this.startTime = startTime;
        this.eventTime = eventTime;
        this.endTime = endTime;
        this.eventNm = eventNm;
        this.timeMisec = timeMisec;
        this.injuryTimeMisec = injuryTimeMisec;
        this.hname = hname;
        this.entryno = entryno;
        this.position = position;
        this.matchName = matchName;
        this.regDt = regDt;
        this.teamHome = teamHome;
        this.teamAway = teamAway;
    }
    static of2({
                   event,
                   videocontent,
               }) {
        let _event = event[0];
        let _videocontent = videocontent[0];
        let eventId = _event.eventId;
        let cid = _event.cid;
        let menuId = _event.menuId;
        let eventTitle = _event.eventTitle;
        let eventNm = _event.eventNm;
        let thumb_cdnUrl = _event.thumb_cdnUrl;
        let thumb_file = _event.thumb_file;
        let thumb_path = _event.thumb_path;
        let start_time = _event.start_time;
        let end_time = _event.end_time;
        let time_misec = _event.time_misec;
        let injury_time_misec = _event.injury_time_misec;
        let flag_arc = _event.flag_arc;
        let hname = _event.hname;
        let entryno = _event.entryno;
        let position = _event.position;
        let match_type_name = _event.match_type_name;
        let regDt = _event.regDt;
        let team_home = _event.team_home;
        let team_away = _event.team_away;
        return new EventModel({
            eventId,
            cid,
            menuId,
            eventNm,
            title: eventTitle,
            thumbnail: `${scheme}${thumb_cdnUrl}${thumb_path}${thumb_file}`,
            startTime: start_time,
            endTime: end_time,
            eventTime: time_misec,
            timeMisec: time_misec,
            injuryTimeMisec: injury_time_misec,
            eventCd: flag_arc,
            hname,
            entryno,
            position,
            matchName: match_type_name,
            regDt,
            teamHome: team_home,
            teamAway: team_away,
            videocontent: _videocontent,
        })
    }

}
