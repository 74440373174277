import React, {useEffect, useState} from "react";
import {value} from "lodash/seq";
import classNames from "classnames";


/**
 * @param {String} labelText
 * @param {String} hintText
 * @param onChange
 * @param {String} inputValue
 * @param isInit
 */
export const LabelComponents = ({
                                    labelText = null,
                                    hintText,
                                    inputValue = '',
                                    onChange,
                                    isInit = true,
                                    style = {},
                                }) => {
    const handleChange = (event) => {
        onChange(event.target.value);
    }

    return (
        <>
            {(labelText !== null) && <div className="line_title">{labelText}</div>}
            <input style={style} type="text" placeholder={hintText} value={inputValue} onChange={handleChange}/>
        </>
    );
}

/**
 * @param {Array} labelCheckboxData
 * @param {Set<string>} checkedValues
 * @param {function} onChecked
 * @param {string} [valueText=value]
 * @param {string} [nameText=text]
 */
export function LabelCheckbox({
                                  labelCheckboxData,
                                  checkedValues,
                                  onChecked,
                                  valueText = 'value',
                                  nameText = 'text'
                              }) {
    return labelCheckboxData.map((item, idx) => <label key={`checkboxLabel_${idx}`}>
        <input type="checkbox"
               value={item[valueText]}
               checked={checkedValues.has(item[valueText])}
               className="input_check"
               onChange={onChecked}
        />
        {item[nameText]}
    </label>)
}


/**
 * @deprecated [LabelCombobox2] 사용
 * @param {String} labelText
 * @param comboboxData
 * @param nameText
 * @param valueText
 * @param {String} selectValue 선택된 값입니다.
 * @param {function (string, number) : void}  onChange 선택된 값, 인덱스를 파라미터로 받는 콜백함수입니다.
 * @param {boolean} useAll
 * @param {boolean} isInit
 * @param {boolean} disabled
 * @param {string} className 퍼블리싱에서 "selectBox"와 같은 계층에서 쓰이는 값을 사용해야 합니다.
 * @param {string} placeholder 미리보기할 값입니다.
 * @param defaultItem 기본 데이터입니다.
 * */
export const LabelCombobox = ({
                                  labelText = null,
                                  comboboxData,
                                  nameText = 'text',
                                  valueText = 'id',
                                  selectValue = 'none',
                                  onChange,
                                  returnObject = false,
                                  useAll = false,
                                  isInit = true,
                                  disabled = false,
                                  className,
                                  placeholder,
                                  defaultItem,
                              }) => {

    const handleChange = (select) => {
        if (select?.result != "" && select?.result != null) {
            const idx = comboboxData?.findIndex(data => data[nameText] === select.result);
            if(returnObject){
                onChange(comboboxData?.[idx], idx);
            }
            else{
                onChange(comboboxData?.[idx]?.[valueText], idx);
            }
        }
    }

    const allComponent = useAll ? <div className="select_text">
        <div className="option">
            <div className="option_text">
                <span>전체</span>
            </div>
        </div>
    </div> : <div className="select_text">
        <div className="option">
            <div className="option_text">
                <span>{defaultItem?.[nameText] ?? "전체"}</span>
            </div>
        </div>
    </div>;


    const makeData = () => {
        const list = useAll
            ? [<li>
                <div className="option">
                    <div className="option_text">
                        <span>전체</span>
                    </div>
                </div>
            </li>]
            : [];
        list.push(...comboboxData?.map((item, index) => (<li key={`${className}_${index}`}>
                <div className={classNames({
                    'option': true,
                    'selected': selectValue == item?.[nameText]
                })}>
                    <div className="option_text">
                        <span>{item[nameText]}</span>
                    </div>
                </div>
            </li>
        )))

        return list
    }

    const [dataList, setDataList] = useState(makeData);

    useEffect(() => {
        if (comboboxData != null && comboboxData.length !== 0) {
            setDataList(makeData());
        }
    }, [comboboxData])

    useEffect(() => {
        if (comboboxData != null && comboboxData.length !== 0) {
            let menuIndex = comboboxData.findIndex(item => item[valueText] === selectValue);
            if(useAll){
                if(menuIndex === -1){
                    menuIndex = 0;
                } else {
                    menuIndex++;
                }
            }
            else {
                if(menuIndex === -1){
                    menuIndex = 0;
                }
            }
            const select = new window.CustomSelectBox(`.${className}`, {
                option: '.select_option',
                placeholder: placeholder,
                onChange: handleChange,
                selected: menuIndex,
            })
            console.log(`${className} init ${placeholder} ${selectValue} ${menuIndex}`);
            return () => {
                console.log(`${className} dispose`);
                select.dispose();
            }
        }
    }, [dataList, className])

    // useEffect(() => {
    //     console.log(`${className} init`);
    //     return () => {
    //         console.log(`${className} dispose`);
    //     }
    // }, [className])


    return (
        <>
            {(labelText !== null) && <div className="line_title">{labelText}</div>}
            <div className="select__wrap item">
                <div className={`selectBox ${className}`}>
                    <div className="select">
                        {allComponent}
                        <ul className="select_option">
                            {dataList}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

/**
 * @param {String} labelText => 라디오버튼 라벨
 * @param name => 라디오버튼 그룹 지정을 위함
 * @param radioLabelData => 라디오 버튼 목록
 * @param checkedValue => 체크된 라디오 버튼 (디폴트값 지정하기 위함)
 * @param onChange => 라디오버튼 상태 체인지 이벤트 핸들러
 * @param className => 스타일 지정
 * */
export const LabelRadioButton = ({
                                     labelText = null,
                                     name,
                                     radioLabelData = [],
                                     checkedValue = null,
                                     onChange,
                                     className = "line_row",
                                     isInit = true,
                                 }) => {
    const [state, setState] = useState(checkedValue);
    const handleChange = (event) => {
        setState(event.target.value);
        onChange(event.target.value);
    }

    useEffect(() => {
        if (isInit) {
            setState(checkedValue);
        }
    }, [isInit]);

    useEffect(() => {
        setState(checkedValue);
    }, [checkedValue])

    return (
        <div className={className}>
            {(labelText !== null) && <div className="line_title">{labelText}</div>}
            <ul>
                {
                    // Todo: ul 태그 className 변경
                    radioLabelData.map((item, index) => (
                        <li key={index}>
                            <div className="checkbox">
                                <input type="radio" name={name}
                                       value={item.value}
                                       checked={item.value === state}
                                       onChange={handleChange}
                                />
                                <label key={index}>
                                    {item.text}
                                </label>
                            </div>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}