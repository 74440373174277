import {makeAttachUrl} from "../../util/url";
import ErrorBoundary from "./ErrorBoundary";

export default function Hashtags({list}) {
    return (
        <article className="edit_item">
            <div className="content_head mb_32">
                <div className="head_title">
                    <div className="txt_head4">해시태그</div>
                </div>
            </div>

            <ErrorBoundary
                fill={false}
            >
                <List
                    list={list}/>
            </ErrorBoundary>
        </article>
    )
}

function List({list}){
    return (
        <div className="hash__tag__area">
            {
                list?.map(it => <span className="txt_tag">#{it}</span>)
            }
            {
                (list?.length ?? 0) === 0 &&
                <span className="txt_sub">해시태그가 없습니다.</span>
            }
        </div>
    )
}