import classNames from "classnames";
import React from "react";

export default class SearchFilterHeader {
    /**
     * @param {number | string} id 필터 아이디. map 에서 받는 index로 충분합니다.
     * @param {string} title 헤더에 나올 제목.
     * @param {Set<string>} filters 해시태그란에 띄워줄 집합.
     * @param onFiltersChanged 필터 변경 이벤트
     * @param visible 가시성
     * @param onVisibilityChange 가시성 변경 이벤트
     * @constructor
     */
    static Text = ({
                       id,
                       title,
                       filters,
                       onFiltersChanged,
                       visible,
                       onVisibilityToggle,
                   }) => {
        const deleteFilter = (filter) => {
            if (filters.delete(filter)){
                onFiltersChanged(id, new Set([...filters]))
            }
        }

        return <div className="filter_head">
            <div className="filter_tag_area">
                <div className="title">{title}</div>
                <div className="tagList">
                    {
                        filters != null &&
                        [...filters].map((keyword, idx) =>
                            <div className="item" key={`keyword_${idx}`}><span className="value">{keyword}</span>
                                <div className="deleteBtn" onClick={() => deleteFilter(keyword)}></div>
                            </div>
                        )
                    }
                </div>
            </div>
            <div
                className={classNames('filter_drop', {active: !visible})}
                onClick={() => onVisibilityToggle(id)}
            ></div>
        </div>
    }

    /**
     * @param {number | string} id 필터 아이디. map 에서 받는 index로 충분합니다.
     * @param {string} title 헤더에 나올 제목.
     * @param {Set<string>} filters 해시태그란에 띄워줄 집합.
     * @param onFiltersChanged 필터 변경 이벤트
     * @param visible 가시성
     * @param onVisibilityChange 가시성 변경 이벤트
     * @param {Array} items 항목 배열
     * @constructor
     */
    static ItemList = ({
                           id,
                           title,
                           filters,
                           onFiltersChanged,
                           visible,
                           onVisibilityToggle,
                           items,
                           nameText = "name",
                           valueText = "value"
                       }) => {
        const deleteFilter = (filter) => {
            if (filters.delete(filter))
                onFiltersChanged(id, filters)
        }

        return <div className="filter_head">
            <div className="filter_tag_area">
                <div className="title">{title}</div>
                <div className="tagList">
                    {[...filters].map((value, idx) => {
                            if (items != null) {
                                const flattenitems = items?.flatMap(item => item.itemList?.length > 0 ? [item, ...item.itemList] : item)
                                const item = flattenitems.find(item => item[valueText] === value);
                                return <div className="item" key={`item_${title}_${idx}`}>
                                    <span className="value">{item[nameText]}</span>
                                    <div className="deleteBtn" onClick={() => deleteFilter(value)}></div>
                                </div>
                            } else {
                                return <div className="item" key={`item_${title}_${idx}`}>
                                    <span className="value">{value}</span>
                                    <div className="deleteBtn" onClick={() => deleteFilter(value)}></div>
                                </div>
                            }
                        }
                    )}
                </div>
            </div>
            <div
                className={classNames('filter_drop', {active: !visible})}
                onClick={() => onVisibilityToggle(id)}
            ></div>
        </div>;
    }
}