import ProgressBar from "../../../common/component/ProgressBar";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {ShowAlertModal} from "../../../common/component/modal/AlertModal";
import {formatBytes} from "../../../lib/dataUtils";
import {ShowConfirmModal} from "../../../common/component/modal/ConfirmModal";
import {cancelUpload, clearFileUpload, uploadFileAction} from "../../../modules/fileUploadReducer";
import classNames from "classnames";

export default function VideoUploadPage() {
    const dispatch = useDispatch();
    const uploadPercent = useSelector(state => state.fileUploadReducer.percent);
    const uploadState = useSelector(state => state.fileUploadReducer.state);
    const [uploadTargetFile, setUploadFile] = useState(null);
    const fileRef = useRef();
    const [canCancel, setCanCancel] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [canSelect, setCanSelect] = useState(true);

    const handleFileChange = (e) => {
        const files = [...e.target?.files];
        if(files.map(it => it.name).join('|').length >= 1000){
            ShowAlertModal({
                message: '파일명이 너무 깁니다. 파일명을 간단하게 해주세요'
            })
            return;
        }
        files.sort((a,b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
        setUploadFile(files);
        dispatch(clearFileUpload());
    }

    const handleUpload = () => {
        if(uploadTargetFile == null){
            ShowAlertModal({
                message: '파일을 선택해 주세요'
            })
        }
        else {
            let message = '';
            if(uploadTargetFile.length === 1){
                message = '업로드 하시겠습니까?';
            }
            else {
                message = `<span>${uploadTargetFile.length}개의 파일을 <span style="color: red">병합업로드</span> 하시겠습니까?</span>`
            }
            ShowConfirmModal({
                message: message,
                onConfirm: (modal) => {
                    modal.current.hideModal();
                    dispatch(uploadFileAction(uploadTargetFile))
                }
            })
        }
    }

    useEffect(()=> {
        dispatch(clearFileUpload())
        return ()=> {
            dispatch(clearFileUpload())
        }
    }, [])

    const handleCancel = () => {
        const targetExist = uploadTargetFile != null && uploadTargetFile.length > 0;
        if(uploadState === 'ready' || uploadState === null){
            if(targetExist){
                setUploadFile(null);
            }
        }
        else if(uploadState === 'working'){
            dispatch(cancelUpload());
        }
        else if(uploadState === 'success') {
            setUploadFile(null);
            if(fileRef.current){
                fileRef.current.value = ''
            }
            dispatch(clearFileUpload())
        }
        else {
            setUploadFile(null);
            if(fileRef.current){
                fileRef.current.value = ''
            }
            dispatch(clearFileUpload())
        }
    }

    useEffect(() => {
        const targetExist = uploadTargetFile != null && uploadTargetFile.length > 0;
        if(uploadState === 'ready' || uploadState === null){
            if(targetExist){
                setCanCancel(true);
                setCanSubmit(true);

            }
            else {
                setCanCancel(false);
                setCanSubmit(false);
            }
            setCanSelect(true);
        }
        else if(uploadState === 'working'){
            setCanSubmit(false);
            setCanCancel(true);
            setCanSelect(false);
        }
        else if(uploadState === 'success') {
            setCanSubmit(false);
            setCanCancel(false);
            setCanSelect(true);
        }
        else {
            setCanSubmit(true);
            setCanCancel(true);
            setCanSelect(true);
        }

    }, [uploadTargetFile, uploadState]);

    useEffect(() => {

        if(uploadState === 'fail'){
            ShowAlertModal({
                message: '업로드 중 오류가 발생했습니다.'
            })
        }

        else if(uploadState === 'cancel'){
            ShowAlertModal({
                message: '업로드가 취소되었습니다.'
            })
        }
    }, [uploadState])

    return <div className="order__item">
        <div className="content_body">
            <div className="div__table__area upload_form_table">
                <div className="row">
                    <dl>
                        <dt>등록날짜</dt>
                        <dd>
                            {
                                new Date().format('yyyy-MM-dd')
                            }
                        </dd>
                    </dl>
                </div>
                <div className="row">
                    <dl>
                        <dt>영상업로드</dt>
                        <dd>
                            <div className="file__upload__wrap">
                                <div className="file__upload">
                                    <div className="upload__inp">
                                        <input type="file" ref={fileRef} id="upload" accept="video/*" multiple onChange={handleFileChange}/>
                                        <label htmlFor="upload">
                                            <div className="placeholder">
                                                {
                                                    uploadTargetFile != null && uploadTargetFile.length > 0 &&
                                                    uploadTargetFile?.map(it => <div>{it.name}</div>)
                                                }
                                                {
                                                    (uploadTargetFile == null || uploadTargetFile.length === 0) &&
                                                    '영상을 선택해주세요.'
                                                }

                                            </div>
                                        </label>
                                    </div>
                                    <div className={classNames({
                                        'btn btn-default': true,
                                        'inactive': !canSelect,
                                    })} onClick={e => {if(canSelect) fileRef.current?.click()}}>영상 선택</div>
                                </div>
                                <div className="file__progress__area">
                                    {
                                        (uploadState == null || uploadState === 'ready') &&
                                        <span className="state__text">대기중</span>
                                    }
                                    {
                                        uploadState === 'working' &&
                                        <span className="state__text">업로드 중</span>
                                    }
                                    {
                                        uploadState === 'success' &&
                                        <span className="state__text">업로드 완료</span>
                                    }
                                    {
                                        uploadState === 'fail' &&
                                        <span className="state__text">업로드 실패</span>
                                    }
                                    {
                                        uploadState === 'cancel' &&
                                        <span className="state__text">업로드 취소</span>
                                    }
                                    <ProgressBar value={uploadPercent ?? 0}/>
                                </div>
                            </div>
                        </dd>
                    </dl>
                </div>
                <div className="row">
                    <dl>
                        <dt>파일용량</dt>
                        <dd>
                            <div id="fileSize">{formatBytes(uploadTargetFile?.reduce((p, f)=> p+f.size, 0) ?? 0)}</div>
                        </dd>
                    </dl>
                </div>
            </div>
            <div className="content_controll">
                <div></div>
                <div className="group">
                    <button className="btn btn-cancel" onClick={handleCancel} disabled={!canCancel}>취소</button>
                    <button className="btn submit" onClick={handleUpload} disabled={!canSubmit}>업로드</button>
                </div>
            </div>
        </div>
    </div>;
}