import {Link} from "react-router-dom";
import _ from 'lodash';

/**
 *
 * @param {Menu} data
 * @param padding
 * @returns {JSX.Element|string}
 * @constructor
 */
export default function PCMenu({data, padding}) {

    // 최 하위 카테고리 묶음 갯수
    const chunkSize = 10;

    if (data == null || data.useYN === "N") {
        return '';
    }

    return (
        <li>
            <Link to={data.menuUrl ?? '/'}>{data.menuNm}</Link>
            <div className="gnb__depth">
                <div
                    className="inner"
                >
                    <div
                        style={{
                            width: padding,
                        }}
                    />

                    {
                        menuComponent()
                    }
                </div>
            </div>
        </li>
    )

    function menuComponent() {
        // video
        if (data.menuNm === 'KFA경기') {
            return data.children.filter(it => it.useYN === "Y").map(it => {
                return <div className="depth__menu__area">
                    <div>
                        <Link to={`${data.menuUrl}?league=${it.menuUrl}`}><span
                            className="title txt_head4">{it.menuNm}</span></Link>
                    </div>
                    <div className="menu">
                        {
                            _.chunk(it.children, chunkSize).map(chunk => <ul className="menu" style={{float: 'left'}}>
                                {
                                    chunk.map(depth3Menu => <li><Link
                                        to={`${data.menuUrl}?league=${depth3Menu.menuUrl}`}>{depth3Menu.menuNm}</Link></li>)
                                }
                            </ul>)
                        }
                    </div>
                </div>
            })
        }

        // video
        else if (data.contentType === 'video') {
            return <Menus data={data} children={data.children}/>;
        }

        // event
        else if (data.contentType === 'event') {
            return data.children.filter(it => it.useYN === "Y").map(it => {
                return <div className="depth__menu__area">
                    <div>
                        <Link to={`${data.menuUrl}?leagueParent=${it.menuUrl}`}>
                            <span className="title txt_head4">{it.menuNm}</span>
                        </Link>
                    </div>
                    <div className="menu">
                        {
                            _.chunk(it.children, chunkSize).map(chunk => <ul className="menu" style={{float: 'left'}}>
                                {
                                    chunk.map(depth3Menu => <li><Link
                                        to={`${data.menuUrl}?${eventQueryParam(depth3Menu.memucode)}`}>{depth3Menu.menuNm}</Link>
                                    </li>)
                                }
                            </ul>)
                        }
                    </div>
                </div>
            });
        }

        // image
        return <Menus data={data} children={data.children} filterType={'menu'}/>;

    }
}

function eventQueryParam(menuCode) {
    return menuCode.map(it => `league=${it.code3}`).join('&');
}

function Menus({data, children, filterType='league'}) {
    return children.filter(it => it.useYN === "Y").map(it =>
        <div className="depth__menu__area col">
            <div className="menu">
                <ul>
                    <li><Link
                        to={`${data.menuUrl}?${filterType}=${it.menuUrl}`}>{it.menuNm}</Link>
                    </li>
                </ul>
            </div>
        </div>);
}