import {useEffect, useState} from "react";
import {validateEmail} from "../../util";
import {useDispatch, useSelector} from "react-redux";
import userReducer, {clearSignup, requestFindId} from "../../modules/userReducer";
import {useLoadingModal} from "../../common/component/LoadingState";
import {ShowAlertModal} from "../../common/component/modal/AlertModal";
import {useNavigate} from "react-router-dom";
import {FindIdEmailPreset} from "../../config/email/FindIdEmailPreset";

export default function FindIdPage() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const [id, setId] = useState("");
    const [email, setEmail] = useState("");
    const [canSend, setCanSend] = useState(false);
    const findIdResult = useSelector(state => state.userReducer.findIdResult);

    useLoadingModal([findIdResult]);

    useEffect(() => {
        if(findIdResult.error){
            ShowAlertModal({
                message: '오류가 발생하였습니다.',
            });
            dispatch(clearSignup());
        }
        else if(findIdResult.data){
            ShowAlertModal({
                message: '메일이 발송되었습니다',
                onConfirm: ()=> {
                    navigate('/user/login');
                }
            })
            dispatch(clearSignup());
        }
    }, [findIdResult]);

    // useEffect(()=> {
    //     setCanSend(id.trim() !== "" && email.trim() !== "" && validateEmail(email));
    // }, [id, email]);

    useEffect(()=> {
        setCanSend(email.trim() !== "" && validateEmail(email));
    }, [email]);

    const handleEmailSend = () => {
        dispatch(requestFindId({
            userEmail: email,
            // ...FindIdEmailPreset,
        }));
    }

    return <div className="inner">
        <div className="sign-form-area">
            <div className="txt_head2 mb_48">아이디 찾기</div>
            <div className="find_alert mb_24">가입시 등록한 이메일 주소를 입력해주세요.</div>
            {/*<div className="field__inp mb_12">*/}
            {/*    <input type="text" placeholder="이름" className="inp-valid" value={id} onChange={e => setId(e.target.value)}/>*/}
            {/*</div>*/}
            <div className="field__inp">
                <input type="email" placeholder="이메일주소" className="inp-valid" value={email} onChange={e => setEmail(e.target.value)}/>
            </div>
            <div className="field__btn mt_24">
                <button className="btn submit" id="submitBtn" disabled={!canSend} onClick={handleEmailSend}>아이디를 이메일 발송</button>
            </div>
        </div>
    </div>
}