import {Dialog} from "@mui/material";
import useLocation2 from "../../../../util/useLocation2";
import {useEffect, useMemo, useState} from "react";
import useSSO from "../../../../lib/useSSO";
import classNames from "classnames";

export default function SSOErrorModal() {

    const [state, setState] = useState({
        show: false,
        message: '',
        okMessage: '확인',
        okCallback: null,
        cancelMessage: '취소',
        cancelCallback: null,
    })
    const {goServiceList} = useSSO();
    const {query} = useLocation2();
    const {
        sso,
        ssoCode,
        ssoMessage,
    } = useMemo(() => {
        return query;
    }, [query])

    useEffect(() => {
        console.log(ssoCode, ssoMessage)
        if(ssoCode == null || ssoCode === '' || ssoCode === '00'){
            setState(p => ({
                ...p,
                show: false,
            }));
        }
        else if(ssoCode === '33' || ssoCode === '22') {
            // PLUS와 연동 안된 계정
            setState(p => ({
                ...p,
                show: true,
                message: 'PLUS 서비스와 연동 되지 않은 ID 입니다.',
                okMessage: '연동관리',
                okCallback: () => {
                    goServiceList();
                },
                cancelMessage: '취소',
                cancelCallback: () => {
                    setState(p => ({
                        ...p,
                        show: false,
                    }))
                }
            }));
        }
        else {
            setState({
                show: true,
                message: ssoMessage !== '' ? ssoMessage : '알 수 없는 오류 입니다.',
                okMessage: '확인',
                okCallback: () => {
                    setState(p => ({
                        ...p,
                        show: false,
                    }));
                }
            })
        }
    }, [ssoCode]);

    return <Dialog open={state.show}>
        <div className="modal-alert-wrap">
            <div
                className={classNames({
                    'modal-dialog': true,
                    'fade': true,
                    'hide': false,
                })}

                style={{
                    display: 'unset',
                }}
            >
                <div className="dim"></div>
                <div className="modal-alert">
                    <div>
                        <div className="message" style={{color: 'white'}}>{state.message}</div>
                    </div>
                    <div className="btn_area">
                        {
                            state.cancelCallback != null &&
                            <div className="btn btn-default" onClick={state.cancelCallback}>{state.cancelMessage}</div>
                        }
                        {
                            state.okCallback != null &&
                            <div className="btn" onClick={state.okCallback}>{state.okMessage}</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </Dialog>
}