import {
    getQueryWith,
    getQueryWithConditions,
    getQueryWithEventCodes,
    getQueryWithHashtag,
    getQueryWithKeywords,
    getQueryWithLeague,
    getQueryWithLeague2,
    getQueryWithViewYn,
    getQueryWithYears,
    RangeCondition,
    TermCondition
} from "../../util/BoardSearchQueryUtil";
import {findMenuWithDfs, findMenuWithMenusAndDfs, getAllChildrenIncludeSelf, getAsFlat} from "../../lib/menu/menuUtil";

export default class SearchParam {
    /**
     *
     * @param {number} size
     * @param {number}  page
     */
    constructor({
                    size = 8,
                    page = 0,
                } = {}
    ) {
        this.size = Number(size);
        this.page = Number(page);
    }
}

export class VideoSearchParam extends SearchParam {

    static videoOrderList = [
        {
            text: '경기 최신순',
            id: 'matchDt desc',
        },
        {
            text: '경기 과거순',
            id: 'matchDt asc',
        },
        {
            text: '등록 최신순',
            id: 'regDt desc',
        },
        {
            text: '등록 과거순',
            id: 'regDt asc',
        },
    ];

    static videoTypeFilterList = [
        {
            text: '전체 영상',
            id: '',
        },
        {
            text: '더티(중계)',
            id: '더티(중계)',
        },
        {
            text: '클린(원본)',
            id: '클린(원본)',
        },
        {
            text: 'Live(녹화)',
            id: 'Live(녹화)',
        },
        {
            text: '파노라마',
            id: '파노라마',
        },
        {
            text: '현장영상',
            id: '현장영상',
        },
    ];


    /**
     *
     * @param {number} size
     * @param {number}  page
     * @param menuIds
     * @param maKnds
     * @param {Set<string>} keywords
     */
    constructor({
                    size = 8,
                    page = 0,
                    menuIds,
                    maKnds,
                    menus,
                    keyword = new Set(),
                    year = new Set(),
                    league = new Set(),
                    copyright = 'Y',
                    sort = 'desc', // matchDt
                    sortTarget = 'matchDt',
                    hashtag = null,
                    recType,
                    shareYn,
                } = {}) {
        super({size, page})
        this.from = page * size;
        this.menuIds = menuIds;
        this.maKnds = maKnds;
        this.menus = menus;
        this.sort = sort;
        this.sortTarget = sortTarget;
        this.hashtag = hashtag;
        this.recType = recType;
        this.shareYn = shareYn;

        if (menuIds != null && menuIds.length > 0) {
            this.query = {
                "bool": {
                    "filter": {
                        "terms": {
                            "menuId": [...menuIds],
                        }
                    }
                }
            }
        } else if (maKnds != null && maKnds.length > 0) {
            this.query = {
                "bool": {
                    "filter": {
                        "terms": {
                            "videocontent.ma_knd": maKnds,
                        }
                    }
                }
            }
        }

        this.keyword = makeSet(keyword);
        this.year = makeSet(year, true);
        this.league = makeSet(league, true);

        if (keyword != null && keyword.size !== 0) {
            this.query = getQueryWithKeywords([...keyword], this.query);
        }

        if(year != null && year.size !== 0){
            this.query = getQueryWithYears([...year], this.query);
        }

        if(this.league != null && this.league.size > 0){
            const menuList = getAsFlat(menus);
            let searchLeague = makeSet(getAsFlat([...this.league].map(league => menuList.find(it => it.menuId == league))).map(it => it.menuId));
            this.query = getQueryWithLeague([...searchLeague], this.query, this.menuIds);
        }

        this.query = getQueryWith('videocontent.copyright_yn', copyright, this.query);
        this.query = getQueryWithViewYn('videocontent', 'Y', this.query);
        if(this.recType) {
            this.query = getQueryWith('file.recType', this.recType, this.query);
        }
        if(this.shareYn) {
            this.query = getQueryWith('file.shareYn', this.shareYn, this.query);
        }

        if(this.sortTarget === 'matchDt') {
            this.sortQuery = [
                {
                    "videocontent.match_date": this.sort,
                },
                {
                    "videocontent.match_time": this.sort,
                }
            ]
        }
        else {
            this.sortQuery = [
                {
                    "videocontent.regDt": this.sort,
                },
                {
                    "videocontent.cid": this.sort,
                }
            ]
        }

        if(hashtag != null) {
            getQueryWithHashtag('hash', hashtag, this.query);
        }
    }

    toHistory() {
        const copy = {
            ...this,
            keyword: [...this.keyword],
            year: [...this.year],
            league: [...this.league],
        }

        delete copy.query;
        delete copy.from;
        delete copy.menus;
        delete copy.sortQuery;
        for(let i in copy) {
            if(isEmpty(copy[i])){
                delete copy[i];
            }
        }

        return copy;
    }

    static fromHistory(param, menus, copyright = 'Y', canShareYn) {
        return new VideoSearchParam({
            ...param,
            keyword: makeSet(param?.keyword),
            year: makeSet(param?.year,true),
            league: makeSet(param?.league,true),
            page: Number(param.page),
            size: param.size != null ? Number(param.size) : 8,
            menus: menus,
            copyright: copyright,
            shareYn: canShareYn ? param.shareYn : null,
        })
    }

    toRegDtDesc() {
        return new VideoSearchParam({
            ...this,
            sortTarget: 'regDt',
            sort: 'desc',
        })
    }
}

const makeSet = (value, toNumber = false) => {
    let res = value;
    if(!value){
        res = new Set();
    }
    else if(!(value instanceof Set)){
        if(Array.isArray(value)){
            if(toNumber){
                res = new Set([...value].map(it => Number(it)).sort());
            }
            else {
                res = new Set([...value].sort());
            }
        }
        else {
            if(toNumber){
                res = new Set([Number(value)]);
            }
            else {
                res = new Set([value]);
            }
        }
    }
    return res;
}

export class EventSearchParam extends SearchParam {
    /**
     *
     * @param {number} size
     * @param {number}  page
     * @param eventCodes
     * @param {Set<String>} keywords
     */
    constructor({
                    // boardParam
                    index,

                    size = 8,
                    page = 0,
                    league,
                    league2,
                    leagueParent,
                    copyright = 'Y',
                    sort,
                    keyword = new Set(),
                    hashtag,
                    menu,
                    menus,
                } = {}) {
        if(keyword instanceof String) {
            const keywordSet = new Set();
            keywordSet.add(keyword);
            keyword = keywordSet;
        }

        super({size, page})

        // boardParam
        this.index = index;

        this.from = page * size;
        this.sort = sort;
        this.query = {
            "match_all": {},
        };
        this.keyword = makeSet(keyword);
        this.league = makeSet(league);
        this.league2 = makeSet(league2, true);
        this.leagueParent = makeSet(leagueParent, true);

        if (keyword != null && keyword.size !== 0) {
            this.query = getQueryWithKeywords([...keyword], this.query);
        }
        league = league ?? [];
        leagueParent = leagueParent ?? [];
        if(league.size + leagueParent.size > 0) this.query = getQueryWithEventCodes([...league, ...getEvents(menu, leagueParent)], this.query); // flag_arc
        league2 = league2 ?? [];
        const menuIds = getMenuIds(menus, [...league2]);
        if(league2.size > 0) this.query = getQueryWithLeague2([...menuIds], this.query); // menuId

        this.query = getQueryWith('event.copyright_yn', copyright, this.query);
        this.query = getQueryWithViewYn('event', 'Y', this.query);
        this.query = getQueryWithHashtag('hash', hashtag, this.query);
    }

    toHistory() {
        const copy = {
            ...this,
            keyword: [...this.keyword],
            league: [...this.league],
            league2: [...this.league2],
            leagueParent: [...this.leagueParent],
        }
        delete copy.query;
        delete copy.from;
        delete copy.sortQuery;
        for(let i in copy) {
            if(isEmpty(copy[i])){
                delete copy[i];
            }
        }
        return copy;
    }

    static fromHistory(param, menus, copyright = 'Y') {
        return new EventSearchParam({
            ...param,
            league: makeSet(param.league),
            league2: makeSet(param.league2, true),
            leagueParent: makeSet(param.leagueParent, true),
            page: Number(param.page),
            size: param?.size != null ? Number(param.size) : 8,
            copyright: copyright,
            // menus: menus,
        })
    }
}

function getMenuIds(menus, leagues) {
    if(!menus) return [];

    const list = leagues.map(menuId => findMenuWithMenusAndDfs(menus, menuId))
        .map(menu => getAllChildrenIncludeSelf(menu))
        .flat()
        .map(menu => menu.menuId);

    console.log('menu1', list);
    return list;
}

function getEvents(menu, leagueParent) {
    if(!menu) return [];

    let customFlag = false;

    const res = [...leagueParent]
        .map(menuId => findMenuWithDfs(menu, Number(menuId)))
        .map(m => {
            if(m.menuNm === '기타기록') customFlag = true;

            return m?.children;
        })
        .flat()
        .map(menu => menu.memucode.map(menucode => menucode.code3))
        .flat();

    if(customFlag) res.push('ZZZ');

    return res;
}

const isEmptyIterator = (iterator) => {
    //if (!(iterator instanceof Set) && !(iterator instanceof Array)) throw new Error("iterator가 아닙니다!");
    return (iterator instanceof Set && iterator.size === 0)
        || (iterator instanceof Set && iterator.has(""))
        || (iterator instanceof Array && iterator.length === 0)
        || (iterator instanceof Array && iterator.includes(""))
}
const isEmpty = (value) => {
    return (value === undefined || value == null || value === "" || isEmptyIterator(value))
}

/**
 * @param {Array[condition, value]} conditions [conditionObject, value] 형태로 제공해주면 필터링 대상 값이 올바르지 않은 값을 제거해줌.
 * @return {*}
 */
const filterCondition = (conditions) => {
    for (let i = 0; i < conditions.length; i++) {
        const [_, value] = conditions[i];
        if (isEmpty(value)) {
            conditions.splice(i, 1)
            i--;
        }
    }
    return conditions;
}

export class ImageSearchParam extends SearchParam {

    static pageSize = 8 * 5;
    static defaultSort = "desc"
    static defaultSortField = "imagecontent.pictureDate"
    /**
     *
     * @param {number} size
     * @param {number}  page
     * @param {Set<string>} keywords
     * @param startDate
     * @param endDate
     * @param {[Menu]} menu 검색 대상 메뉴 (그룹만 가능)
     * @param {[number]} menus 전체 메뉴
     */
    constructor({
                    size = ImageSearchParam.pageSize,
                    page = 0,
                    keyword = new Set(),
                    startDate,
                    endDate,
                    // menuId,
                    copyright = 'Y',
                    menu,
                    sort = ImageSearchParam.defaultSort,
                    sortTarget = ImageSearchParam.defaultSortField,
                    menus,
                    hashtag = null,
                    shareYn,
                } = {}) {
        super({size, page})
        this.from = page * size;
        this.startDate = startDate;
        this.endDate = endDate;
        this.keyword = makeSet(keyword);
        this.sort = sort;
        this.sortTarget = sortTarget;
        this.shareYn = shareYn;

        this.menu = menu; // 메뉴 그룹 단위, 여기서 검색용 menuId 도출 가능
        this.menuId = ImageSearchParam.menuListToMenuId(menu ?? [], menus)

        const conditions = filterCondition([
            [RangeCondition.greaterThan("imagecontent.pictureDate", startDate), startDate],
            [RangeCondition.lowerThan("imagecontent.pictureDate", endDate), endDate],
            ...[...keyword].map(keyword => [TermCondition.keywordParam(keyword), keyword]),
            [TermCondition.anyInArr('menuId', this.menuId), this.menuId],
            [new TermCondition('hash.hashNm', hashtag), hashtag],
            [new TermCondition('imagecontent.view_yn', "Y"), "Y"],
        ]);

        this.query = getQueryWithConditions(conditions.map(cond => cond[0]), this.query);
        this.query = getQueryWith('imagecontent.copyright_yn', copyright, this.query);
        if(this.shareYn) {
            this.query = getQueryWith('file.shareYn', this.shareYn, this.query);
        }
    }

    toHistory() {
        const copy = {
            ...this,
            keyword: [...this.keyword]
        }
        delete copy.query;
        delete copy.from;
        delete copy.size;
        delete copy.menuId;
        for(let i in copy) {
            if(isEmpty(copy[i])){
                delete copy[i];
            }
        }
        return copy;
    }

    static fromHistory(param, menus, copyright = 'Y', canShareYn) {
        console.log('fromHistory', param.menu);
        let menuList = param.menu;
        if(!Array.isArray(param.menu)){
            menuList = [param.menu];
        }
        if(param.menu == null){
            menuList = [];
        }
        console.log('fromHistory', ImageSearchParam.menuIdToMenuList(menuList, menus));
        return new ImageSearchParam({
            ...param,
            // 히스토리 복구시 그룹 메뉴 ID로 menu 추출
            menu: ImageSearchParam.menuIdToMenuList(menuList, menus),
            keyword: makeSet(param.keyword),
            startDate: param.startDate ?? '',
            endDate: param.endDate ?? '',
            menus: menus,
            copyright: copyright,
            shareYn: canShareYn ? param.shareYn : null,
        })
    }

    static menuIdToMenuList(menuIds, menus) {
        const totalMenu = getAsFlat(menus);
        const ids = menuIds.map(it => Number(it));
        const filtered = totalMenu?.filter(it => ids.includes(it.menuId ))
        return filtered?.map(it => it.menuId);
    }

    static menuListToMenuId(menuList, menus) {
        if(menus == null){
            return [];
        }
        const totalMenu = getAsFlat(menus);
        const showingFiltered = totalMenu?.filter(it => menuList.includes(it.menuId));
        const showingChildren = getAsFlat(showingFiltered);
        return showingChildren.map(it => it.menuId);
    }
}