import {combineReducers} from 'redux';
import {all} from 'redux-saga/effects';
import homeReducer from "./homeReducer";
import menuReducer from "./menuReducer";
import videoReducer, {videoSaga} from "./videoReducer";
import loadingReducer from "./loadingReducer";
import videoBoardReducer from "./videoBoardReducer";
import eventBoardReducer from "./eventBoardReducer";
import imageReducer from "./imageReducer";
import userReducer, {alertSaga, downloadLogListSaga} from "./userReducer";
import watermarkReducer from "./watermarkReducer";
import pipReducer from "./pipReducer";
import liveReducer from "./liveReducer";
import relationReducer, {videoRelationSaga} from "./relationReducer";
import searchReducer from "./searchReducer";
import totalSearchReducer, {totalSearchSaga} from "./totalSearchReducer";
import hotHashtagReducer from "./hotHashtagReducer";
import videoUploadReducer from "./videoUploadReducer";
import imageUploadReducer from "./imageUploadReducer";
import fileUploadReducer, {uploadSaga} from "./fileUploadReducer";

const rootReducer = combineReducers({
    homeReducer,
    menuReducer,
    videoReducer,
    loadingReducer,
    videoBoardReducer,
    eventBoardReducer,
    imageReducer,
    userReducer,
    watermarkReducer,
    pipReducer,
    liveReducer,
    relationReducer,
    searchReducer,
    totalSearchReducer,
    hotHashtagReducer,
    videoUploadReducer,
    imageUploadReducer,
    fileUploadReducer,
});

export function* rootSaga() {
    yield all([
        videoSaga(),
        videoRelationSaga(),
        totalSearchSaga(),
        alertSaga(),
        downloadLogListSaga(),
        uploadSaga(),
    ]);
}

export default rootReducer;