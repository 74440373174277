import {createRef, forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {createRoot} from "react-dom/client";
import classNames from "classnames";

const AlertModal = forwardRef( ({ message, onConfirm, show }, ref) =>  {
    const [isShow, setIsShow] = useState(show ?? false)
    const [firstShow, setFirstShow] = useState(true);
    useImperativeHandle(ref, () => ({
        showModal() {
            setIsShow(true)
        },
        hideModal() {
            setIsShow(false)
        },
    }));

    useEffect(()=> {
        if(isShow){
            setFirstShow(false);
        }
    }, [isShow])

    const handleConfirm = () => {
        setIsShow(false);
        if(onConfirm){
            onConfirm(ref);
        }
    }
    return (
        <div className="modal-alert-wrap">
            <div
                className={classNames({
                    'modal-dialog': true,
                    'fade': true,
                    'hide': !isShow,
                })}

                style={{
                    display: firstShow ? 'none': 'unset',
                }}
            >
                <div className="dim"></div>
                <div className="modal-alert">
                    <div>
                        <div className="message">{message}</div>
                    </div>
                    <div className="btn_area">
                        <div className="btn" onClick={handleConfirm}>확인</div>
                    </div>
                </div>
            </div>
        </div>
    )
})

/**
 * DOM에 <AlertModal/> 언선 이후 사용 가능
 * @param message
 * @param onConfirm
 * @returns {JSX.Element}
 * @constructor
 */
export const ShowAlertModal = ({message, onConfirm})=> {
    const ref = createRef();
    const newModal = <AlertModal
        ref={ref}
        show={true}
        message={message}
        onConfirm={onConfirm}
    />

    try{
        const container = document.querySelectorAll('.modal-alert-wrap')[0];
        const modalRoot = createRoot(container);
        modalRoot.render(newModal)
        return ref;
    }
    catch(e) {
        return null;
    }
}


export default AlertModal;