

// encodeURI 알파벳, 0~9의 숫자, ; , / ? : @ & = + $ #    - _ . ! ~ * ' ( )
// encodeURIComponent 알파벳, 0~9의 숫자 - _ . ! ~ * ' ( )
//
// encodeURI 에서 안되는 것들  ; , / ? : @ & = + $ #
// 무조건 치환 '#', '+', ';', ','
// 위치에 따라 처리 '?', ':', '@', '&'
// 무시 '/', '=', '$'
const justReplaceList = [
    '#', '+', ';', ',',
]

export const escapeUri = (uri) => {
    uri = encodeURI(uri);
    for(let i in justReplaceList){
        const char = justReplaceList[i];
        if(uri.includes(char)){
            uri = uri.replaceAll(char, encodeURIComponent(char));
        }
    }

    uri = remainLast('?', uri);
    uri = remainBefore('/', ':', uri);
    uri = remainBefore('/', '@', uri);
    uri = remainAfter('?', '&', uri);
    return uri;
}

/**
 * 마지막 char만 제외하고 encode
 * ? 를 넣으면, 마지막만 쿼리파라미터로 생각하고 나머지 치환
 * @param char
 * @param uri
 * @returns {*}
 */
const remainLast = (char, uri) => {
    const blocks = uri.split(char);
    if(blocks.length < 3) {
        return uri;
    }
    // 마지막 블록 빼고 escape 된 값으로 치환
    const lastBlock = blocks.slice(blocks.length - 1);
    uri = blocks.slice(0, blocks.length-1).join(encodeURIComponent(char)) + char + lastBlock[0];
    return uri;
}

/**
 * before 문자 이전은 남기고 encode
 * host에 들어 갈 수 있는 :, @ 치환에 사용
 * @param before
 * @param char
 * @param uri
 * @returns {string|*}
 */
const remainBefore = (before, char, uri) => {
    const blocks = uri.split(before);
    if(blocks.length < 2){
        return uri;
    }
    const remainBlocks = blocks.slice(1, blocks.length).map(it => it.replaceAll(char, encodeURIComponent(char)));
    return [blocks[0], ...remainBlocks].join(before);
}

/**
 * after 문자 이후는 남기고 encode
 * 쿼리파라미터 이전의 & 값 치환에 사용
 * @param after
 * @param char
 * @param uri
 * @returns {string|*}
 */
const remainAfter = (after, char, uri) => {
    // '/' 로 나눠서 host 이후 확인
    const blocks = uri.split(after);
    if(blocks.length < 2){
        return uri;
    }
    const remainBlocks = blocks.slice(0, blocks.length-1).map(it => it.replaceAll(char, encodeURIComponent(char)));
    return [...remainBlocks, blocks[blocks.length-1]].join(after);
}